import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { APP_MODE } from '../env';
import authSlice from './slices/auth-slice';
import customerSlice from './slices/user-slice';
import categorySlice from './slices/category-slice';
import attributeSlice from './slices/attribute-slice';
import productSlice from './slices/product-slice';
import containerSlice from './slices/container-slices';
import storeSlice from './slices/store-slice';
import inventorySlice from './slices/inventory-slice';
import crazyDealsSlice from './slices/crazy-deals-slice';
import bannerSlice from './slices/banner-slice';
import orderSlice from './slices/order-slice';
import prescriptionSlice from './slices/prescription-slice';
import reviewSlice from './slices/review-slice';
import followupSlice from './slices/followup-slice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth_store'],
};

const reducers = combineReducers({
    auth_store: authSlice,
    customer_store: customerSlice,
    category_store: categorySlice,
    attribute_store: attributeSlice,
    product_store: productSlice,
    container_store: containerSlice,
    store: storeSlice,
    order: orderSlice,
    inventory: inventorySlice,
    crazydeals_store: crazyDealsSlice,
    banner_store: bannerSlice,
    prescription_store: prescriptionSlice,
    review_store: reviewSlice,
    followup_store: followupSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    // devTools: APP_MODE === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export const persistor = persistStore(store);

export default store;
