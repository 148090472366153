import { AutoComplete, Input } from 'antd';

const SearchBar = ({ onSearch, placeholder }) => {
    return (
        <>
            <div className="flex items-end justify-between @sm:flex-col-reverse @sm:items-center mb-4">
                <div className="@sm:mt-6 @sm:w-full @lg:w-1/4 @xl:w-1/4 @2xl:w-1/4 @sm:pl-4">
                    <p className="fw-500 mt-4 sm:text-[1vw] text-[#1A3353] mb-2">
                        Search
                    </p>
                    <AutoComplete
                        popupMatchSelectWidth={252}
                        style={{
                            width: 300,
                        }}
                        // options={options}
                        // onSelect={onSelect}
                        className="text-black"
                    >
                        <Input.Search
                            className="w-full text-black"
                            placeholder={placeholder}
                            enterButton
                            allowClear
                            onChange={(e) => onSearch('search', e.target.value)}
                        />
                    </AutoComplete>
                </div>
            </div>
        </>
    );
};

export default SearchBar;
