import { createSlice } from '@reduxjs/toolkit';
import {
    ManipulateTransactionId,
    orderDetail,
    orderList,
} from '../actions/order-action';

const initialState = {
    orderList: [],
    orderListId: '',
    orderDetail: [],
    order_count: '',
    deliveryPartnerId: '',
    selectedStatus: '',
    orderStatus: '',
};
const orderSlice = createSlice({
    name: 'order-slice',
    initialState,
    reducers: {
        setOrderListId: (state, action) => {
            state.orderListId = action.payload;
        },
        setDeliveryPartnerID: (state, action) => {
            state.deliveryPartnerId = action.payload;
        },
        setSelectedStatus: (state, action) => {
            state.selectedStatus = action.payload;
        },
        setOrderStatus: (state, {payload}) => {
            state.orderStatus = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(orderList.fulfilled, (state, { payload }) => {
            state.orderList = (payload?.data);
            state.order_count = payload.data?.counts;
        });
        //order detail
        builder.addCase(orderDetail.fulfilled, (state, { payload }) => {
            state.orderDetail = payload;
        });
    },
});

export const { setOrderListId, setDeliveryPartnerID, setSelectedStatus,setOrderStatus } =
    orderSlice.actions;
export default orderSlice.reducer;
