import { useDispatch, useSelector } from 'react-redux';
import {
    OrderListId,
    setDeliveryPartnerID,
    setOrderListId,
    setSelectedStatus,
} from '../../../redux/slices/order-slice';
import { orderStatus } from '../../../redux/actions/order-action';
import { Select } from 'antd';
import { Link } from 'react-router-dom';

const OrdersMobile = (props) => {
    console.log('Orders are: ', props.dataSource);

    const dispatch = useDispatch();
    const role = useSelector((state) => state.auth_store.role);

    const roleOptions = {
        Doctor: [
            {
                key: 'Pending',
                value: 'pending',
            },
            {
                key: 'Prescription Uploaded',
                value: 'Prescription-Uploaded',
            },
        ],
        Pharma: [
            {
                key: 'Prescription Uploaded',
                value: 'Prescription-Uploaded',
            },
            { key: 'Confirmed', value: 'confirmed' },
            {
                key: 'Pending',
                value: 'pending',
            },
        ],
        'Delivery Person': [
            {
                key: 'Packed',
                value: 'packed ',
            },
            {
                key: 'Shipped',
                value: 'shipped',
            },
            // {
            //     key: 'Delivered',
            //     value: 'delivered',
            // },
        ],
        'Super Admin': [
            {
                key: 'Pending',
                value: 'pending',
            },
            { key: 'Confirmed', value: 'confirmed' },
            { key: 'Packed', value: 'packed' },
            {
                key: 'Prescription Uploaded',
                value: 'Prescription-Uploaded',
            },
            // {
            //     key: 'Delivered',
            //     value: 'delivered',
            // },
        ],
    };

    const options = roleOptions[role] || [];

    console.log('Orders are: ', props.dataSource);
    const handleChange = (value, orderId) => {
        dispatch(setSelectedStatus({ value, orderId }));
        dispatch(orderStatus({ value, orderId }));
    };
    return (
        <section>
            <span>
                {props.dataSource?.map((item) => (
                    <Link
                        key={item.orderId}
                        to={`/orders/order-details/${item.orderId}`}
                    >
                        <div
                            key={item.orderId}
                            className="bg-white m-4 p-4 rounded-lg"
                        >
                            <div className="flex justify-between">
                                <p className="ant-table-cell font-semibold text-[#1A3353]">
                                    ORDER ID:
                                </p>
                                <p className="text-[#455560]">{item.orderId}</p>
                            </div>
                            <div className="flex my-4">
                                <p className="text-[#455560] w-1/2">
                                    {item.billingAddress.fullName}
                                </p>
                                <p className="text-[#455560] w-1/2">
                                    {item.grandTotal}
                                </p>
                            </div>
                            <div className="flex my-4 items-center">
                                <p className="text-[#455560] w-1/2">
                                    {item.billingAddress.mobile}
                                </p>
                                <p className="text-[#455560] w-1/2">
                                    {/* <Select
                                    defaultValue={item.status}
                                    onChange={(value) =>
                                        handleChange(value, item.orderId)
                                    }
                                    style={{
                                        width: '180px',
                                        backgroundColor: 'transparent',
                                    }}
                                    options={options.map((option) => ({
                                        label: option.key,
                                        value: option.value,
                                    }))}
                                /> */}
                                </p>
                            </div>
                            <div className="flex">
                                <p className="text-[#455560 w-1/2">
                                    {item.paymentMode}
                                </p>
                                <p className="text-[#455560]">
                                    {item.deliveryOTP}
                                </p>
                            </div>
                        </div>
                    </Link>
                ))}
            </span>
        </section>
    );
};
export default OrdersMobile;
