export const LOGIN_API = '/admin/auth/login';

export const CUSTOMER_LSIT = '/admin/user';

export const CUSTOMER_DETAILS = '/admin/user';

export const CATEGORY_LIST = '/admin/category';

export const ADD_NEW_CATEGORY = '/admin/category/create';

export const UPDATE_CATEGORY = '/admin/category';

export const ATTRIBUTES_LIST = '/admin/attribute';

export const SUB_ATTRIBUTES_LIST = '/admin/attribute';

export const ADD_NEW_SUB_ATTRIBUTES = '/admin/attribute/create';

export const UPDATE_SUB_ATTRIBUTES = '/admin/attribute';

export const DELETE_SUB_ATTRIBUTES = '/admin/attribute';

export const PRODUCT_LIST = '/admin/product';

export const BANNER_LIST = '/admin/banner';

export const CREATE_BANNER = '/admin/banner/create';

export const REVIEW_LIST = '/admin/reviewRating';

export const REVIEW_DETAIL_LIST = '/admin/reviewRating';

export const UPDATE_REVIEW_LIST = '/admin/reviewRating';

export const DELETE_REVIEW_LIST = '/admin/reviewRating';

export const PHARMA_USERS_LISTINGS = '/admin/pharma';
export const DELETE_PHARMA = '/admin/pharma';
export const DELETE_DOCTOR = '/admin/doctor';
export const UPDATE_PHARMA = '/admin/pharma';
