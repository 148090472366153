import React, { useEffect, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import {
    Button,
    Checkbox,
    ConfigProvider,
    Form,
    Modal,
    Pagination,
    Select,
    Switch,
    Table,
} from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    BulkErrorLogs,
    BulkImagesUpload,
    BulkProductUpload,
    BulkUpdateProductUpload,
    DeleteProduct,
    ProductListData,
    ToggleProductStatus,
    UpdateProduct,
    _ManipulateErrors,
} from '../../redux/actions/product-action';
import {
    CategoryListData,
    PrimaryCategoryListData,
} from '../../redux/actions/category-action';
import { DeleteModal } from '../../components/common/delete-modal';
import { ActiveInactiveModal } from '../../components/common/active-inactive-modal';

import { useLoader } from '../../hooks/useLoader';
import { SearchProductBySkuDetailsInProductListing } from '../../redux/actions/inventory-action';

import toast from 'react-hot-toast';
import SearchBar from '../../components/topbar/searchbar';

import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';
import { useForm } from 'antd/es/form/Form';
import Alertify from '../../scripts/alertify';
import { CheckCircleOutlined } from '@ant-design/icons';

const columns = [
    {
        title: 'PRODUCT NAME',
        dataIndex: 'name',
    },
    {
        title: 'SKU',
        dataIndex: 'sku',
    },
    {
        title: 'TYPE',
        dataIndex: 'type',
    },
    {
        title: 'CATEGORY',
        dataIndex: 'category',
    },
    {
        title: 'HSN',
        dataIndex: 'hsn',
    },
    { title: 'GST', dataIndex: 'gst' },
];

const ProductListing = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [searchP] = useSearchParams();
    const navigate = useNavigate();
    const productFilterUrl = searchP.get('filter');

    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const [bulk_upload_loader, startLoader, endLoader] = useLoader(false);

    const ProductData = useSelector((state) => state.product_store.productList);
    const productFilter = useSelector(
        (state) => state.product_store.productFilter
    );
    const bulk_errors = useSelector((state) => state.product_store.bulk_errors);
    const search = useSelector((state) => state.product_store.search);
    const product_count = useSelector(
        (state) => state.product_store.product_count
    );
    const [status_modal, setStatusModal] = useState(false);
    const [delete_modal, setDeleteModal] = useState(false);
    const [selected_product, setSelectedProduct] = useState({});
    const [level, setLevel] = useState('first');
    const [form] = Form.useForm();

    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    const [selectedParent, setSelectedParent] = useState('');

    const [csv_file, setCSVFile] = useState('');
    const [bulkUploaded, setBulkUploaded] = useState(false);
    const [error_data, setErrorOnTable] = useState(null);
    const [error_images_data, setImagesErrorOnTable] = useState(null);
    const [isBulkUpdate, setIsBulkUpdate] = useState(false);
    const [variantListing, setVariantListing] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (record) => {
        setVariantListing(record);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const action_column = [
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (id, record) => (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimaryBorder: '#6E3362',
                            colorPrimary: '#6E3362',
                        },
                    }}
                >
                    <Switch
                        value={record.status === 'active'}
                        className="flex self-center"
                        onChange={toggleStatusModal}
                        onClick={() => setSelectedProduct(record)}
                    />
                </ConfigProvider>
            ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (id, record) => {
                return (
                    <span className="flex">
                        <Link to={`/products/product-detail/${record.key}`}>
                            <img
                                src="/icons/common/edit.svg"
                                alt="edit"
                                className="h-[20px]"
                            />
                        </Link>
                        {record.type === 'variable' ? (
                            <img
                                src="/images/product-images/eye.png"
                                alt="edit"
                                className=" ml-5 h-[20px] cursor-pointer"
                                onClick={() => showModal(record)}
                            />
                        ) : null}
                    </span>
                );
            },
        },
    ];

    const all_columns = [...columns, ...action_column];

    const paginate = (pageNumber, pageSize) => {
        setCurrentPage(pageNumber);
        setPageSize(pageSize);
        navigate(`?page=${pageNumber}&pageSize=${pageSize}`);
        if (productFilterUrl) {
            navigate(
                `?filter=${productFilterUrl}&page=${pageNumber}&pageSize=${pageSize}`,
                { replace: true }
            );
        }
    };

    const categoryData = useSelector(
        (state) => state.category_store.primaryCategory
    );
    const secondCategory = useSelector(
        (state) => state.category_store.secondCategory
    );

    const parentCategoryOptions = categoryData?.map((item) => ({
        value: item._id,
        label: item.name,
        id: item._id,
    }));

    const CategoryOptions = secondCategory?.map((item) => ({
        value: item._id,
        label: item.name,
        id: item._id,
    }));

    const errorData = bulk_errors?.map((data) => data.errors);
    // console.log('Bulk error--------', errorData);

    useEffect(() => {
        dispatch(BulkErrorLogs());
    }, []);

    function bulkUpload() {
        startLoader();
        // product bulk update api call
        if (isBulkUpdate) {
            dispatch(
                BulkUpdateProductUpload({
                    productFile: csv_file,
                    firstCategoryId: selectedParent,
                    secondCategoryId: selectedSubCategory,
                })
            )
                .unwrap()
                .then(({ res }) => {
                    if (res.status === 201) {
                        const data = _ManipulateErrors(res.data.data.errors);
                        dispatch(BulkErrorLogs());
                        setErrorOnTable(errorData);
                        setBulkUploaded(true);
                        Alertify.success('Bulk Updated Successfully');
                    }
                })
                .catch(() => {})
                .finally(endLoader);
        } else {
            // product bulk upload api call
            dispatch(
                BulkProductUpload({
                    productFile: csv_file,
                    firstCategoryId: selectedParent,
                    secondCategoryId: selectedSubCategory,
                    // thirdCategoryId: selectedSubSubCategory,
                })
            )
                .unwrap()
                .then(({ res }) => {
                    if (res.status === 201) {
                        // const data = _ManipulateErrors(res.data.data.errors);
                        // setErrorOnTable(data);
                        // dispatch(BulkErrorLogs());
                        // setErrorOnTable(errorData);
                        setBulkUploaded(true);
                        Alertify.success('Bulk Uploaded Successfully');
                    }
                })
                .catch((error) => {})
                .finally(endLoader);
        }
    }

    function toggleDeleteModal() {
        setDeleteModal(!delete_modal);
    }

    function toggleStatusModal() {
        setStatusModal(!status_modal);
    }

    // console.log("Bulk loader--------------", csv_file)

    function deleteProduct() {
        dispatch(DeleteProduct({ id: selected_product.key }))
            .unwrap()
            .then(() => toggleDeleteModal())
            .catch(() => {});
    }

    const newCol = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
        },
        {
            title: 'Hsn Code',
            dataIndex: 'hsnCode',
            key: 'hsnCode',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => new Date(createdAt).toLocaleString(),
        },
    ];

    function changeStatus(status) {
        console.log({ status });
        dispatch(
            ToggleProductStatus({
                id: selected_product.key,
                status: selected_product.status,
            })
        )
            .unwrap()
            .then(() => toggleStatusModal())
            .catch(() => {});
    }

    function handleCSVFile(file) {
        setCSVFile(file.target.files[0]);
    }

    const getSubCategory = (value) => {
        setLevel('second');
        setSelectedParent(value);
        const parentId = value;
        dispatch(CategoryListData({ level: 'second', parentId })).then(() =>
            setLoading(false)
        );
    };

    const getSubSubCategory = (value) => {
        setLevel('third');
        setSelectedSubCategory(value);
        const parentId = value;
        dispatch(CategoryListData({ level: 'third', parentId })).then(() =>
            setLoading(false)
        );
    };

    useEffect(() => {
        dispatch(PrimaryCategoryListData({ level: 'first' }));
    }, []);

    useEffect(() => {
        const payload = {
            search,
            page: Number(currentPage),
            pageSize: Number(pageSize),
        };

        if (productFilterUrl) {
            const filter = {};
            filter[productFilterUrl] = true;
            payload['filter'] = filter;
        }

        dispatch(ProductListData(payload)).then((res) => {
            setLoading(false);
        });
    }, [currentPage, pageSize, productFilterUrl]);

    const [addBulkModal, setaddBulkModal] = useState(false);

    const [zipFileName, setzipFileName] = useState('');
    const [uploadFile, setUploadFile] = useState('');

    const showAddBulk = () => {
        setaddBulkModal(!addBulkModal);
    };
    const closeModal = () => {
        setaddBulkModal(false);
        form.resetFields();
        // setCSVFile('')
    };

    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const debouncedRequest = useDebounce((callback) => callback(), 500);

    function searchProductBySku(type, value) {
        debouncedRequest(() => {
            dispatch(
                SearchProductBySkuDetailsInProductListing({
                    value,
                    is_search: true,
                })
            );
        });
    }

    const handleFileReader = (event) => {
        console.log(event);
        setUploadFile(event?.target?.files[0]);
        setzipFileName(event?.target?.files[0]?.name);
    };
    const ZipUpload = async () => {
        if (uploadFile === '') {
            alert('Please choose a zip file');
            return;
        }
        try {
            startLoader();
            const data = await dispatch(
                BulkImagesUpload({ productImages: uploadFile })
            )
                .then((res) => {
                    if (res?.payload.res?.status === 200) {
                        setBulkUploaded(true);
                        Alertify.success('Bulk Image Uploaded Successfully');
                        const data = _ManipulateErrors(
                            response?.payload.res?.data?.data?.errors
                        );
                        setImagesErrorOnTable(data);
                    }
                })
                .catch((error) => {})
                .finally(endLoader);
            let response = data;

            // toast.success(response?.payload?.res?.data?.message);
            setzipFileName('');
        } catch (error) {
            console.error('Error during zip upload:', error);
            alert('An error occurred during file upload');
        }
    };

    const handleZipUpload = (e) => {
        e?.preventDefault();
        ZipUpload();
    };

    const convertBytesToMB = (bytes) => {
        const megabytes = bytes / (1024 * 1024);
        return megabytes.toFixed(2);
    };

    return (
        <>
            <SearchBar
                onSearch={searchProductBySku}
                placeholder={'Search by SKU'}
            />
            <div className="bg-white pb-2 rounded-[6px]">
                <SimpleTable
                    tableName={'All Products Listings'}
                    openPopup={showAddBulk}
                    // showLogs={showAddLogs}
                    addNew={true}
                    addNewLink="/products/product-detail/0"
                    logs={true}
                    logsLink="/products/logs"
                    actionSelect={false}
                    productFilter={true}
                    export={true}
                    columns={all_columns}
                    dataSource={ProductData}
                    loading={loading}
                    placeholder={'products'}
                    exportModal={'product'}
                    parentCategoryOptions={parentCategoryOptions}
                />
                <div className="flex justify-center  py-10">
                    {' '}
                    <Pagination
                        current={currentPage}
                        total={product_count}
                        pageSize={pageSize}
                        onChange={paginate}
                        hideOnSinglePage
                        pageSizeOptions={[10, 20, 50]}
                    />{' '}
                </div>

                <Modal
                    title="Product Variants"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <Table
                        dataSource={variantListing.variants}
                        columns={newCol}
                    />
                </Modal>

                <ActiveInactiveModal
                    {...selected_product}
                    onToggle={changeStatus}
                    onCancel={toggleStatusModal}
                    visible={status_modal}
                />

                {delete_modal && (
                    <DeleteModal
                        {...selected_product}
                        onDelete={(item) => deleteProduct(item)}
                        onCancel={toggleDeleteModal}
                        visible={delete_modal}
                    />
                )}

                <Modal
                    centered
                    open={addBulkModal}
                    footer={false}
                    onCancel={closeModal}
                    width={1000}
                >
                    <div className="w-full h-full p-16">
                        <h1 className="text -14 font-bold mb-3">
                            {' '}
                            Bulk Upload
                        </h1>
                        <div className="flex  flex-col gap-4">
                            <div className="border-2 rounded-md">
                                <div className="flex justify-between  p-4 ">
                                    <div className="">
                                        <label className="text-[1vw] font-bold">
                                            Product Upload
                                        </label>
                                    </div>
                                    <input
                                        className="cursor-pointer"
                                        type="radio"
                                        value="option1"
                                        checked={selectedOption === 'option1'}
                                        onChange={handleOptionChange}
                                        style={{ transform: 'scale(1.5)' }}
                                    />
                                </div>
                                {selectedOption === 'option1' && (
                                    <div className="p-4">
                                        {/* Content of the div when option2 is selected */}
                                        <>
                                            <Form
                                                // form={}
                                                layout="vertical"
                                                // onFinish={}
                                                autoComplete="off"
                                                className=""
                                            >
                                                <Form.Item
                                                    className="mb-4"
                                                    name="firstCategoryId"
                                                    label={
                                                        <label className="#1A3353">
                                                            Category *
                                                        </label>
                                                    }
                                                >
                                                    <Select
                                                        className="w-full h-[40px]"
                                                        placeholder="Select Category"
                                                        // onChange={getSubCategory}
                                                        onChange={
                                                            getSubCategory
                                                        }
                                                        options={
                                                            parentCategoryOptions
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="mb-4"
                                                    name="secondCategoryId"
                                                    label={
                                                        <label className="#1A3353">
                                                            Sub Category *
                                                        </label>
                                                    }
                                                >
                                                    <Select
                                                        className="w-full h-[40px]"
                                                        placeholder="Select Sub Category"
                                                        onChange={
                                                            getSubSubCategory
                                                        }
                                                        options={
                                                            CategoryOptions
                                                        }
                                                    />
                                                </Form.Item>

                                                <Checkbox
                                                    checked={isBulkUpdate}
                                                    onChange={(e) =>
                                                        setIsBulkUpdate(
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    Bulk Update
                                                </Checkbox>
                                                {/* <Form.Item
                                                className="mb-4"
                                                name="thirdCategoryId"
                                                label={
                                                    <label className="#1A3353">
                                                        Sub Child Category *
                                                    </label>
                                                }
                                            >
                                                <Select
                                                    className="w-full h-[40px]"
                                                    placeholder="Select Sub Child Category"
                                                    options={subCategoryOptions}
                                                    onChange={setSubSubCategory}
                                                />
                                            </Form.Item> */}
                                                <Form.Item
                                                    className="my-4"
                                                    name="csvFile"
                                                    label={
                                                        <label className="#1A3353">
                                                            Upload CSV File *
                                                        </label>
                                                    }
                                                    valuePropName="fileList"
                                                    getValueFromEvent={normFile}
                                                >
                                                    {/* <Upload
                                                    name="csvFile"
                                                    beforeUpload={beforeUpload}
                                                    accept=".csv"
                                                    showUploadList={true}
                                                    onChange={handleCSVFile}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined />
                                                        }
                                                    >
                                                        Click to Upload
                                                    </Button>
                                                </Upload> */}
                                                    <div className="drag text-[#3B3B3B] font-semibold mt-6 text-[22px]">
                                                        Drag & drop files or{' '}
                                                        <span className="">
                                                            <label
                                                                htmlFor="img"
                                                                className="cursor-pointer text-[#FF8F2F] underline font-semibold my-6 text-[22px]"
                                                                onClick={() =>
                                                                    document
                                                                        .getElementById(
                                                                            'getFile'
                                                                        )
                                                                        .click()
                                                                }
                                                            >
                                                                Browse
                                                                <input
                                                                    type="file"
                                                                    data-max-size="2048"
                                                                    id="getFile"
                                                                    className="fileIcon invisible"
                                                                    onChange={
                                                                        handleCSVFile
                                                                    }
                                                                    multiple
                                                                />
                                                            </label>
                                                        </span>
                                                    </div>
                                                </Form.Item>
                                                {csv_file && (
                                                    <div
                                                        key={csv_file?.name}
                                                        className="flex justify-center mb-4"
                                                    >
                                                        <div className="rounded-3xl border-1 @sm:w-full lg:w-[80%]">
                                                            <div className="flex  justify-between @sm:px-4 lg:px-10  py-4">
                                                                <div>
                                                                    <p className="text-12 text-[black]">
                                                                        {csv_file
                                                                            ?.name
                                                                            ?.length >
                                                                        25
                                                                            ? `${csv_file?.name.slice(
                                                                                  0,
                                                                                  25
                                                                              )}...`
                                                                            : csv_file?.name}
                                                                    </p>
                                                                </div>
                                                                <div className="flex gap-9">
                                                                    <p className="text-12 font-normal text-[black]">
                                                                        <div>
                                                                            {convertBytesToMB(
                                                                                csv_file?.size
                                                                            )}{' '}
                                                                            MB
                                                                        </div>
                                                                    </p>
                                                                    <p className="text-12 font-normal text-[black]">
                                                                        {
                                                                            csv_file?.type
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {error_data && (
                                                    <ErrorTable
                                                        data={error_data}
                                                    />
                                                )}
                                                {/* <Form.Item>
                                                <List
                                                    renderItem={(file) => (
                                                        <List.Item>
                                                            {file.name}
                                                        </List.Item>
                                                    )}
                                                />
                                            </Form.Item> */}
                                                {bulkUploaded && (
                                                    <div className="flex justify-center">
                                                        <CheckCircleOutlined className="text-[#64de64] text-32" />
                                                    </div>
                                                )}
                                                <Button
                                                    className="py-2 mt-3 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                                                    onClick={bulkUpload}
                                                    loading={bulk_upload_loader}
                                                >
                                                    Upload
                                                </Button>
                                            </Form>
                                        </>
                                    </div>
                                )}
                            </div>
                            <div className=" border-2 rounded-md">
                                <div className="flex justify-between  p-4 ">
                                    <div className="">
                                        <label className="text-[1vw] font-bold">
                                            Image Upload
                                        </label>
                                    </div>
                                    <input
                                        type="radio"
                                        value="option2"
                                        checked={selectedOption === 'option2'}
                                        onChange={handleOptionChange}
                                        style={{ transform: 'scale(1.5)' }}
                                        className="cursor-pointer"
                                    />
                                </div>
                                {selectedOption === 'option2' && (
                                    <div className="p-4">
                                        <label
                                            htmlFor="fileUpload"
                                            className="block text-[1vw] font-bold mb-2"
                                        >
                                            Upload Bulk Images
                                        </label>
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            multiple
                                            onChange={handleFileReader}
                                        />

                                        {error_images_data && (
                                            <ErrorTable
                                                data={error_images_data}
                                            />
                                        )}

                                        {bulkUploaded && (
                                            <div className="flex justify-center mt-4">
                                                <CheckCircleOutlined className="text-[#64de64] text-32" />
                                            </div>
                                        )}

                                        <Button
                                            className="py-2 mt-3 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                                            onClick={(e) => {
                                                handleZipUpload(e);
                                            }}
                                            loading={bulk_upload_loader}
                                        >
                                            upload
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ProductListing;

const error_columns = [
    {
        title: 'LINE NUMBER',
        dataIndex: 'lineNumber',
    },
    {
        title: 'MESSAGE',
        dataIndex: 'message',
    },
    {
        title: 'VALUE',
        dataIndex: 'value',
    },
];

const ErrorTable = ({ data }) => {
    return (
        <>
            <Table
                columns={error_columns}
                dataSource={data}
                pagination={true}
            />
        </>
    );
};
