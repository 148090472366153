import { createSlice } from '@reduxjs/toolkit';
import {
    BulkErrorLogs,
    GetProductDetails,
    ManipulateProductLists,
    ProductListData,
    _ManipulateErrorsLogsList,
} from '../actions/product-action';
import { SearchProductBySkuDetailsInProductListing } from '../actions/inventory-action';

const initialState = {
    is_login: false,
    productList: [],
    search: '',
    product_count: 0,
    product_details: {},
    bulk_errors: [],
    bulkErros_count: 0,
    productFilter :'',
    firstCategoryId: '',
};

const productSlice = createSlice({
    name: 'product-slice',
    initialState,
    reducers: {
        SetProductSearch: (state, { payload }) => {
            // console.log('payload', payload);
            state['search'] = payload.value;
        },
        SetProductData: (state, { payload }) => {
            // console.log('payload', payload);
            state['search'] = payload.value;
        },
        SetCategoryId: (state, { payload }) => {
            // console.log('payload', payload);
            state.firstCategoryId = payload;
        },
        ClearProductDetailsData: (state, { payload }) => {
            state.product_details = {};
        },
        SetProductFilter: (state, { payload }) => {
            state.productFilter = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ProductListData.fulfilled, (state, { payload }) => {
            state.productList = ManipulateProductLists(payload.data?.list);
            state.product_count = payload.data?.count;
        });
        builder.addCase(
            SearchProductBySkuDetailsInProductListing.fulfilled,
            (state, { payload }) => {
                if (payload.is_search === true) {
                    state.productList = ManipulateProductLists(
                        payload.res.data.data.list
                    );
                    state.product_count = payload.res.data.data.count;
                }
            }
        );
        builder.addCase(GetProductDetails.fulfilled, (state, { payload }) => {
            let {
                firstCategoryDetails,
                secondCategoryDetails,
                thirdCategoryDetails,
            } = payload.res.data[0];
            firstCategoryDetails = {
                label: firstCategoryDetails[0]?.name,
                value: firstCategoryDetails[0]?._id,
            };
            secondCategoryDetails = {
                label: secondCategoryDetails[0]?.name,
                value: secondCategoryDetails[0]?._id,
            };
            thirdCategoryDetails = {
                label: thirdCategoryDetails[0]?.name,
                value: thirdCategoryDetails[0]?._id,
            };
            let attr = {};
            payload.res.data[0].parentAttributes.forEach((item) => {
                if (item.inputType === 'dropdown') {
                    if (item.multiple === true) {
                        const val = item.value.map((item) => item._id);
                        attr = { ...attr, [item.key]: val };
                    } else {
                        attr = { ...attr, [item.key]: item.value[0]._id };
                    }
                } else if (item.inputType === 'textBox') {
                    attr = { ...attr, [item.key]: item.value[0].value };
                }
            });
            const productVariants = payload.res.data[0].productVariants.map(
                (item) => {
                    let attributes = {};
                    item.attributes.forEach(({ key, value }) => {
                        attributes = { ...attributes, [key]: value };
                    });
                    return { ...item, attributes };
                }
            );
            state.product_details = {
                ...payload.res.data[0],
                attr,
                productVariants,
                firstCategoryDetails,
                secondCategoryDetails,
                thirdCategoryDetails,
            };
        });
        builder.addCase(BulkErrorLogs.fulfilled, (state, { payload }) => {
            // console.log('Payload-----------------', payload);
            state.bulk_errors = _ManipulateErrorsLogsList(payload?.data?.logs);
            state.bulkErros_count = payload?.data?.total;
        });
    },
});

export const { SetProductSearch, SetProductData, ClearProductDetailsData,SetProductFilter,SetCategoryId } =
    productSlice.actions;

export default productSlice.reducer;
