import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import {
    BULK_ERROR_LOGS,
    BULK_UPDATE_UPLOAD_PRODUCT,
    BULK_UPLOAD_IMAGES,
    BULK_UPLOAD_PRODUCT,
    CREATE_PRODUCT,
    DELETE_PRODUCT,
    GET_PRODUCT_DETAILS,
    PRODUCT_EXPORT,
    PRODUCT_LIST,
    SUB_ATTRIBUTES_LIST,
    TOGGLE_PRODUCT_STATUS,
    UPDATE_PRODUCT,
    UPLOAD_IMAGE,
} from '../../constant/api-constant';

import { CATEGORY_OBJECT } from '../../constant/category-constant';
import Alertify from '../../scripts/alertify';
import toast from 'react-hot-toast';
import { formatDate } from '../../screens/orders/order-list/orders';

/*-----------------Product List----------------*/

export const ProductListData = createAsyncThunk(
    'products/products-list',
    async ({ key = 'search', value = '', page = 1, pageSize = 10, filter }) => {
        try {
            const response = await postApi(PRODUCT_LIST, {
                [key]: value,
                page,
                pageSize,
                filter,
            });
            // console.log('Response-----------', response);

            return response.data;
        } catch (error) {
            console.error('Error:', error);
            return Promise.reject();
        }
    }
);

/*------------------ Product Data---------------------*/

export const GetProductDetails = createAsyncThunk(
    'GetProductDetails',
    async ({ id }, { getState }) => {
        try {
            const res = await getApi(`${GET_PRODUCT_DETAILS}/${id}`);
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
);

/*------------------Manipulate Product List---------------------*/

export function ManipulateProductLists(data) {
    return data?.map((item) => ({
        key: item._id,
        name: item.name,
        sku: item.sku,
        hsn: item.hsn || '',
        gst: `${item.gst}%`,
        status: item.status,
        type: item.type,
        category: CATEGORY_OBJECT[item.firstCategoryId],
        variants: item.variants,
    }));
}

export const DeleteProduct = createAsyncThunk(
    'DeleteProduct',
    async ({ id }, { dispatch }) => {
        try {
            const res = await deleteApi(`${DELETE_PRODUCT}/${id}`);
            dispatch(ProductListData({}));
            return res;
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
);

export const UpdateProduct = createAsyncThunk(
    'UpdateProduct',
    async ({ id, payload }, { getState, dispatch }) => {
        try {
            const { product_details } = getState().product_store;
            const res = await patchApi(`${UPDATE_PRODUCT}/${id}`, {
                ...product_details,
                ...payload,
                attributes: { ...payload.attributes, brand: payload.brand },
                status: payload.status ? 'active' : 'inactive',
                QnA: payload.QnA?.length > 0 ? payload.QnA : undefined,
            });

            Alertify.success('Product is updated successfully');
            return { res };
        } catch (error) {
            console.error(error.message);
            error.message.map((item, index) => {
                return toast.error(item);
            });

            return Promise.reject();
        }
    }
);

export const ToggleProductStatus = createAsyncThunk(
    'ToggleProductStatus',
    async ({ id, status }, { getState, dispatch }) => {
        try {
            const res = await patchApi(`${TOGGLE_PRODUCT_STATUS}/${id}`, {
                status: status === 'active' ? 'inactive' : 'active',
            });
            dispatch(ProductListData({}));
            Alertify.success('Status is changed successfully');
            return { res };
        } catch (error) {
            console.error(error.message);
            error.message.map((item, index) => {
                return Alertify.error(item);
            });

            return Promise.reject();
        }
    }
);

export const CreateNewProduct = createAsyncThunk(
    'CreateNewProduct',
    async ({ payload }) => {
        try {
            const res = await postApi(CREATE_PRODUCT, {
                ...payload,
                gst: Number(payload.gst) || undefined,
                status: payload.status ? 'active' : 'inactive',
                attributes: { ...payload.attributes, brand: payload.brand },
                itemCode: payload.sku.trim(),
                QnA: payload.QnA.length > 0 ? payload.QnA : undefined,
            });
            Alertify.success('Product is created successfully');
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const GetAttributesValues = createAsyncThunk(
    'GetAttributesValues',
    async ({
        search,
        // page = 1,
        // pageSize = 10,
        attribute,
    }) => {
        try {
            const response = await postApi(SUB_ATTRIBUTES_LIST, {
                search,
                // page,
                // pageSize,
                attribute,
            });
            return Promise.resolve(response.data);
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
);

export const UploadImages = createAsyncThunk(
    'UploadImages',
    async ({ images, index, type }) => {
        try {
            const form_data = new FormData();

            images.forEach((image) => {
                form_data.append('images', image);
            });

            const res = await postApi(UPLOAD_IMAGE, form_data, {
                'Content-Type': 'multipart/form-data',
            });
            Alertify.success('Image successfully uploaded');
            return { res, index };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const BulkProductUpload = createAsyncThunk(
    'BulkProductUpload',
    async (data) => {
        try {
            const form_data = new FormData();
            Object.entries(data).forEach(([key, value]) =>
                form_data.append(key, value)
            );
            const res = await postApi(BULK_UPLOAD_PRODUCT, form_data, {
                'Content-Type': 'multipart/form-data',
            });
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const BulkUpdateProductUpload = createAsyncThunk(
    'BulkUpdateProductUpload',
    async (data) => {
        try {
            const form_data = new FormData();
            Object.entries(data).forEach(([key, value]) =>
                form_data.append(key, value)
            );
            const res = await patchApi(BULK_UPDATE_UPLOAD_PRODUCT, form_data, {
                'Content-Type': 'multipart/form-data',
            });
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const BulkErrorLogs = createAsyncThunk(
    'BulkErrorLogs',
    async ({ page, pageSize }) => {
        try {
            const res = await postApi(BULK_ERROR_LOGS, {
                page,
                pageSize,
            });
            return res.data;
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const ProductExport = createAsyncThunk(
    'productExport',
    async ({ firstCategoryId }) => {
        try {
            const res = await postApi(PRODUCT_EXPORT, {
                firstCategoryId: [firstCategoryId],
            });
            Alertify.success('Product exported successfully');
            return res.data;
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const BulkImagesUpload = createAsyncThunk(
    'BulkImagesUpload',
    async (data) => {
        try {
            const form_data = new FormData();

            Object.entries(data).forEach(([key, value]) =>
                form_data.append(key, value)
            );

            const res = await patchApi(BULK_UPLOAD_IMAGES, form_data, {
                'Content-Type': 'multipart/form-data',
            });
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error?.message)
            return Promise.reject(error);
        }
    }
);

export function _ManipulateErrors(data = []) {
    return data.map((error) => ({
        lineNumber: error.lineNumber,
        message: error.message,
        value: error.value,
        id: error._id,
    }));
}

export function _ManipulateErrorsLogsList(data = []) {
    return data.map((item) => ({
        key: item?._id,
        name: item?.fileName,
        type: item._id,
        date: formatDate(item.createdAt),
        detailType: item?.productDetailsFile
            ? 'Bulk Uploaded'
            : item?.productDetailsUploadFile
            ? 'Bulk Updated'
            : '-',
        totalData: item?.totalData,
        errorData: item?.errors,
    }));
}

/*
 {
            key: 1,
            name: 'Product 1',
            sku: '1121',
            type: 'ABC',
            date:'12-03-2024',
            detailType:'Updated File',
            totalData:'1'
        },
*/
