import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    ConfigProvider,
    DatePicker,
    Input,
    InputNumber,
    Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import {
    EditReview,
    GetReviewDetails,
} from '../../redux/actions/review-action';

const ReviewDetails = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const reviewDetailsList = useSelector(
        (state) => state.review_store.reviewDetailsList
    );
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(GetReviewDetails({ id })).then((res) => {
            setIsLoading(true);
        });
    }, [id]);

    function goBack() {
        window.history.back();
    }

    function toggleStatus(id, status) {
        // console.log("Id from status: -----------", id)
        alert('are you sure want to change the status');
        dispatch(EditReview({ id, payload: { status } }));
    }

    const handleSubmit = (form) => {
        const data = {
            productId: reviewDetailsList?.productId?._id,
            rating: String(form.rating),
            review: form.review,
            status: reviewDetailsList?.status,
        };
        // console.log('Form data-----', data);
        dispatch(
            EditReview({ id, payload: data }).then((res)=>{
                navigate('/reviews')
            })
        );
    };

    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <div className="flex gap-4 items-center p-4 border-b-1">
                <Link to="#">
                    <ArrowLeftOutlined onClick={goBack} />
                </Link>
                <h1 className="table-name">Review Details</h1>
            </div>
            <div className="p-4 mt-2 rounded-lg">
                <div className="border-1 rounded-lg p-8 mt-6">
                    <div className="flex gap-4 ms-auto justify-end items-center mb-6">
                        <strong>Status:</strong>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimaryBorder: '#6E3362',
                                    colorPrimary: '#6E3362',
                                },
                            }}
                        >
                            <Switch
                                // defaultChecked
                                checked={reviewDetailsList?.status}
                                className="flex self-center"
                                onClick={() =>
                                    toggleStatus(
                                        reviewDetailsList?._id,
                                        !reviewDetailsList.status
                                    )
                                }
                                // style={{ border: "1px solid #6E3362" }}
                            />
                        </ConfigProvider>
                    </div>
                    <ConfigProvider
                        theme={{
                            components: {
                                InputNumber: {
                                    controlWidth: 0,
                                    controls: false,
                                },
                            },
                        }}
                    >
                        {isLoading && (
                            <Form
                                layout="vertical"
                                onFinish={handleSubmit}
                                initialValues={{
                                    name: `${reviewDetailsList?.userId?.firstName} ${reviewDetailsList?.userId?.lastName}`,
                                    productName:
                                        reviewDetailsList?.productId?.name,
                                    rating: reviewDetailsList?.rating,
                                    review: reviewDetailsList?.review,
                                    // date: reviewDetailsList?.createdAt,
                                }}
                            >
                                <div className="flex gap-4">
                                    <Form.Item
                                        className="w-4/5"
                                        name="name"
                                        label={
                                            <label
                                                className="text-[#1A3353]"
                                                htmlFor="name"
                                            >
                                                Name *
                                            </label>
                                        }
                                    >
                                        <Input
                                            // onChange={}
                                            disabled
                                            autoComplete="name"
                                            className="h-[40px]"
                                            placeholder="Sumit"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="w-4/5"
                                        name="productName"
                                        label={
                                            <label
                                                className="text-[#1A3353]"
                                                htmlFor="productName"
                                            >
                                                Product Name *
                                            </label>
                                        }
                                    >
                                        <Input
                                            // onChange={}
                                            disabled
                                            name="productName"
                                            className="h-[40px]"
                                            placeholder="Purna Gummies Sugar Free Apple Cider Vinegar"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="flex gap-4">
                                    <Form.Item
                                        className="w-4/5"
                                        name="rating"
                                        label={
                                            <label
                                                className="text-[#1A3353]"
                                                htmlFor="rating"
                                            >
                                                Rating *
                                            </label>
                                        }
                                    >
                                        <InputNumber
                                            className="w-full grid items-center h-[40px]"
                                            controls={false}
                                            name="rating"
                                            min={1}
                                            max={5}
                                            placeholder="5"
                                            // disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="w-4/5"
                                        name="date"
                                        label={
                                            <label
                                                className="text-[#1A3353]"
                                                htmlFor="date"
                                            >
                                                Date *
                                            </label>
                                        }
                                    >
                                        <DatePicker
                                            className="w-full h-[40px]"
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Form.Item>
                                </div>
                                <Form.Item
                                    name="review"
                                    label={
                                        <label
                                            className="text-[#1A3353]"
                                            htmlFor="review"
                                        >
                                            Review *
                                        </label>
                                    }
                                >
                                    <TextArea
                                        // disabled
                                        rows={6}
                                        name="review"
                                        className="w-full"
                                    />
                                </Form.Item>
                                <div className="flex gap-4 w-2/5 mt-6">
                                    <Button
                                        htmlType="submit"
                                        className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                    >
                                        SAVE CHANGES
                                    </Button>
                                    <Button
                                        htmlType="reset"
                                        className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                        onClick={goBack}
                                    >
                                        CANCEL
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </ConfigProvider>
                </div>
            </div>
        </div>
    );
};
export default ReviewDetails;
