import React, { useEffect, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import { ConfigProvider, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Modal, Select } from 'antd/es';
import { useDispatch, useSelector } from 'react-redux';
import {
    StoreDetail,
    StoreList,
    UpdateStore,
} from '../../redux/actions/store-action';
import { StoreListId } from '../../redux/slices/store-slice';
import Alertify from '../../scripts/alertify';

const StoreListing = () => {
    const dispatch = useDispatch();
    const [addNewModal, setAddNewModal] = useState(false);
    const [toggleValue, setToggleValue] = useState();
    const [checked, setChecked] = useState();
    const showAddNew = () => {
        setAddNewModal(true);
    };
    const closeModal = () => {
        setAddNewModal(false);
    };
    const [form] = Form.useForm();
    const storeList = useSelector((state) => state.store.storeList);
    const Id = useSelector((state) => state.store.storeListId);
    const storeDetail = useSelector((state) => state.store.storeDetail);

    const onChange = (checked) => {
        setChecked(checked);
    };
    const columns = [
        {
            title: 'STORE NAME',
            dataIndex: 'name',
            render: (name, record) => (
                <Link to={`/stores/store-detail/${record._id}`}>{name}</Link>
            ),
        },
        {
            title: 'PHONE NUMBER',
            dataIndex: 'mobile',
            render: (mobile, record) => (
                <Link to={`/stores/store-detail/${record._id}`}>{mobile}</Link>
            ),
        },
        {
            title: 'ADDRESS',
            dataIndex: 'address',
            render: (address, record) => (
                <Link to={`/stores/store-detail/${record._id}`}>{address}</Link>
            ),
        },
    ];

    useEffect(() => {
        let toggleValue = checked === false ? 'inactive' : 'active';
        setToggleValue(toggleValue);
        dispatch(StoreList({ page: 1, pageSize: 10, status: toggleValue }));
    }, [dispatch, checked, toggleValue]);

    const action_column = [
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (id, record) => (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimaryBorder: '#6E3362',
                            colorPrimary: '#6E3362',
                        },
                    }}
                >
                    <Switch
                        defaultChecked={record.status === 'active'}
                        className="flex self-center"
                        onChange={onChange}
                    />
                </ConfigProvider>
            ),
        },
        {
            title: '',
            dataIndex: 'action',
            render: (id, record) => {
                return (
                    <span className="flex">
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px] cursor-pointer"
                            onClick={() => {
                                dispatch(StoreListId(id));
                                dispatch(StoreDetail({ id: id })).then(() => {
                                    showAddNew();
                                });
                            }}
                        />
                    </span>
                );
            },
        },
    ];

    const updateStoreApi = (form) => {
        dispatch(
            UpdateStore({
                id: Id,
                form: {
                    ...form,
                    geoLocation: {
                        long: form.longitude,
                        lat: form.latitude,
                    },
                    type: 'store',
                    isDeliveryAllowed: true,
                },
            })
        ).then(() => {
            Alertify.success('Store details updated successfully');
            dispatch(StoreDetail({ id: Id }));
            dispatch(StoreList({ page: 1, pageSize: 10, status: toggleValue }));
        });
    };

    const all_columns = [...columns, ...action_column];

    return (
        <>
            <SimpleTable
                tableName="Store"
                showAddNew={showAddNew}
                actionSelect={true}
                export={true}
                columns={all_columns}
                dataSource={storeList}
                // loading={loading}
            />
            <Modal
                centered
                open={addNewModal}
                footer={false}
                onCancel={closeModal}
                width={1000}
            >
                <div className="w-full h-full p-16">
                    <h1 className="table-name mb-4">Add New Store</h1>
                    <Form
                        form={form}
                        onFinish={updateStoreApi}
                        layout="vertical"
                        autoComplete="off"
                        initialValues={{
                            name: storeDetail?.name,
                            phoneNumber: storeDetail?.mobile,
                            address: storeDetail?.address,
                            status: storeDetail?.status,
                            state: storeDetail?.state,
                            pincode: storeDetail?.pincode,
                            longitude: storeDetail?.location?.coordinates?.[0],
                            latitude: storeDetail?.location?.coordinates?.[1],
                        }}
                    >
                        <div className="flex gap-8 ">
                            <Form.Item
                                className="w-1/2"
                                name="name"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Store Name
                                    </label>
                                }
                            >
                                <Input
                                    placeholder="ABC Store"
                                    className="h-[40px]"
                                    name="name"
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                name="phoneNumber"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Phone Number
                                    </label>
                                }
                            >
                                <Input
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    className="h-[40px]"
                                />
                            </Form.Item>
                        </div>
                        <div className="flex gap-8 ">
                            <Form.Item
                                className="w-1/2"
                                name="address"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Address
                                    </label>
                                }
                            >
                                <Input
                                    placeholder="abc@example.com"
                                    className="h-[40px]"
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                name="status"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Status
                                    </label>
                                }
                            >
                                <Select
                                    className="h-[40px]"
                                    placeholder="Please Select the Store status"
                                    options={[
                                        { value: 'Active', label: 'Active' },
                                        {
                                            value: 'inactive',
                                            label: 'Inactive',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className="flex gap-8 ">
                            <Form.Item
                                className="w-1/2"
                                name="state"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        State
                                    </label>
                                }
                            >
                                <Input
                                    placeholder="New Delhi"
                                    className="h-[40px]"
                                    name="state"
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                name="pincode"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Pincode
                                    </label>
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder="Enter pincode"
                                    className="h-[40px]"
                                />
                            </Form.Item>
                        </div>
                        <div className="flex gap-8 ">
                            <Form.Item
                                className="w-1/2"
                                name="latitude"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Latitude
                                    </label>
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder="latitude"
                                    className="h-[40px]"
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                name="longitude"
                                label={
                                    <label style={{ color: '#1A3353' }}>
                                        Longitude
                                    </label>
                                }
                            >
                                <Input
                                    type="text"
                                    placeholder="longitude"
                                    className="h-[40px]"
                                />
                            </Form.Item>
                        </div>
                        <div className="flex gap-4 w-1/2 mt-6">
                            <Button
                                htmlType="submit"
                                className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                            >
                                SAVE CHANGES
                            </Button>
                            <Button
                                onClick={() => closeModal()}
                                className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                            >
                                CANCEL
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default StoreListing;
