import React, { useState, useEffect } from 'react';
import { Tabs, ConfigProvider, Modal, Pagination, Switch } from 'antd';
import SimpleTable from '../../../components/tables/simpletable';

import { useDispatch, useSelector } from 'react-redux';
import {
    CustomersListData,
    DeleteUserRole,
    DeliveryListData,
    DoctorListData,
    GetPharmaUsersList,
    UpdatePharmaUser,
    deliveryRole,
    updateDeliveryPartner,
} from '../../../redux/actions/user-action';
import AddNewRoleUser from '../../../components/userDetails/addNewDeliveryPartner';

import useAppSelector from '../../../hooks/useAppSelector';

import SearchBar from '../../../components/topbar/searchbar';
import { useDebounce } from '../../../hooks/useDebounce';

// columns for cutomer
const customerColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'NAME',
        dataIndex: 'name',
    },
    {
        title: 'CREATED ON',
        dataIndex: 'createDate',
    },
    {
        title: 'PHONE NUMBER',
        dataIndex: 'phno',
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
    },
    {
        title: '',
        dataIndex: 'action',
        width: 90,
    },
];

// columns for admin
const adminColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
    },
    {
        title: 'NAME',
        dataIndex: 'name',
    },
    {
        title: 'CREATED ON',
        dataIndex: 'createDate',
    },
    {
        title: 'PHONE NUMBER',
        dataIndex: 'phno',
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
    },
    {
        title: '',
        dataIndex: 'action',
        width: 90,
    },
];

const CustomersList = () => {
    const [loading, setLoading] = useState(true);
    const detailID = useSelector((state) => state.customer_store.detailID);
    const [userActiveTab, setUserActiveTab] = useState(
        localStorage.getItem('userActiveTab') || '1'
    );
    const [customerSearchValue, setCustomerSearchValue] = useState('');
    const [doctorSearchValue, setDoctorSearchValue] = useState('');
    const [deliverySearchValue, setDeliverySearchValue] = useState('');
    const [deliveryPersonId, setDeliveryPersonId] = useState(null);
    const [pharmaSearchValue, setPharmaSearchValue] = useState('');
    const role = useSelector((state) => state.auth_store.role);
    const [user_details, setUserDetails] = useState({});
    const dispatch = useDispatch();
    const debouncedRequest = useDebounce((callback) => callback(), 500);
    const customers = useSelector((state) => state.customer_store.customers);
    const deliveryData = useSelector(
        (state) => state.customer_store.delivery_partner
    );
    const doctors = useSelector((state) => state.customer_store.doctors);
    const search = useSelector((state) => state.customer_store.search);
    const customer_count = useSelector(
        (state) => state.customer_store.customer_count
    );
    const delivery_count = useSelector(
        (state) => state.customer_store.delivery_count
    );
    const doctor_count = useSelector(
        (state) => state.customer_store.doctor_count
    );
    const store = useAppSelector((state) => ({
        pharmaLists: state.customer_store.pharmaLists,
        pharmaCounts: state.customer_store.pharmaCounts,
    }));

    const onChangeSwitch = (checked, listId, role) => {
        let toggleValue = checked === false ? 'inactive' : 'active';
        if (role === 'Pharma') {
            dispatch(
                UpdatePharmaUser({
                    id: listId,
                    payload: { status: toggleValue },
                })
            );
        } else {
            dispatch(
                updateDeliveryPartner({
                    id: listId,
                    status: toggleValue,
                })
            );
        }
    };

    const handleSearchCustomer = (key, value) => {
        setCustomerSearchValue(value);
    };

    const handleSearchDoctor = (key, value) => {
        setDoctorSearchValue(value);
    };

    const handleSearchDelivery = (key, value) => {
        setDeliverySearchValue(value);
    };

    function handleSearchPharmacy(key, value) {
        debouncedRequest(() => {
            dispatch(GetPharmaUsersList({ search: value }));
        });
    }

    useEffect(() => {
        dispatch(CustomersListData({ search })).then((res) => {
            setLoading(false);
        });
    }, []);

    function getCustomerLists(page, pageSize) {
        dispatch(
            CustomersListData({ page, pageSize, search: customerSearchValue })
        ).then(() => setLoading(false));
    }
    function getDoctorLists(page, pageSize) {
        dispatch(
            DoctorListData({ page, pageSize, search: doctorSearchValue })
        ).then(() => setLoading(false));
    }

    // columns for doctor
    const doctorColumns = [
        {
            title: 'ID',
            dataIndex: 'key',
        },
        {
            title: 'NAME',
            dataIndex: 'name',
        },
        {
            title: 'CREATED ON',
            dataIndex: 'createdAt',
            render: (createdAt) =>
                new Date(createdAt).toLocaleString('en-US', { createdAt }),
        },
        {
            title: 'PHONE NUMBER',
            dataIndex: 'mobile',
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
        },
        // {
        //     title: 'STORE LOCATION',
        //     dataIndex: 'storeLocation',
        // },
        {
            title: '',
            dataIndex: 'action',
            width: 90,
        },
    ];
    const doctorAdditonalCol = {
        title: 'ACTION',
        dataIndex: 'action',
        render: (_, item) => {
            return (
                <span className="flex gap-2">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                        onClick={() => {
                            setUserDetails(item);
                            setAddNewModal(true);
                        }}
                    />

                    <div
                        onClick={() =>
                            dispatch(
                                DeleteUserRole({
                                    id: item.key,
                                    role: item?.roles[0]?.name,
                                })
                            )
                        }
                        className="cursor-pointer"
                    >
                        <img
                            src="/icons/common/delete.svg"
                            alt="delete"
                            className="h-[20px]"
                        />
                    </div>
                </span>
            );
        },
    };

    // Delivery Partner columns and data
    const deliveryColumns = [
        {
            title: 'ID',
            dataIndex: '_id',
        },
        {
            title: 'NAME',
            dataIndex: 'name',
        },
        {
            title: 'PHONE NUMBER',
            dataIndex: 'mobile',
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
        },
    ];
    const action_column = [
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (id, record) => {
                return (
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimaryBorder: '#6E3362',
                                colorPrimary: '#6E3362',
                            },
                        }}
                    >
                        <Switch
                            defaultChecked={record.status === 'active'}
                            className="flex self-center"
                            onChange={(checked) =>
                                onChangeSwitch(
                                    checked,
                                    record?.key ?? record._id,
                                    record?.roles[0]?.name
                                )
                            }
                        />
                    </ConfigProvider>
                );
            },
        },
        {
            title: '',
            dataIndex: 'action',
            render: (id, record) => {
                return (
                    <span className="flex gap-3">
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px] cursor-pointer"
                            onClick={() => {
                                setUserDetails(record);
                                setAddNewModal(true);
                            }}
                        />
                        {userActiveTab === '5' ? (
                            <div
                                onClick={() => deleteUserRole(record)}
                                className="cursor-pointer"
                            >
                                <img
                                    src="/icons/common/delete.svg"
                                    alt="delete"
                                    className="h-[20px]"
                                />
                            </div>
                        ) : null}
                    </span>
                );
            },
        },
    ];

    function deleteUserRole(record) {
        dispatch(
            DeleteUserRole({ id: record._id, role: record.roles[0].name })
        );
    }

    const all_columns = [...deliveryColumns, ...action_column];
    const combinedDoctorColumns = [...doctorColumns, doctorAdditonalCol];

    useEffect(() => {
        dispatch(deliveryRole()).then((res) => {
            const fetchedDeliveryRoleId = res?.payload?.data?.list;
            // Check if fetchedDeliveryRoleId is an array and not empty
            if (
                Array.isArray(fetchedDeliveryRoleId) &&
                fetchedDeliveryRoleId.length > 0
            ) {
                // Find the object with the name "Delivery Person"
                const deliveryPerson = fetchedDeliveryRoleId.find(
                    (role) => role.name === 'Delivery Person'
                );

                // If a delivery person is found, set their _id
                if (deliveryPerson) {
                    setDeliveryPersonId(deliveryPerson._id);
                    getDeliveryPartnersLists(
                        1,
                        10,
                        deliveryPerson._id,
                        deliverySearchValue
                    );
                }
            }
        });
    }, [dispatch, deliveryPersonId]);

    const getDeliveryPartnersLists = async (page, pageSize) => {
        if (deliveryPersonId) {
            await dispatch(
                DeliveryListData({
                    page,
                    pageSize,
                    deliveryPersonId,
                    search: deliverySearchValue,
                })
            ).then((res) => {
                setLoading(false);
            });
        } else {
            // console.log('personId is not available');
        }
    };

    useEffect(() => {
        getCustomerLists();
        getDoctorLists();
        dispatch(GetPharmaUsersList({ search: pharmaSearchValue }));
    }, [customerSearchValue, doctorSearchValue, pharmaSearchValue]);

    useEffect(() => {
        getDeliveryPartnersLists();
    }, [deliverySearchValue]);

    const [addNewModal, setAddNewModal] = useState(false);
    const showAddNew = () => {
        setAddNewModal(!addNewModal);
        setUserDetails({});
    };
    const closeModal = () => {
        setAddNewModal(false);
    };

    // items for tabs
    const items = [
        {
            key: '1',
            label: 'Customers',
            children: (
                <>
                    <SearchBar
                        onSearch={handleSearchCustomer}
                        placeholder={'Search Customer'}
                    />
                    <SimpleTable
                        tableName="Customers"
                        showAddNew={false}
                        selectDate={false}
                        actionSelect={true}
                        loading={loading}
                        export={true}
                        columns={customerColumns}
                        dataSource={customers}
                    />
                    <div className="flex justify-center py-10">
                        {' '}
                        <Pagination
                            defaultCurrent={1}
                            total={customer_count}
                            onChange={(page, size) =>
                                getCustomerLists(page, size)
                            }
                            hideOnSinglePage
                            pageSizeOptions={[10, 20, 50]}
                        />{' '}
                    </div>
                </>
            ),
        },
        // {
        //     key: '2',
        //     label: 'Admin/Sub Admin',
        //     children: (
        //         <SimpleTable
        //             tableName="Admin/Sub-Admin"
        //             // showAddNew={showAddNewButtonForAdmin ? showAddNew : null}
        //             selectDate={true}
        //             actionSelect={true}
        //             export={true}
        //             // columns={adminColumns}
        //             dataSource={[]}
        //         />
        //     ),
        // },
        {
            key: '3',
            label: 'Doctor',
            children: (
                <>
                    <SearchBar
                        onSearch={handleSearchDoctor}
                        placeholder={'Search Doctor'}
                    />{' '}
                    <SimpleTable
                        tableName="Doctors"
                        showAddNew={showAddNew}
                        selectDate={true}
                        actionSelect={true}
                        export={true}
                        columns={combinedDoctorColumns}
                        dataSource={doctors}
                    />
                    <div className="flex justify-center py-10">
                        {' '}
                        <Pagination
                            defaultCurrent={1}
                            total={doctor_count}
                            onChange={(page, size) =>
                                dispatch(
                                    DoctorListData({
                                        page,
                                        pageSize: size,
                                        search: doctorSearchValue,
                                    })
                                )
                            }
                            hideOnSinglePage
                            pageSizeOptions={[10, 20, 50]}
                        />{' '}
                    </div>
                </>
            ),
        },
        {
            key: '4',
            label: 'Delivery Partner',
            children: (
                <>
                    <SearchBar
                        onSearch={handleSearchDelivery}
                        placeholder={'Search Delivery'}
                    />
                    <SimpleTable
                        tableName="Delivery Partner"
                        showAddNew={showAddNew}
                        actionSelect={true}
                        export={true}
                        columns={all_columns}
                        dataSource={deliveryData}
                    />
                    <div className="flex justify-center py-10">
                        {' '}
                        <Pagination
                            defaultCurrent={1}
                            total={delivery_count}
                            onChange={(page, size) =>
                                getDeliveryPartnersLists(
                                    page,
                                    size,
                                    deliveryPersonId,
                                    deliverySearchValue
                                )
                            }
                            hideOnSinglePage
                            pageSizeOptions={[10, 20, 50]}
                        />{' '}
                    </div>
                </>
            ),
        },
        {
            key: '5',
            label: 'Pharmacy',
            children: (
                <>
                    <SearchBar
                        onSearch={handleSearchPharmacy}
                        placeholder={'Search Pharmacy '}
                    />
                    <SimpleTable
                        tableName="Pharma"
                        showAddNew={showAddNew}
                        actionSelect={true}
                        export={true}
                        columns={all_columns}
                        dataSource={store.pharmaLists}
                    />
                    <div className="flex justify-center py-10">
                        {' '}
                        <Pagination
                            defaultCurrent={1}
                            total={store.pharmaCounts}
                            onChange={(page, size) =>
                                dispatch(
                                    GetPharmaUsersList({ page, pageSize: size })
                                )
                            }
                            hideOnSinglePage
                            pageSizeOptions={[10, 20, 50]}
                        />{' '}
                    </div>
                </>
            ),
        },
    ];

    const onChange = (key) => {
        setUserActiveTab(key);
    };
    useEffect(() => {
        localStorage.setItem('userActiveTab', userActiveTab);
    }, [userActiveTab]);
    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            itemSelectedColor: '#1A3353',
                            itemColor: '#455560',
                            inkBarColor: '#1A3353',
                            itemHoverColor: '#5f5a5a',
                        },
                    },
                }}
            >
                <Tabs
                    defaultActiveKey={userActiveTab}
                    items={items}
                    onChange={onChange}
                    className="users-tab"
                />
            </ConfigProvider>
            {addNewModal && (
                <>
                    <Modal
                        centered
                        open={addNewModal}
                        footer={false}
                        onCancel={closeModal}
                        width={1000}
                    >
                        <div className="w-full h-full p-16">
                            <AddNewRoleUser
                                id={detailID}
                                closeModal={closeModal}
                                user_details={user_details}
                            />
                        </div>
                    </Modal>
                </>
            )}
        </>
    );
};

export default CustomersList;
