export const roleOptions = {
    Doctor: [
        {
            key: 'Pending',
            value: 'pending',
        },
        {
            key: 'Prescription Uploaded',
            value: 'Prescription-Uploaded',
        },
    ],
    Pharma: [
        {
            key: 'Prescription Uploaded',
            value: 'Prescription-Uploaded',
        },
        { key: 'Confirmed', value: 'confirmed' },
        {
            key: 'Pending',
            value: 'pending',
        },
    ],
    'Delivery Person': [
        {
            key: 'Packed',
            value: 'packed ',
        },
        {
            key: 'Shipped',
            value: 'shipped',
        },
        // {
        //     key: 'Delivered',
        //     value: 'delivered',
        // },
    ],
    'Super Admin': [
        {
            key: 'Pending',
            value: 'pending',
        },
        { key: 'Confirmed', value: 'confirmed' },
        { key: 'Packed', value: 'packed' },
        {
            key: 'Prescription Uploaded',
            value: 'Prescription-Uploaded',
        },
        // {
        //     key: 'Delivered',
        //     value: 'delivered',
        // },
    ],
};