import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    Table,
    Select,
    DatePicker,
    ConfigProvider,
    AutoComplete,
    Input,
} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
    ClearDeliveryDetailId,
    ClearDeliveryPersonId,
    SetDetailID,
} from '../../redux/slices/user-slice';
import { ROLES } from '../../constant/roles-constant';
import useAppSelector from '../../hooks/useAppSelector';
import moment from 'moment';
import ExportModal from '../common/export-modal';
// for search functionality
const getRandomInt = (max, min = 0) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            <a
                                href={`https://s.taobao.com/search?q=${query}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {category}
                            </a>
                        </span>
                        <span className="text-grey">
                            {getRandomInt(200, 100)}
                        </span>
                    </div>
                ),
            };
        });
// for search functionality END

// handlechange for select box
const handleChange = (value) => {
    console.log(`selected ${value}`);
};

// onChange function on the table
const onChange = (pagination, filters, sorter, extra) => { };

const SimpleTable = ({ select_row = false, ...props }) => {
    const [searchP] = useSearchParams();
    const navigate = useNavigate();
    const store = useAppSelector((state) => ({
        role: state.auth_store.role,
    }));

    const [orderStatusValue, setOrderStatusValue] = useState(
        searchP.get('filter') ?? 'Order Status'
    );
    const [categoryFilterValue, setCategoryFilterValue] = useState(
        searchP.get('category') ?? 'Category By Level'
    );
    const [featuredCategoryFilter, setFeaturedCategoryFilter] = useState(
        searchP.get('filter') ?? 'Category Filters'
    );
    const [productFilter, setProductFilter] = useState(
        searchP.get('filter') ?? 'Filter By Product'
    );
    const { RangePicker } = DatePicker;

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const [isExportModalVisible, setIsExportModalVisible] = useState(false);
    const [relatedComponent, setRelatedComponent] = useState('');

    const handleShowExportModal = (component) => {
        setIsExportModalVisible(true);
        setRelatedComponent(component);
    };

    const handleCloseExportModal = () => {
        setIsExportModalVisible(false);
        setRelatedComponent('');
    };

    // for search box
    const [options, setOptions] = useState([]);
    const handleSearch = (value) => {
        setOptions(value ? searchResult(value) : []);
    };
    const onSelect = (value) => {
        console.log('onSelect', value);
    };
    // selecting rows
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleOrderStatusFilter = (value) => {
        if (value === null || value === undefined) {
            setOrderStatusValue('Order Status');
            navigate('?');
        } else {
            setOrderStatusValue(value);
            navigate(`?filter=${value}`);
        }
    };

    const handleCategoryFilter = (value) => {
        if (value === null || value === undefined) {
            setCategoryFilterValue('Category By Level');
            navigate('?');
        } else {
            setCategoryFilterValue(value);
            navigate(`?category=${value}`);
        }
    };

    const handleProductFilter = (value) => {
        if (value === null || value === undefined) {
            setProductFilter('Filter By Product');
            navigate('?');
        } else {
            setProductFilter(value);
            navigate(`?filter=${value}`);
        }
    };

    const handleFeaturedCategoryFilter = (value) => {
        if (value === null || value === undefined) {
            setFeaturedCategoryFilter('Category Filters');
            navigate('?');
        } else {
            setFeaturedCategoryFilter(value);
            navigate(`?filter=${value}`);
        }
    };

    // onchange for datepicker
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };
    function goBack() {
        window.history.back();
    }
    const dispatch = useDispatch();

    // console.log("Order options --------------", props?.orderOptions)

    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <div className="flex justify-between p-4 border-b-1 items-center @sm:flex-wrap @sm:gap-y-4">
                <div className="flex gap-4 items-center">
                    {props.goBack ? (
                        <ArrowLeftOutlined onClick={goBack} />
                    ) : null}
                    <h1 className="table-name">{props.tableName}</h1>
                    {props.isPrescriptionRequired ? (
                        <>
                            <img
                                className="ml-5 w-10 h-10"
                                src="/images/prescription/Prescription_Required_logo.png"
                                alt="Upload File"
                            />{' '}
                            <p>Prescription Required</p>
                        </>
                    ) : null}
                </div>
                <div className="flex items-center @sm:flex-wrap @sm:gap-y-4 @sm:justify-center ml-4 gap-4">
                    {props?.logs ? (
                        <Link to={props?.logsLink}>
                            <button
                                onClick={props.openPopup}
                                className="py-2 mr-3 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                            >
                                Logs
                            </button>
                        </Link>
                    ) : null}

                    {props?.openPopup ? (
                        <button
                            onClick={props.openPopup}
                            className="py-2 mr-3 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                        >
                            +Bulk Upload
                        </button>
                    ) : null}

                    {props?.addNew ? (
                        <Link to={props.addNewLink}>
                            <button
                                onClick={() => {
                                    dispatch(ClearDeliveryPersonId());
                                }}
                                id=""
                                className="py-2 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                            >
                                +Add New
                            </button>
                        </Link>
                    ) : null}

                    {props.categoryFilter ? (
                        <Select
                            id=""
                            className="ms-3"
                            defaultValue="Category By Level"
                            style={{
                                width: 160,
                            }}
                            onChange={handleCategoryFilter}
                            value={categoryFilterValue}
                            allowClear
                            options={[
                                {
                                    value: 'first',
                                    label: 'Parent Category',
                                },
                                {
                                    value: 'second',
                                    label: 'Sub Category',
                                },
                                {
                                    value: 'third',
                                    label: 'Sub-sub Category',
                                },
                            ]}
                        />
                    ) : null}

                    {props.categoryFilterFeatured ? (
                        <Select
                            id=""
                            className="ms-3"
                            defaultValue="Category Filters"
                            style={{
                                width: 180,
                            }}
                            onChange={handleFeaturedCategoryFilter}
                            value={featuredCategoryFilter}
                            allowClear
                            options={[
                                {
                                    value: 'isFeaturedCategory',
                                    label: 'Featured Category',
                                },
                                {
                                    value: 'isBestSelling',
                                    label: 'Best Selling Category',
                                },
                            ]}
                        />
                    ) : null}

                    {props.productFilter ? (
                        <Select
                            id=""
                            className="ms-3"
                            defaultValue="Filter by Product"
                            style={{
                                width: 200,
                            }}
                            onChange={handleProductFilter}
                            value={productFilter}
                            allowClear
                            options={[
                                {
                                    value: 'isFeatured',
                                    label: 'Featured Product',
                                },
                                {
                                    value: 'isBestSeller',
                                    label: 'Best Selling Product',
                                },
                                {
                                    value: 'isIntroducingNew',
                                    label: 'Introducing New Product',
                                },
                                {
                                    value: 'isConciousWall',
                                    label: 'Concious Wall Product',
                                },
                            ]}
                        />
                    ) : null}

                    {props?.showAddNew ? (
                        <button
                            id=""
                            onClick={() => {
                                dispatch(SetDetailID(null));
                                dispatch(ClearDeliveryDetailId()); // Dispatch the action
                                props.showAddNew(); // Call props.showAddNew() as a function
                            }}
                            className="py-2 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                        >
                            +Add New
                        </button>
                    ) : null}
                    {props?.uploadPrescription &&
                        store.role !== ROLES.PHRAMA ? (
                        <button
                            id=""
                            onClick={props.showUploadModal}
                            className="py-1 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                        >
                            <img
                                src="/icons/common/upload.svg"
                                width="25"
                                className="me-2"
                                alt=""
                            />
                            UPLOAD PRESCRIPTION
                        </button>
                    ) : null}
                    {props?.search ? (
                        <AutoComplete
                            popupMatchSelectWidth={252}
                            style={{
                                height: 'auto',
                            }}
                            options={options}
                            onSelect={onSelect}
                            // onSearch={handleSearch}
                            className="text-black ms-3"
                            id=""
                        >
                            <Input.Search
                                id=""
                                className="w-full text-black"
                                placeholder={`Search ${props.placeholder}`}
                                onSearch={(value) =>
                                    props.onSearch('search', value)
                                }
                                enterButton
                            />
                        </AutoComplete>
                    ) : null}
                    {props?.selectDate ? (
                        <DatePicker
                            id=""
                            className="ms-3"
                            onChange={onChange}
                            format={'DD/MM/YYYY'}
                        />
                    ) : null}
                    {props.filterByCategory ? (
                        <Select
                            id=""
                            className="ms-3"
                            defaultValue="All Category"
                            style={{
                                width: 200,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'makeUpSkin',
                                    label: 'Make Up & Skin',
                                },
                                {
                                    value: 'healthPharmacy',
                                    label: 'Health & Pharmacy',
                                },
                                {
                                    value: 'wellnessPersonalCare',
                                    label: 'Wellness & Personal Care',
                                },
                                {
                                    value: 'fragrance',
                                    label: 'Fragrances',
                                },
                                {
                                    value: 'men',
                                    label: 'Men',
                                },
                                {
                                    value: 'vitaminsNutrition',
                                    label: 'Vitamins & Nutrition',
                                },
                            ]}
                        />
                    ) : null}
                    {props.actionSelect ? (
                        <Select
                            id=""
                            className="ms-3"
                            defaultValue="Action"
                            style={{
                                width: 120,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'edit',
                                    label: 'Edit',
                                },
                                {
                                    value: 'delete',
                                    label: 'Delete',
                                },
                            ]}
                        />
                    ) : null}
                    {props.orderStatus ? (
                        <Select
                            id=""
                            className="ms-3"
                            defaultValue="Order Status"
                            style={{
                                width: 200,
                            }}
                            onChange={handleOrderStatusFilter}
                            value={orderStatusValue}
                            allowClear
                        >
                            {props?.orderOptions?.map((option) => (
                                <Select.Option
                                    key={option.key}
                                    value={option.value}
                                >
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : null}

                    {props.transactionDate ? (
                        <RangePicker
                            disabledDate={disabledDate}
                            onChange={props.onChange}
                        />
                    ) : null}
                    {props.export ? (
                        <>
                            <Button onClick={() => handleShowExportModal(props.exportModal)} className="ms-3">Export </Button>
                            <ExportModal
                                visible={isExportModalVisible}
                                onClose={handleCloseExportModal}
                                relatedComponent={relatedComponent}
                                parentCategoryOptions={props.parentCategoryOptions}
                                attributeOption = {props.attributeOption}
                            />
                        </>
                    ) : null}
                </div>
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: '#0000001A',
                            cellFontSize: 13,
                            headerBg: '#fff',
                            headerColor: '#1A3353',
                            colorText: '#455560',
                        },
                    },
                }}
            >
                <Table
                    id=""
                    className="border-1 m-2 rounded-[6px] overflow-x-auto"
                    pagination={false}
                    borderColor="red"
                    // rowSelection={rowSelection}
                    columns={props.columns}
                    // dataSource={props.dataSource}
                    dataSource={props.dataSource?.map((row, index) => ({
                        ...row,
                        newKey: index,
                    }))}
                    onChange={onChange}
                    onRow={props.toggleDiv}
                    loading={props.loading}
                />
            </ConfigProvider>
        </div>
    );
};

export default SimpleTable;
