import React, { useState } from 'react';
import {
    EditOutlined,
    FileTextOutlined,
    PhoneOutlined,
    CheckCircleFilled,
} from '@ant-design/icons';
import { DatePicker } from 'antd';
import FollowUpModal from './followUp-modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    setFollowUpEndDate,
    setFollowUpStartDate,
} from '../../redux/slices/followup-slice';
import { createFollowUp } from '../../redux/actions/followup-action';

const Timeline = () => {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const role = useSelector((state) => state.auth_store.role);
    const { followupData } = useSelector((state) => state.followup_store);
    const customerData = useSelector(
        (state) => state.customer_store.singleCustomerDetails
    );
    const ordersDetail = useSelector((state) => state?.order?.orderDetail);
    const onChange = (date, dateString) => {
        dispatch(setFollowUpStartDate(dateString));
    };

    const onChangeEndDate = (date, dateString) => {
        dispatch(setFollowUpEndDate(dateString));
    };

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleCallFollowUp = () => {
        const currentDate = new Date();
        let reqData = {
            note: 'Called',
            followUpDate: moment(currentDate).format('lll'),
            isCalled: true,
            orderId: ordersDetail[0]?._id,
        };

        dispatch(createFollowUp(reqData));
    };

    return (
        <>
            <div className="flex items-center gap-5 px-2 py-5 mb-3 border-b ">
                <div className="flex items-center gap-2 w-[33.33%] ">
                    <p className="font-bold text-10">Full Name:</p>
                    <p className="text-10">
                        {customerData?.firstName} {customerData?.lastName}
                    </p>
                </div>
                <div className="flex items-center gap-2 w-[33.33%]">
                    <p className="font-bold text-10">Email:</p>
                    <p className="text-10">{customerData?.email}</p>
                </div>
                <div className="flex items-center gap-2 w-[33.33%]">
                    <p className="font-bold text-10">Mobile:</p>
                    {role === 'Doctor' && (
                        <PhoneOutlined
                            onClick={handleCallFollowUp}
                            className="text-[#FF8F2F] border rounded-full p-1 border-[#FF8F2F]"
                        />
                    )}

                    <p className="text-10">{customerData?.mobile}</p>
                </div>
            </div>
            <div className="flex justify-end gap-5 mb-3 pe-7">
                {' '}
                <div>
                    <button
                        onClick={handleOpenModal}
                        type="button"
                        className="flex items-center gap-2 px-3 py-2 text-sm bg-transparent border rounded-lg "
                    >
                        <EditOutlined />
                        <p className="text-[12px]">Add Follow Up </p>
                    </button>
                </div>
                <div>
                    <DatePicker onChange={onChange} placeholder="Start Date" />
                    <DatePicker
                        onChange={onChangeEndDate}
                        placeholder="End Date"
                        className="mx-3"
                    />
                </div>
            </div>

            <div className="flex flex-col gap-3 py-5">
                {followupData.map((item) => (
                    <div className="flex items-center pe-5">
                        <div className="lg:w-[15%] flex flex-col items-center ">
                            <p className="text-[#72849A] text-[12px]">
                                {moment(item.createdAt).format('lll')}
                            </p>
                            {/* <p className="text-[#72849A] text-[12px]">
                            
                            </p> */}
                        </div>

                        {item?.isCalled ? (
                            <div className="lg:w-[10%] flex justify-center">
                                <PhoneOutlined className="text-[#FF8F2F] border rounded-full p-2 border-[#FF8F2F]" />
                            </div>
                        ) : (
                            <div className="lg:w-[10%] flex justify-center">
                                <FileTextOutlined className="text-[#FF8F2F] border rounded-full p-2 border-[#FF8F2F]" />
                            </div>
                        )}

                        <div className="lg:w-[75%] bg-[#f7f7f6] py-5 flex flex-col gap-2 border border-[#A7A3A0] px-2  rounded-lg">
                            <div className="flex items-center">
                                <div className="w-[15%]">
                                    <p className="text-[#1A3353] text-[13px]">
                                        Follow up -{' '}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-[#1A3353] text-[13px]">
                                        with {item?.userId?.firstName}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-[15%]">
                                    <p className="text-[#1A3353] text-[13px]">
                                        Assigned to -{' '}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-[#1A3353] text-[13px]">
                                        {item?.doctorId?.name} | Due :{' '}
                                        {moment
                                            .utc(item?.followUpDate)
                                            .format('DD/MM/YYYY HH:mm A')}
                                        {/* {moment(item?.followUpDate).format(
                                            'DD/MM/YYYY HH:mm'
                                        )} */}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="w-[15%]">
                                    <p className="text-[#1A3353] text-[13px]">
                                        Note -{' '}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-[#1A3353] text-[13px]">
                                        {item?.note}
                                    </p>
                                </div>
                            </div>
                            {item?.isCalled && (
                                <div className="flex items-center">
                                    <div className="w-[15%]">
                                        <p className="text-[#1A3353] text-[13px]">
                                            Called
                                        </p>
                                    </div>
                                    <div className="">
                                        <CheckCircleFilled className="text-[#FF8F2F]" />
                                    </div>
                                </div>
                            )}

                            <div className="flex items-center">
                                <div className="w-[15%]">
                                    <p className="text-[#1A3353] text-[13px]">
                                        Status -{' '}
                                    </p>
                                </div>
                                <div className="">
                                    <button
                                        type="button"
                                        className="px-2 py-1 text-[13px] font-semibold text-[#FF8F2F] border border-[#FF8F2F] bg-white rounded-lg "
                                    >
                                        {item?.status}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div className="flex items-center pe-5">
                    <div className="lg:w-[15%] flex flex-col items-center ">
                        <p className="text-[#72849A] text-[12px]">
                            14 Jan 2023
                        </p>
                        <p className="text-[#72849A] text-[12px]">10:15 AM</p>
                    </div>
                    <div className="lg:w-[10%] flex justify-center">
                        <FileTextOutlined className="text-[#FF8F2F] border rounded-full p-2 border-[#FF8F2F]" />
                    </div>
                    <div className="lg:w-[75%] bg-[#f7f7f6] h-[200px] flex flex-col gap-2 border border-[#A7A3A0] px-2 py-2  rounded-lg">
                        <div className="flex items-center">
                            <div className="w-[15%]">
                                <p className="text-[#1A3353] text-[13px]">
                                    Follow up -{' '}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-[#1A3353] text-[13px]">
                                    with Uday Vihari
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-[15%]">
                                <p className="text-[#1A3353] text-[13px]">
                                    Assigned to -{' '}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-[#1A3353] text-[13px]">
                                    Ashwani Sakpal | Due : 18 Jan 11:22 AM
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-[15%]">
                                <p className="text-[#1A3353] text-[13px]">
                                    Status -{' '}
                                </p>
                            </div>
                            <div className="">
                                <button
                                    type="button"
                                    className="px-2 py-1 text-[13px] font-semibold text-[#FF8F2F] border border-[#FF8F2F] bg-white rounded-lg "
                                >
                                    Pending
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="flex items-center pe-5">
                    <div className="lg:w-[15%] flex flex-col items-center ">
                        <p className="text-[#72849A] text-[12px]">
                            14 Jan 2023
                        </p>
                        <p className="text-[#72849A] text-[12px]">10:15 AM</p>
                    </div>
                    <div className="lg:w-[10%] flex justify-center">
                        <PhoneOutlined className="text-[#FF8F2F] border rounded-full p-2 border-[#FF8F2F]" />
                    </div>
                    <div className="lg:w-[75%] bg-[#f7f7f6] h-[90px] flex flex-col gap-2 border border-[#A7A3A0] px-2 py-2  rounded-lg">
                        <div className="flex items-center">
                            <div className="w-[15%]">
                                <p className="text-[#1A3353] text-[13px]">
                                    Call -{' '}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-[#1A3353] text-[13px]">
                                    Lorem ipsum dolor sit amet consectetur. Odio
                                    morbi diam facilisi facilisis amet venenatis
                                    orci. Viverra elit morbi ornare porttitor
                                    blandit felis et.
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* --------------------modals---------------------------- */}
            <FollowUpModal
                visible={isModalVisible}
                onClose={handleCloseModal}
            />
        </>
    );
};

export default Timeline;
