import { Button, Modal, Pagination, Select } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SimpleTable from '../../../components/tables/simpletable';
import { useDispatch, useSelector } from 'react-redux';
import {
    ManipulateTransactionId,
    orderList,
    orderStatus,
    updateOrderAgent,
} from '../../../redux/actions/order-action';
import {
    setOrderListId,
    setOrderStatus,
    setSelectedStatus,
} from '../../../redux/slices/order-slice';
import SearchBar from '../../../components/topbar/searchbar';
import {
    DeliveryListData,
    deliveryRole,
} from '../../../redux/actions/user-action';
import Otp from '../../../components/otp/otp';
import OrdersMobile from './ordersMobile';
import { roleOptions } from '../../../components/roleOptions';
import { PhoneFilled, PhoneOutlined } from '@ant-design/icons';
import moment from 'moment';
import { createFollowUp } from '../../../redux/actions/followup-action';
import { setFollowupUserId } from '../../../redux/slices/followup-slice';

export function formatDate(dateString) {
    if (!dateString || isNaN(Date.parse(dateString))) {
        return '';
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

const orderStatusOptions = [
    // { key: '1', value: 'completed', label: 'Completed' },
    { key: '2', value: 'pending', label: 'Pending' },
    {
        key: '3',
        value: 'Prescription-Uploaded',
        label: 'Prescription Uploaded',
    },
    { key: '4', value: 'payment-pending', label: 'Payment Pending' },
    // {
    //     key: '5',
    //     value: 'needs-doctor-approval',
    //     label: 'Needs Doctor Approval',
    // },
    { key: '6', value: 'packed', label: 'Packed' },
    { key: '7', value: 'delivered', label: 'Delivered' },
    { key: '8', value: 'confirmed', label: 'Confirmed' },
    // { key: '9', value: 'failed', label: 'Failed' },
];

const OrdersList = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        handleResize(); // Initialize state
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const [searchP] = useSearchParams();
    const navigate = useNavigate();
    const orderStatusFilterurl = searchP.get('filter');
    const ordersDetail = useSelector((state) => state?.order?.orderDetail);
    const startDateUrl = searchP.get('startDate');
    const endDateUrl = searchP.get('endDate');
    const [statusOption, setStatusOption] = useState([]);
    const [statusMap, setStatusMap] = useState({});
    const [statusFlag, setStatusFlag] = useState(false);
    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const [deliveryPersonId, setDeliveryPersonId] = useState(null);
    const ordersList = useSelector((state) => state?.order?.orderList);
    const orderStatusFilter = useSelector((state) => state?.order?.orderStatus);
    const ordersCount = useSelector((state) => state?.order?.order_count);
    const role = useSelector((state) => state.auth_store.role);
    const userId = useSelector((state) => state.auth_store.userId);
    const deliveryData = useSelector(
        (state) => state.customer_store.delivery_partner
    );

    const handleChange = (value, orderId) => {
        // setStatusMap((prev) => ({ ...prev, [orderId]: value }));
        dispatch(orderStatus({ value, orderId })).then((res) => {
            if (res?.error?.message === 'Rejected') {
                setStatusFlag(true);
            } else {
                setStatusMap((prev) => ({ ...prev, [orderId]: value }));
            }
        });
        dispatch(setSelectedStatus({ value, orderId }));
    };

    const handleSelectChange = (value, orderId) => {
        dispatch(updateOrderAgent({ value, orderId }));
    };

    const options = roleOptions[role] || [];

    function paginate(pageNumber, pageSize) {
        setCurrentPage(pageNumber);
        setPageSize(pageSize);
        navigate(`?page=${pageNumber}&pageSize=${pageSize}`, { replace: true });
        if (orderStatusFilterurl) {
            navigate(
                `?filter=${orderStatusFilterurl}&page=${pageNumber}&pageSize=${pageSize}`,
                { replace: true }
            );
        }
        if (endDateUrl && startDateUrl) {
            navigate(
                `?startDate=${startDateUrl}&endDate=${endDateUrl}&page=${pageNumber}&pageSize=${pageSize}`,
                { replace: true }
            );
        }

        if (orderStatusFilterurl && endDateUrl && startDateUrl) {
            navigate(
                `?filter=${orderStatusFilterurl}&startDate=${startDateUrl}&endDate=${endDateUrl}&page=${pageNumber}&pageSize=${pageSize}`,
                { replace: true }
            );
        }
    }

    function searchOrder(type, value) {
        setSearchInput(value);
    }

    const handleFilterOrders = () => {
        if (role === 'Doctor') {
            return ordersList.list?.filter((order) => {
                return order?.populatedOrderedItems?.some(
                    (item) => item.prescriptionRequired === true
                );
            });
        }
        return ManipulateTransactionId(ordersList.list);
    };

    useEffect(() => {
        dispatch(deliveryRole()).then((res) => {
            const fetchedDeliveryRoleId = res?.payload?.data?.list;
            if (
                Array.isArray(fetchedDeliveryRoleId) &&
                fetchedDeliveryRoleId.length > 0
            ) {
                const deliveryPerson = fetchedDeliveryRoleId.find(
                    (role) => role.name === 'Delivery Person'
                );
                if (deliveryPerson) {
                    setDeliveryPersonId(deliveryPerson._id);
                    getDeliveryPartnersLists(1, 10, deliveryPerson._id);
                }
            }
        });
    }, [dispatch, deliveryPersonId]);

    const getDeliveryPartnersLists = async (page, pageSize, personId) => {
        if (personId) {
            await dispatch(
                DeliveryListData({
                    page: currentPage,
                    pageSize: pageSize,
                    deliveryPersonId: personId,
                })
            ).then((res) => {
                setLoading(false);
            });
        } else {
            console.log('orderId is not available');
        }
    };

    const handleCallFollowUp = (_id) => {
        dispatch(setFollowupUserId(_id));
        const currentDate = new Date();
        let reqData = {
            note: 'Called',
            followUpDate: moment(currentDate).format('lll'),
            isCalled: true,
            orderId: _id,
        };

        dispatch(createFollowUp(reqData));
    };

    // const filteredOrdersList = handleFilterOrders();
    const filteredOrdersList = handleFilterOrders()?.map((order) => ({
        ...order,
        options: statusOption,
        status: statusMap[order._id] || order.status,
    }));

    console.log('flteredOrdersList: ---------', filteredOrdersList);

    const orderColumns = [
        {
            title: 'ORDER ID',
            dataIndex: 'orderId',
            render: (orderId) => <span className="uppercase">#{orderId}</span>,
        },
        {
            title: 'CUSTOMER NAME',
            dataIndex: 'billingAddress',
            render: (billingAddress) => <span>{billingAddress?.fullName}</span>,
        },
        {
            title: 'PHONE NUMBER',
            dataIndex: 'billingAddress',
            render: (billingAddress) => (
                <span className="flex">{billingAddress?.mobile}</span>
            ),
        },

        // {
        //     title: 'CALL',
        //     dataIndex: '_id',
        //     render: (_id) => (
        //         <span className="flex">
        //             {role === 'Doctor' ? (
        //                 <PhoneFilled
        //                     onClick={() => handleCallFollowUp(_id)}
        //                     className="text-[#FF8F2F] px-1"
        //                 />
        //             ) : null}
        //         </span>
        //     ),
        // },
        {
            title: 'TOTAL AMOUNT',
            dataIndex: 'grandTotal',
            render: (grandTotal) => <span>{grandTotal?.toFixed(2)}</span>,
        },
        {
            title: 'PAYMENT METHOD',
            dataIndex: 'paymentMode',
        },
        {
            title: 'DELIVERY TYPE',
            dataIndex: 'deliveryType',
            render: (deliveryType) => <span>{deliveryType}</span>,
        },
        // {
        //     title: 'TRANSACTION STATUS',
        //     dataIndex: 'transactionId',
        //     render: (transactionId) => (
        //         <span>{transactionId[0]?.transactionStatus}</span>
        //     ),
        // },
        {
            title: 'ORDER DATE',
            dataIndex: 'createdAt',
            render: (createdAt) => <span>{formatDate(createdAt)}</span>,
        },
        {
            title: 'STATUS',
            dataIndex: '',
            render: (record) => {
                // console.log("record ------------------", record);
                return (
                    <Select
                        key={record._id + record.status}
                        disabled={record?.status === 'delivered'}
                        defaultValue={record?.status}
                        value={record?.status}
                        onChange={(value) => handleChange(value, record?._id)}
                        style={{ width: '200px' }}
                        options={record?.options?.map((option) => ({
                            label: option.key,
                            value: option.value,
                        }))}
                    />
                );
            },
        },

        {
            title: '',
            dataIndex: 'action',
        },
    ];

    const action_column = [
        {
            title: 'EDIT',
            dataIndex: '_id',
            render: (_id, record) => {
                return (
                    <span className="flex w-[50px]">
                        <Link to={`/orders/order-details/${_id}`}>
                            <img
                                src="/icons/common/edit.svg"
                                alt="edit"
                                className="h-[20px] cursor-pointer"
                                onClick={() => {
                                    dispatch(setOrderListId(_id));
                                }}
                            />
                        </Link>
                    </span>
                );
            },
        },
    ];

    const generateOtp = {
        title: 'OTP',
        dataIndex: 'otp',
        render: (_id, record) => {
            return (
                <>
                    {record?.deliveryStatus === 'delivered' ? (
                        <Button
                            className="mx-auto py-2 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#47cb96] bg-[#33a376] text-white md:text-[.85vw]"
                            disabled={true}
                            onClick={() => {
                                dispatch(setOrderListId(record?._id));
                                otpShow();
                            }}
                        >
                            Delivered
                        </Button>
                    ) : (
                        <Button
                            className="mx-auto py-2 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#6E3362] bg-[#6E3362] text-white md:text-[.85vw]"
                            onClick={() => {
                                dispatch(setOrderListId(record?._id));
                                otpShow();
                            }}
                        >
                            Delivered
                        </Button>
                    )}
                </>
            );
        },
    };

    const seenToDelivery = [
        {
            title: 'DELIVERY PARTNER',
            dataIndex: '',
            align: 'center',
            render: (record, _id) => {
                if (record.deliveryType !== 'store-pickup') {
                    return (
                        <Select
                            defaultValue={
                                record?.deliveryAgent[0]?.name
                                    ? record?.deliveryAgent[0]?.name
                                    : 'Select any agent'
                            }
                            // disabled={record.status !== 'packed'}
                            style={{ width: '200px' }}
                            onChange={(value) =>
                                handleSelectChange(value, _id._id)
                            }
                            options={deliveryData.map((item) => ({
                                value: item.key,
                                label: item.name,
                            }))}
                        />
                    );
                } else {
                    return 'NA';
                }
            },
        },
        {
            title: 'OTP',
            dataIndex: 'deliveryOTP',
            align: 'center',
            render: (deliveryOTP, record) => {
                if (record.deliveryType !== 'store-pickup') {
                    return deliveryOTP;
                } else {
                    return 'NA';
                }
            },
        },
    ];

    const all_columns = [
        ...orderColumns,
        ...(role === 'Doctor' ? [] : []),
        ...(role === 'Delivery Person' ? [generateOtp] : []),
        ...(role === 'Super Admin' ? seenToDelivery : []),
        ...action_column,
    ];
    const [openModal, setOpenModal] = useState(false);
    const otpShow = () => {
        setOpenModal(!openModal);
    };

    const handleDateChange = (date, dateString) => {
        if (!date) {
            if (orderStatusFilterurl) {
                navigate(`?filter=${orderStatusFilterurl}`, { replace: true });
                return;
            } else {
                navigate(``, { replace: true });
                return;
            }
        }
        navigate(`?startDate=${dateString[0]}&endDate=${dateString[1]}`, {
            replace: true,
        });
        if (orderStatusFilterurl) {
            navigate(
                `?filter=${orderStatusFilterurl}&startDate=${dateString[0]}&endDate=${dateString[1]}`,
                { replace: true }
            );
        }
    };

    useEffect(() => {
        const params = {
            page: Number(currentPage),
            pageSize: Number(pageSize),
            role: role,
            userId: userId,
        };

        const paramsWithSearch = {
            page: Number(1),
            pageSize: Number(10),
            search: searchInput,
            role: role,
            userId: userId,
        };

        if (startDateUrl) {
            params.startDate = startDateUrl;
        }

        if (endDateUrl) {
            params.endDate = endDateUrl;
        }

        if (orderStatusFilterurl) {
            params.orderStatus = orderStatusFilterurl;
        }

        if (searchInput) {
            dispatch(orderList(paramsWithSearch)).then((res) => {
                setLoading(false);
                console.log('ress', res);
            });
        } else {
            dispatch(orderList(params)).then((res) => {
                setLoading(false);
                console.log('ress', res);
            });
        }
        setStatusOption(roleOptions[role] || []);
    }, [
        dispatch,
        currentPage,
        pageSize,
        searchInput,
        startDateUrl,
        endDateUrl,
        orderStatusFilterurl,
    ]);

    return (
        <>
            <SearchBar onSearch={searchOrder} placeholder={'Search'} />
            {!isMobile ? (
                <>
                    <SimpleTable
                        tableName="Orders"
                        actionSelect={false}
                        // transactionStatus={false}
                        orderStatus={true}
                        orderOptions={orderStatusOptions}
                        transactionDate={true}
                        export={false}
                        columns={all_columns}
                        dataSource={filteredOrdersList}
                        className="orders"
                        loading={loading}
                        onChange={handleDateChange}
                    />
                    <div className="flex justify-center py-10">
                        {' '}
                        <Pagination
                            pageSize={pageSize}
                            current={currentPage}
                            total={ordersCount}
                            onChange={paginate}
                            hideOnSinglePage
                            pageSizeOptions={[10, 20, 50]}
                        />{' '}
                    </div>
                </>
            ) : (
                <OrdersMobile dataSource={filteredOrdersList} />
            )}
            <Modal centered open={openModal} onCancel={otpShow} footer={false}>
                <Otp
                    className="bg-[black]"
                    pageNumber={currentPage}
                    pageSize={pageSize}
                    searchInput={searchInput}
                />
            </Modal>
        </>
    );
};

export default OrdersList;
