import './App.css';
import Sidebar from './components/Sidebar/sidebar';

import PagesRoutes from './routes';

// import Login from './screens/auth/login';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Topbar from './components/topbar/topbar';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SignInScreen from './screens/auth/login';
import { useEffect } from 'react';
import ForgotPasswordScreen from './screens/auth/forgot-password';
import ResetPasswordScreen from './screens/auth/reset-password';

function App() {
    const is_login = useSelector((state) => state.auth_store.is_login);
    const permissions = useSelector((state) => state.auth_store.permissions);

    return (
        <div className="flex w-full">
            {is_login ? (
                <>
                    <div>
                        <Sidebar data={permissions} />
                    </div>
                    <div className="ml-auto mr-0 h-full min-h-screen rounded-[6px] bg-[#F7F8FB] p-6 @sm:w-full @sm:p-0 @lg:w-[79vw] @xl:w-[82.5vw] @2xl:w-[84vw]">
                        <Topbar />
                        <PagesRoutes />
                        <Toaster className="mh-100vh" />
                    </div>
                </>
            ) : (
                <>
                    <Login />
                    <Toaster className="mh-100vh" />
                </>
            )}
        </div>
    );
}

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const is_login = useSelector((state) => state.auth_store.is_login);

    // console.log('location.pathname', location.pathname);

    useEffect(() => {
        if (!is_login && location.pathname === '/forgot-password') {
            navigate('/forgot-password');
            return;
        }
        if (!is_login && location.pathname === '/reset-password') {
            // navigate('/reset-password');
            return;
        }
        if (!is_login && location.pathname !== 'login') {
            navigate('/login');
        }
    }, [is_login]);

    return (
        <>
            <Routes>
                <Route path="/login" element={<SignInScreen />} />
                <Route
                    path="/forgot-password"
                    element={<ForgotPasswordScreen />}
                />
                <Route
                    path="/reset-password"
                    element={<ResetPasswordScreen />}
                />
            </Routes>
        </>
    );
};

export default App;
