const ImageUploadSection = ({
    setImagesFiles,
    images_files = [],
    title = 'Upload Images',
    type = 'multiple',
    name,
}) => {
    const maxSize = 2.5 * 1024 * 1024; // 2.5 MB in bytes

    const handleImageChange = (e) => {
        const files = e.target.files;
        // toggleImagePreviewModal(true);
        // Validate each selected file
        const validImages = Array.from(files).filter((image) => {
            // Validate image type
            const isImage = image.type.startsWith('image/');
            if (!isImage) {
                console.warn(`File ${image.name} is not an image.`);
                return false;
            }

            // Validate image size
            const isSizeValid = image.size <= maxSize;
            if (!isSizeValid) {
                console.warn(
                    `File ${image.name} exceeds the maximum allowed size.`
                );
                return false;
            }

            return true;
        });
        // Update state with valid images
        if (type === 'multiple') {
            setImagesFiles([...images_files, ...validImages]);
        } else if (type === 'single') {
            setImagesFiles({ file: validImages[0] });
        }
    };

    function deleteImage(index) {
        const img_arr = [...images_files];
        img_arr.splice(index, 1);
        setImagesFiles(img_arr);
    }

    return (
        <>
            {/* Image section */}
            <div className="p-8 w-2/5 border-1 rounded-lg h-max">
                <label className="w-full text-[#1A3353] md:text-[1vw] font-semibold pb-4">
                    {title}
                </label>
                <div className="uploadBox" onSubmit={(e) => e.preventDefault()}>
                    <div className="mt-4 FirstTab border-dashed border-1 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                        <div>
                            <img
                                className="m-auto"
                                src="/icons/common/upload-file.svg"
                                alt="Upload File"
                            />
                            <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                Drag & drop files or{' '}
                                <span className="">
                                    <label
                                        htmlFor={name}
                                        className="cursor-pointer text-[#FF8F2F] underline font-semibold my-6 text-[22px]"
                                    >
                                        Browse
                                        <input
                                            type="file"
                                            data-max-size="2048"
                                            id={name}
                                            className="fileIcon invisible"
                                            onChange={handleImageChange}
                                            multiple={
                                                type === 'multiple'
                                                    ? true
                                                    : false
                                            }
                                            accept="image/*"
                                        />
                                    </label>
                                </span>
                            </div>
                        </div>

                        <p className="info">Supported files: JPEG, PNG, WebP</p>
                    </div>
                </div>

                <div className="flex gap-4 mb-4 flex-wrap">
                    {images_files.map((image, index) => (
                        <div key={index} className="w-[30%] z-1 relative">
                            {typeof image === 'string' ? (
                                <img
                                    src={image}
                                    alt={`Selected ${index + 1}`}
                                />
                            ) : (
                                <>
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Selected ${index + 1}`}
                                    />
                                    <div
                                        className="cursor-pointer absolute top-0 z-10 left-0"
                                        onClick={deleteImage}
                                    >
                                        Delete
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ImageUploadSection;
