import { createSlice } from '@reduxjs/toolkit';
import { AttributeListData } from '../actions/attribute-action';
import { _ManipulateMappingAttribute } from '../actions/container-actions';
import { GetCrazyDeals } from '../actions/crazy-deals-actions';
import { CreateBanner, GetBannerList, ManipualateBannerData } from '../actions/banner-action';
import { GetReviewDetails, GetReviewList, ManipualateReviewData } from '../actions/review-action';

const initialState = {
    reviewList: [],
    reviewDetailsList: [],
    reviewList_count: null,
};

const reviewSlice = createSlice({
    name: 'review_store',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetReviewList.fulfilled, (state, { payload }) => {
            // console.log("Payload---------------", payload)
            state.reviewList_count = payload?.data?.count;
            state.reviewList = ManipualateReviewData(payload?.data?.list);
        });
        builder.addCase(GetReviewDetails.fulfilled, (state, { payload }) => {
            // console.log("Payload---------------", payload)
            // state.bannerList_count = payload?.data?.count;
            state.reviewDetailsList =(payload?.data);
        });
    },
});

export const { Test } = reviewSlice.actions;
export default reviewSlice.reducer;
