import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    STORE_DETAIL,
    STORE_DETAIL_UPDATE,
    STORE_LIST,
} from '../../constant/api-constant';
import { getApi, patchApi, postApi } from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';

export const StoreList = createAsyncThunk(
    'store/storeList',
    async ({ page = 1, pageSize = 10, status }) => {
        try {
            const response = await postApi(STORE_LIST, {
                page,
                pageSize,
                status,
            });
            console.log('response ', response);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const StoreDetail = createAsyncThunk('store/storeDetail', async (id) => {
    try {
        const response = await getApi(`${STORE_DETAIL}${id.id}`);
        console.log('response of store detail ', response);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        Alertify.error(error.message[0]);
        return Promise.reject();
    }
});

//Patch
export const UpdateStore = createAsyncThunk(
    'store/storeUpdate',
    async ({ id, form }) => {
        try {
            console.log('value of form-----', form);
            const response = await patchApi(
                `${STORE_DETAIL_UPDATE}${id}`,
                form
            );

            console.log('response of update Api', response);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);
