import { createSlice } from "@reduxjs/toolkit";
import { getPrescriptionByOrder, getPrescriptionByUser, uploadPrescription } from "../actions/prescription-action";

const initialState = {
    uploadPrescription: [],
    getPrescription:[],
    getUserPrescription: [],
    prescriptionCount: 0,
};
const prescriptionSlice = createSlice({
    name: 'prescription-slice',
    initialState,
   
    extraReducers: (builder) => {
        builder.addCase(uploadPrescription.fulfilled, (state, { payload }) => {
            state.uploadPrescription = payload?.data;
        });
        builder.addCase(getPrescriptionByOrder.fulfilled, (state, { payload }) => {
            state.getPrescription = payload.data
            state.prescriptionCount =state.getPrescription?.prescription.length
        });
        builder.addCase(getPrescriptionByUser.fulfilled, (state, { payload }) => {
            state.getUserPrescription = payload.data
        });
        
    },
});
export default prescriptionSlice.reducer;