export const LOGIN_API = '/admin/auth/login';
export const RESET_PASSWORD_LINK = '/admin/ResetPasswordLink';

export const RESET_PASSWORD_AFTER_LINK = '/admin/createresetPassword';

export const CUSTOMER_LIST = '/admin/user';

export const CUSTOMER_DETAILS = '/admin/user';

export const CUSTOMER_ADDRESSES = '/admin/user/address/';

export const DOCTOR_LIST = '/doctor';

export const CREATE_DOCTOR = '/admin/doctor/create';

export const GET_ROLE = '/admin/role';
export const UPDATE_ORDER_STATUS = '/admin/order';
export const CREATE_DELIVERY_PERSON = '/admin/create';
export const GET_DELIVERY_PERSON_DETAIL = '/admin';
export const GET_DOCTOR_DETAIL = '/admin';
export const UPDATE_DELIVERY_PERSON = '/admin';

export const GET_DELIVERY_PERSON = '/admin';

export const CATEGORY_LIST = '/admin/category';

export const ADD_NEW_CATEGORY = '/admin/category/create';

export const GET_CATEGORY = '/admin/category';

export const UPDATE_CATEGORY = '/admin/category';

export const DELETE_CATEGORY = 'admin/category';

export const ATTRIBUTES_LIST = '/admin/attribute';

export const ATTRIBUTES_VALUE_LIST = '/admin/attribute';

export const SUB_ATTRIBUTES_LIST = '/admin/attribute';

export const ADD_NEW_SUB_ATTRIBUTES = '/admin/attribute/create';

export const IS_TOP_BRAND = '/admin/attribute/createTopBrand';

export const UPDATE_SUB_ATTRIBUTES = '/admin/attribute';

export const DELETE_SUB_ATTRIBUTES = '/admin/attribute';

export const PRODUCT_LIST = '/admin/product';

export const CREATE_FILTER = '/admin/filter/create';

export const GET_FILTER_LIST = '/admin/filter';

export const UPDATE_FILTER = '/admin/filter';

export const DELETE_FILTER = '/admin/filter';

export const GET_PRODUCT_DETAILS = '/admin/product';

export const DELETE_PRODUCT = '/admin/product';
export const UPDATE_PRODUCT = '/admin/product';
export const TOGGLE_PRODUCT_STATUS = '/admin/product/update-status';
export const CREATE_PRODUCT = '/admin/product/create';

export const UPLOAD_IMAGE = '/admin/product/image';
export const BULK_UPLOAD_PRODUCT = '/admin/product/bulkUpload';
export const BULK_UPDATE_UPLOAD_PRODUCT = '/admin/product/bulkUpload/update';
export const BULK_ERROR_LOGS = '/admin/product/bulk-upload-errors';
export const BULK_UPLOAD_IMAGES = '/admin/product/bulkUpload/images';
export const STORE_LIST = '/admin/store';
export const STORE_DETAIL = `/admin/store/`;
export const STORE_DETAIL_UPDATE = `/admin/store/`;
export const INVENTORY_LIST = '/admin/inventory';
export const SEARCH_BY_SKU = '/admin/inventory/sku_search';
export const SEARCH_BY_SKU_DETAILS = '/admin/inventory/product/sku_search';

export const ORDERS_LIST = '/admin/order';

export const ORDERS_STATUS = '/admin/order/updateStatus';

export const UPDATE_ORDER_AGENT = '/admin/order';

export const ORDERS_DETAIL = '/admin/order';

export const RETURN_REASON = '/order/return-reason';

export const CREATE_NEW_INVENTORY = '/admin/inventory/create';

export const UPDATE_INVENTORY = '/admin/inventory';

export const GET_CATEGORY_DETAILS_BY_ID = '/admin/category';

export const GET_CRAZY_DEALS = '/admin/crazyDeals';

export const GET_PRESCRIPTION_USERS = '/prescription/list';
export const GET_PRESCRIPTION_ORDERS = '/order/prescription/';

export const UPLOAD_PRESCRIPTION = '/admin/order/prescriptionUpload';

export const DELETE_CRAZY_DEALS = '/admin/crazyDeals';
export const UPDATE_CRAZY_DEALS = 'admin/crazyDeals/update';
export const GET_BRANDS_DATA = '/admin/brand';
export const CREATE_CRAZY_DEALS = '/admin/crazyDeals/create';
export const GET_DETAILS_CRAZY_DEALS = 'admin/crazyDeals';
export const GET_THIRD_LEVEL_CATEGORY = '/admin/category';
export const DELETE_BANNERS = '/admin/banner';
export const GET_BANNER_DETAILS = '/admin/banner';

export const UPDATE_BANNER = '/admin/banner/update';

export const DELETE_IMAGE = '/admin/product/remove/image';

export const CREATE_FOLLOWUP = '/admin/followUp/create';

export const FOLLOWUP_JOURNEY_LIST = '/admin/journey/'; //  user_id in the end
export const FOLLOWUP_LIST = 'admin/followUp/'; // user_id in the end

export const PRODUCT_EXPORT = '/admin/product/export';

export const ATTRIBUTE_EXPORT = '/admin/attribute/export';

export const CATEGORY_EXPORT = '/admin/category/export';
export const UPDATE_CALL_STATUS = '/admin/followUp/update-call-status';
