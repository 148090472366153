import { createSlice } from '@reduxjs/toolkit';
import {
    CustomersAddress,
    CustomersDetails,
    CustomersListData,
    DeliveryListData,
    DoctorListData,
    GetPharmaUsersList,
    ManipulateCustomerLists,
    ManipulateDeliveryLists,
    ManipulateDoctorLists,
    getDeliveryPartnerDetail,
    manipulateCustomerDetails,
} from '../actions/user-action';

const initialState = {
    is_login: false,
    customers: [],
    customer_addresses: [],
    doctors: [],
    delivery_partner: [],
    delivery_partner_detail: [],
    customer_Details: [],
    singleCustomerDetails: [],
    permissions: [],
    search: '',
    deliveryRoleId: '',
    deliveryId: '',
    customer_count: 0,
    doctor_count: 0,
    delivery_count: 0,
    detailID: '',
    //! pharma
    pharmaLists: [],
    pharmaCounts: 0,
};

const customerSlice = createSlice({
    name: 'user-slice',
    initialState,
    reducers: {
        SetCustomerSearch: (state, { payload }) => {
            state['search'] = payload.value;
        },
        SetDeliveryRoleId: (state, { payload }) => {
            state.deliveryRoleId = payload.value;
        },
        SetClickedDeliveryPersonId: (state, { payload }) => {
            state.deliveryId = payload;
        },
        ClearDeliveryPersonId: (state, { payload }) => {
            state.deliveryId = '';
        },
        ClearDeliveryDetailId: (state) => {
            state.delivery_partner_detail = {};
        },
        SetDetailID: (state, action) => {
            state.detailID = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(CustomersAddress.fulfilled, (state, { payload }) => {
            console.log('payload address----->', payload);
            state.customer_addresses = payload.data;
        });
        builder.addCase(CustomersListData.fulfilled, (state, { payload }) => {
            state.customer_count = payload.data.data.count;
            state.customers = ManipulateCustomerLists(payload.data?.data?.list);
        });
        builder.addCase(CustomersDetails.fulfilled, (state, { payload }) => {
            state.customer_Details = manipulateCustomerDetails(payload.data);
            const data = payload.data;
            console.log('data =---->', data);
            delete data['0'];
            delete data['1'];
            state.singleCustomerDetails = data;
        });
        builder.addCase(DoctorListData.fulfilled, (state, { payload }) => {
            state.doctor_count = payload.data.data.count;
            state.doctors = ManipulateDoctorLists(payload.data?.data?.list);
        });
        builder.addCase(DeliveryListData.fulfilled, (state, { payload }) => {
            state.delivery_count = payload.data.count;
            state.delivery_partner = ManipulateDeliveryLists(
                payload.data?.list
            );
        });
        builder.addCase(
            getDeliveryPartnerDetail.fulfilled,
            (state, { payload }) => {
                state.delivery_partner_detail = payload;
            }
        );
        //! CRUD OF PHARMA
        builder.addCase(GetPharmaUsersList.fulfilled, (state, { payload }) => {
            state.pharmaLists = payload.res.data.data.list;
            state.pharmaCounts = payload.res.data.data.count;
        });
    },
});

export const {
    SetDetailID,
    SetCustomerSearch,
    SetDeliveryRoleId,
    SetClickedDeliveryPersonId,
    ClearDeliveryPersonId,
    ClearDeliveryDetailId,
} = customerSlice.actions;

export default customerSlice.reducer;
