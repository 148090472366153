import { createSlice } from '@reduxjs/toolkit';

import {
    GetCrazyDeals,
    GetCrazyDealsDetails,
} from '../actions/crazy-deals-actions';

const initialState = {
    deals_list: [],
    deals_list_count: null,
    bannerName: '',
    bannerImage: '',
    minDiscount: '',
    maxDiscount: '',
    types: '',
};

const crazyDealsSlice = createSlice({
    name: 'crazydeals_store',
    initialState,
    reducers: {
        ResetCrazyDealsValues: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(GetCrazyDeals.pending, (state, { payload }) => {
            state.deals_list = []
        });
        builder.addCase(GetCrazyDeals.fulfilled, (state, { payload }) => {
            state.deals_list_count = payload?.res?.data?.data?.count;
            state.deals_list = payload?.res?.data?.data?.list;
        });
        builder.addCase(
            GetCrazyDealsDetails.fulfilled,
            (state, { payload }) => {
                state.bannerName = payload?.res?.data?.bannerName;
                state.bannerImage = payload?.res?.data?.bannerImage;
                state.minDiscount = payload?.res?.data?.minDiscount;
                state.maxDiscount = payload?.res?.data?.maxDiscount;
                state.types = payload?.res?.data?.types;
                if (payload?.res?.data?.types === 'category') {
                    state.categoryId = {
                        value: payload?.res?.data?.categoryId?._id,
                        label: payload?.res?.data?.categoryId?.name,
                    };
                }
            }
        );
    },
});

export const { ResetCrazyDealsValues } = crazyDealsSlice.actions;
export default crazyDealsSlice.reducer;
