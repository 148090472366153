import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';
import {
    CREATE_CRAZY_DEALS,
    DELETE_BANNERS,
    DELETE_CRAZY_DEALS,
    GET_BANNER_DETAILS,
    GET_CRAZY_DEALS,
    UPDATE_BANNER,
    UPDATE_CRAZY_DEALS,
} from '../../constant/api-constant';
import { BANNER_LIST, CREATE_BANNER } from '../../contant/api-constant';

export const GetBannerList = createAsyncThunk(
    'getBanner-list',
    async ({ page = 1, pageSize = 10 }) => {
        try {
            const res = await postApi(BANNER_LIST, {
                page,
                pageSize,
            });
            // _ManipulateCrazyDeals;
            // console.log("Response-------------------", res)
            return res.data;
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/* ----------------- Create Banner----------------*/

export const CreateBanner = createAsyncThunk(
    'Create-banner',
    async ({ payload }) => {
        try {
            const res = await postApi(CREATE_BANNER, {
                ...payload,
            });
            Alertify.success('Banner created successfully');
            // dispatch(GetBannerList({page:1, pageSize:10}));
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export function ManipualateBannerData(data) {
    return data?.map((item) => {
        return {
            key: item._id,
            desktopBanner: item.desktopBannerImg,
            mobileBanner: item.mobileBannerImg,
            bannerName: item.bannerName,
            type: item?.types,
            action: item._id,
            id: item._id,
            status:item.status
        };
    });
}

export const DeleteBanner = createAsyncThunk(
    'DeleteBanner',
    async ({ id, page,pageSize }, { dispatch }) => {
        try {
            const res = await deleteApi(`${DELETE_BANNERS}/${id}`);
            Alertify.success('successfully deleted banner');
            dispatch(GetBannerList({page:page, pageSize:pageSize}));
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const GetBannerDetails = createAsyncThunk(
    'GetBannerDetails',
    async ({ id }) => {
        try {
            const res = await getApi(`${GET_BANNER_DETAILS}/${id}`);
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);


export const EditBanner = createAsyncThunk(
    'EditBanner',
    async ({ id, payload, page,pageSize }, { dispatch }) => {
        try {
            const res = await patchApi(`${UPDATE_BANNER}/${id}`, {
                ...payload,
            });
            Alertify.success('Updated successfully');
            dispatch(GetBannerList({page:page, pageSize:pageSize}));
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);
