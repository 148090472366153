import {
    ArrowLeftOutlined,
    DeleteColumnOutlined,
    DeleteFilled,
} from '@ant-design/icons';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CategoryListData } from '../../redux/actions/category-action';
import { SubAttributeList } from '../../redux/actions/attribute-action';
import {
    CreateNewProduct,
    GetProductDetails,
    UpdateProduct,
    UploadImages,
} from '../../redux/actions/product-action';
import useAppSelector from '../../hooks/useAppSelector';
import GetAttributesOptions from './get-attribute-options';
import { ClearProductDetailsData } from '../../redux/slices/product-slice';
import ImageUploadSection from './image-upload-sections';
import { useLoader } from '../../hooks/useLoader';
import { FindDifferentString, uniqueId } from '../../utils/utils';
import TextArea from 'antd/es/input/TextArea';
import {
    DeleteImage,
    GetBrandsData,
} from '../../redux/actions/container-actions';

import ImageUpload from '../../components/common/image-upload';
import VariantCardWrapper from './variant-card-wrapper';
import ImageRender from '../../components/common/image-render';

const AddNewProduct = () => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const variant_state_ref = useRef();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [level, setLevel] = useState('first');

    const [firstCategoryId, setFirstCategoryId] = useState(null);
    const [secondCategoryId, setSecondCategoryId] = useState(null);
    const [thirdCategoryId, setThirdCategoryId] = useState(null);
    const [show_form_fields, setShowFormFields] = useState(false);
    const [QnA, setQnA] = useState([
        // {
        //     question: '',
        //     answer: '',
        //     id: uniqueId(),
        // },
    ]);
    const [featureBanner, setFeatureBanner] = useState(''); //string
    const [introducingNewBanner, setIntroducingNewBanner] = useState('');
    const [attributes, setAdditionalAttributes] = useState({});
    const [uploaded_images, setUploadedImages] = useState([]);
    const [btn_loader, startLoader, endLoader] = useLoader(false);

    const [selectedFeature, setSelectedFeature] = useState([]);
    const [showVariantFields, setShowVariantFields] = useState(false);
    const [image_variant_modal, setImageVariantModal] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [isIntroducingNew, setIsIntroducingNew] = useState(false);
    const [is_IntroducingNewModal, setToggleIntroducingNewModal] =
        useState(false);
    const [isFeaturedProducts_modal, setFeaturedProductsModal] =
        useState(false);
    const [image_variant_local, setImageVariantLocal] = useState({
        file: [],
        index: 0,
    });

    const [introducing_new_local, setIntroducingNewLocal] = useState(false);
    const [featured_products_local, setFeaturedProductsLocal] = useState(false);

    const [product_images_url, setProductImagesUrl] = useState([]);

    const store = useAppSelector((state) => ({
        product_details: state.product_store.product_details,
        attributes_mapping: state.container_store.attributes_mapping,
        primaryCategory: state.category_store.primaryCategory,
        secondCategory: state.category_store.secondCategory,
        thirdCategory: state.category_store.thirdCategory,
        brands_list: state.container_store.brands_data,
    }));

    const memoized_brand = useMemo(() => {
        return store.brands_list?.map((item) => ({
            value: item._id,
            label: item.value,
            id: item._id,
        }));
    }, [store.brands_list]);

    function goBack() {
        window.history.back();
    }

    function handleProductsFields(key, key2, value, index) {
        const data = [...QnA];
        if (key === 'QnA') {
            data[index][key2] = value;
            setQnA(data);
        }
    }

    const productType = (value) => {
        if (value === 'variable') {
            setShowVariantFields(true);
        } else {
            setShowVariantFields(false);
        }
    };

    const getSubCategory = (value) => {
        setLevel('second');
        setFirstCategoryId(value);
        setSecondCategoryId(null);
        setThirdCategoryId(null);
        const parentId = value;
        dispatch(CategoryListData({ level: 'second', parentId })).then(() =>
            setLoading(false)
        );
    };

    const getSubSubCategory = (value) => {
        setLevel('third');
        setSecondCategoryId(value);
        setThirdCategoryId(null);
        const parentId = value;
        dispatch(CategoryListData({ level: 'third', parentId })).then(() =>
            setLoading(false)
        );
    };

    function uploadIntroducingNewImage() {
        dispatch(UploadImages({ images: [introducing_new_local.file] }))
            .unwrap()
            .then(({ res }) => {
                if (!!res.data.data.urls) {
                    setIntroducingNewBanner(res.data.data.urls[0]);
                }
                toggleIntroducingNewImageModal(false);
            })
            .catch(() => {});
    }

    function uploadFeaturedProductImage() {
        dispatch(UploadImages({ images: [featured_products_local.file] }))
            .unwrap()
            .then(({ res }) => {
                if (!!res.data.data.urls) {
                    setFeatureBanner(res.data.data.urls[0]);
                }
                toggleFeaturedProductsModal(false);
            })
            .catch(() => {});
    }


    async function handleSubmit(form) {
        startLoader();
        if (variant_state_ref.current) {
            var { variants_state, selectedItems } = variant_state_ref.current();
        }

        console.log({ variants_state, selectedItems });
        if (id === '0') {
            dispatch(
                CreateNewProduct({
                    payload: {
                        ...form,
                        attributes,
                        brand: selectedBrand,
                        images: product_images_url,
                        variants: variants_state,
                        variantAttributesList: selectedItems,
                        firstCategoryId,
                        secondCategoryId,
                        thirdCategoryId,
                        featureBanner,
                        introducingNewBanner,
                        isIntroducingNew,
                        isFeatured,
                        QnA,
                    },
                })
            )
                .unwrap()
                .then(() => window.history.goBack())
                .catch(() => {})
                .finally(endLoader);
        } else if (id !== '0') {
            dispatch(
                UpdateProduct({
                    payload: {
                        ...form,
                        attributes,
                        brand: selectedBrand,
                        images: product_images_url,
                        variants: variants_state,
                        variantAttributesList: selectedItems,
                        QnA,
                        featureBanner,
                        introducingNewBanner,
                        isIntroducingNew,
                        isFeatured,
                    },
                    id,
                })
            )
                .unwrap()
                .then(() => window.history.goBack())
                .catch(() => {})
                .finally(endLoader);
        }
    }

    function setIntroducingNewImage(image) {
        setToggleIntroducingNewModal(true);
        setIntroducingNewLocal(image);
    }
    function setFeaturedProductImage(image) {
        setFeaturedProductsModal(true);
        setFeaturedProductsLocal(image);
    }

    function toggleIntroducingNewImageModal() {
        setToggleIntroducingNewModal(!is_IntroducingNewModal);
    }
    function toggleFeaturedProductsModal() {
        setFeaturedProductsModal(!isFeaturedProducts_modal);
    }

    function handleAddFeatureSelect(selected) {
        if (selected.length < selectedFeature.length) {
            const different = FindDifferentString(selectedFeature, selected);
            console.log({ different });
        }
        setSelectedFeature(selected);
    }

    function deleteQnA(index) {
        const data = [...QnA];
        if (data.length > 1) {
            delete data[index];
            setQnA(data);
        } else if (data.length === 1) {
            setQnA([]);
        }
    }

    function additionalAttribute(key, value) {
        setAdditionalAttributes({
            ...attributes,
            [key]: value,
        });
    }

    function toggleImageVariantModal(state) {
        setImageVariantModal(state);
    }

    function addMoreQA() {
        setQnA((prev) => [
            ...prev,
            { question: '', answer: '', id: uniqueId() },
        ]);
    }

    function deleteServerImage(select_image) {
        dispatch(DeleteImage({ url: select_image }))
            .unwrap()
            .then(() => {
                setProductImagesUrl((images) =>
                    images.filter((item) => item !== select_image)
                );
            });
    }

    useEffect(() => {
        dispatch(CategoryListData({ level: 'first' }));
        dispatch(GetBrandsData());
        dispatch(SubAttributeList({ attribute: 'brand' })).then((res) => {
            setLoading(false);
        });
        if (id !== '0') {
            dispatch(GetProductDetails({ id })).then(() => {
                setShowFormFields(true);
            });
        } else {
            setShowFormFields(true);
        }
        return () => {
            dispatch(ClearProductDetailsData());
        };
    }, []);

    useEffect(() => {
        if (
            store.product_details &&
            Object.keys(store.product_details).length > 0
        ) {
            setFirstCategoryId(store.product_details.firstCategoryDetails);
            setSecondCategoryId(store.product_details.secondCategoryDetails);
            setThirdCategoryId(store.product_details.thirdCategoryDetails);
            setIsFeatured(store.product_details.isFeatured);
            setIsIntroducingNew(store.product_details.isIntroducingNew);
            setFeatureBanner(store.product_details.featureBanner);
            setIntroducingNewBanner(store.product_details.introducingNewBanner);
            setAdditionalAttributes(store.product_details.attr);
            setProductImagesUrl(store.product_details.images);
            setQnA(store.product_details.QnA);
            if (store.product_details.parentAttributes.length > 0) {
                setSelectedFeature(
                    store.product_details.parentAttributes.map(({ key }) => key)
                );
            }

            setShowVariantFields(
                store.product_details.type === 'variable' ? true : false
            );
        }
    }, [store.product_details]);

    useEffect(() => {
        setSelectedBrand(attributes.brand);
    }, [attributes.brand]);

    console.log({ selectedFeature });

    return (
        <>
            <div className="bg-white pb-2 rounded-[6px]">
                <div className="flex p-4 items-center justify-between border-b-1">
                    <div className="flex gap-4 items-center">
                        <ArrowLeftOutlined onClick={goBack} />
                        {id === '0' ? (
                            <div className="table-name">Add New Product</div>
                        ) : (
                            <div className="table-name">Edit Product</div>
                        )}
                    </div>
                </div>
                <div className="rounded-[6px] border-1 p-8 m-2">
                    {show_form_fields && (
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            autoComplete="off"
                            className=""
                            initialValues={{
                                name: store.product_details.name,
                                introduction:
                                    store.product_details.introduction,
                                sku: store.product_details.sku,
                                hsnCode: store.product_details.hsn,
                                gst: store.product_details.gst,
                                isFeatured: store.product_details.isFeatured,
                                description: store.product_details.description,
                                type: store.product_details.type || 'simple',
                                status:
                                    store.product_details.status === 'active'
                                        ? true
                                        : false,
                                mfgCode: store.product_details.mfgCode,
                                isConciousWall:
                                    store.product_details.isConciousWall,
                                isBestSeller:
                                    store.product_details.isBestSeller,
                                metaDescription:
                                    store.product_details.metaDescription,
                                metaTitle: store.product_details.metaTitle,
                                howToUse: store.product_details.howToUse,
                                metaKeywords:
                                    store.product_details.metaKeywords,
                            }}
                        >
                            <div className="flex">
                                <div className="w-3/5 pe-8">
                                    <div className="flex mb-8 items-center">
                                        <h1 className="md:text-[1vw] font-semibold">
                                            Product Type -
                                        </h1>
                                        <Form.Item
                                            className="mb-0"
                                            name="type" // This is the name of the field
                                            valuePropName="option"
                                        >
                                            <Select
                                                className="ms-3 mb-0"
                                                placeholder="Add type of Product:-"
                                                onChange={productType}
                                                defaultValue={'simple'}
                                                value={
                                                    showVariantFields
                                                        ? 'variable'
                                                        : 'simple'
                                                }
                                                style={{
                                                    width: 150,
                                                }}
                                                options={[
                                                    {
                                                        value: 'simple',
                                                        label: 'Simple Product',
                                                    },
                                                    {
                                                        value: 'variable',
                                                        label: 'Variable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        className="mb-4"
                                        name="name"
                                        label={
                                            <label className="text-[#1A3353]">
                                                Product Name *
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="Product Name"
                                            className="h-[40px] w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="mb-4"
                                        name="introduction"
                                        label={
                                            <label className="text-[#1A3353]">
                                                Introduction
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="Introduction"
                                            className="h-[40px] w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="mb-4"
                                        name="sku"
                                        label={
                                            <label className="text-[#1A3353]">
                                                SKU *
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="slug"
                                            className="h-[40px]"
                                        />
                                    </Form.Item>

                                    <div className="mb-4" name="brand">
                                        <label className="#1A3353">
                                            Brand *
                                        </label>
                                        <Select
                                            className="w-full h-[40px] mt-2"
                                            placeholder="Select Brand"
                                            onChange={(e) =>
                                                setSelectedBrand(e)
                                            }
                                            options={memoized_brand}
                                            value={selectedBrand}
                                        />
                                    </div>
                                    <div
                                        className="mb-4"
                                        name="firstCategoryId"
                                    >
                                        <label className="#1A3353">
                                            Category *
                                        </label>
                                        <Select
                                            className="w-full h-[40px] mt-2"
                                            placeholder="Select Category"
                                            onChange={getSubCategory}
                                            value={firstCategoryId}
                                        >
                                            {store.primaryCategory?.length >
                                                0 &&
                                                store.primaryCategory.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item._id}
                                                            value={item._id}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                    <div
                                        className="mb-4"
                                        name="secondCategoryId"
                                    >
                                        <label className="#1A3353">
                                            Sub Category *
                                        </label>

                                        <Select
                                            className="w-full h-[40px] mt-2"
                                            placeholder="Select Sub Category"
                                            onChange={getSubSubCategory}
                                            value={secondCategoryId}
                                        >
                                            {store.secondCategory?.length > 0 &&
                                                store.secondCategory?.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item._id}
                                                            value={item._id}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                    <div
                                        className="mb-4"
                                        name="thirdCategoryId"
                                    >
                                        <label className="#1A3353">
                                            Sub Child Category *
                                        </label>
                                        <Select
                                            className="w-full h-[40px] mt-2"
                                            placeholder="Select Sub Child Category"
                                            onChange={(value) =>
                                                setThirdCategoryId(value)
                                            }
                                            value={thirdCategoryId}
                                        >
                                            {store.thirdCategory?.length > 0 &&
                                                store.thirdCategory?.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item._id}
                                                            value={item._id}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                        </Select>
                                    </div>
                                    <Form.Item
                                        className="mb-4"
                                        name="hsnCode"
                                        label={
                                            <label className="text-[#1A3353]">
                                                HSN
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="HSN Code"
                                            className="h-[40px] w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="mb-4"
                                        name="gst"
                                        label={
                                            <label className="text-[#1A3353]">
                                                GST
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="GST"
                                            className="h-[40px] w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="mb-4"
                                        name="mfgCode"
                                        label={
                                            <label className="text-[#1A3353]">
                                                Manufacture Code
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="Enter manufacturer code"
                                            className="h-[40px] w-full"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="m-0"
                                        name="status"
                                        valuePropName="checked"
                                    >
                                        <Checkbox className="me-8">
                                            Status
                                        </Checkbox>
                                    </Form.Item>

                                    <Form.Item
                                        className="m-0"
                                        name="isBestSeller"
                                        valuePropName="checked"
                                    >
                                        <Checkbox className="me-8">
                                            Best Selling
                                        </Checkbox>
                                    </Form.Item>
                                    <div className="my-1">
                                        <Checkbox
                                            className="me-8"
                                            checked={isFeatured}
                                            onChange={(e) =>
                                                setIsFeatured(e.target.checked)
                                            }
                                        >
                                            Featured Products
                                        </Checkbox>
                                    </div>
                                    <div
                                        name="introducingNew"
                                        valuePropName="checked"
                                    >
                                        <Checkbox
                                            className="me-8"
                                            checked={isIntroducingNew}
                                            onChange={(e) =>
                                                setIsIntroducingNew(
                                                    e.target.checked
                                                )
                                            }
                                        >
                                            Introducing New
                                        </Checkbox>
                                    </div>
                                    <Form.Item
                                        name="isConciousWall"
                                        valuePropName="checked"
                                    >
                                        <Checkbox className="me-8">
                                            Conscious Wall
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                <div className="flex flex-col gap-2 w-2/5">
                                    <ImageUpload
                                        uploaded_images={product_images_url}
                                        title="Upload Product Images"
                                        name={'image_product_upload'}
                                        type={'multiple'}
                                        setUploadedImages={setProductImagesUrl}
                                    />

                                    <ImageRender
                                        data={product_images_url}
                                        img_className={'w-[48%]'}
                                        container_classNamer={
                                            'flex flex-row gap-3 flex-wrap'
                                        }
                                    />
                                </div>
                            </div>
                            {isFeatured && (
                                <div className="flex flex-row gap-2 w-full my-4">
                                    <ImageUploadSection
                                        {...{
                                            uploaded_images,
                                            setUploadedImages,
                                            setImagesFiles:
                                                setFeaturedProductImage,
                                            title: 'Upload Featured Banner',
                                            name: 'featured_products',
                                            type: 'single',
                                        }}
                                    />
                                    {featureBanner && (
                                        <ImageRender
                                            data={featureBanner}
                                            className="w-[30%] z-1 relative h-[400px]"
                                            alt="featureBanner"
                                        />
                                    )}
                                </div>
                            )}

                            {isIntroducingNew && (
                                <div className="flex flex-row gap-2 w-full my-4">
                                    <ImageUploadSection
                                        {...{
                                            uploaded_images,
                                            setUploadedImages,
                                            setImagesFiles:
                                                setIntroducingNewImage,
                                            title: 'Upload Introducing New',
                                            name: 'introducing_new',
                                            type: 'single',
                                        }}
                                    />
                                    {introducingNewBanner && (
                                        <ImageRender
                                            data={introducingNewBanner}
                                            className="w-[30%] z-1 relative h-[400px]"
                                            alt="featureBanner"
                                        />
                                    )}
                                </div>
                            )}

                            <Form.Item
                                label={
                                    <label className="text-14 font-medium text-[#084245] mt-4">
                                        Description
                                    </label>
                                }
                                name="description"
                            >
                                <TextArea
                                    placeholder="Enter description"
                                    className="mt-1"
                                    autoSize={{
                                        minRows: 5,
                                        maxRows: 12,
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={
                                    <label
                                        className="text-14 font-medium text-[#084245]"
                                        htmlFor="Name"
                                    >
                                        How to use
                                    </label>
                                }
                                name={'howToUse'}
                            >
                                <TextArea
                                    placeholder="Enter how to use"
                                    className="mt-1"
                                    autoSize={{
                                        minRows: 5,
                                        maxRows: 12,
                                    }}
                                />
                            </Form.Item>
                            <div>
                                <div className="flex flex-row justify-between items-end mb-2">
                                    <p>QnA</p>
                                    <Button
                                        className="bg-[#275B6A] text-white flex justify-center items-center"
                                        onClick={addMoreQA}
                                    >
                                        Add more
                                    </Button>
                                </div>
                                <div className="border-2 p-4">
                                    {QnA?.length > 0 &&
                                        QnA.map((qa, index) => {
                                            return (
                                                <React.Fragment key={qa.id}>
                                                    <div className="flex flex-row gap-2">
                                                        <Input
                                                            placeholder="Enter question"
                                                            value={qa.question}
                                                            onChange={(e) =>
                                                                handleProductsFields(
                                                                    'QnA',
                                                                    'question',
                                                                    e.target
                                                                        .value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <Input
                                                            placeholder="Enter answer"
                                                            value={qa.answer}
                                                            onChange={(e) =>
                                                                handleProductsFields(
                                                                    'QnA',
                                                                    'answer',
                                                                    e.target
                                                                        .value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                        <div
                                                            className="p-2"
                                                            onClick={() =>
                                                                deleteQnA(index)
                                                            }
                                                        >
                                                            <DeleteFilled
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            />
                                                            {/* <DeleteColumnOutlined /> */}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                            </div>

                            <Form.Item
                                name="metaDescription"
                                label="Meta Description"
                                className="mt-4"
                            >
                                <Input.TextArea
                                    style={{ width: '100%' }}
                                    placeholder="Enter Meta Description"
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name="metaTitle" label="Meta Title">
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="Enter Meta Title"
                                />
                            </Form.Item>

                            <Form.Item
                                name="metaKeywords"
                                label="Meta Keywords"
                            >
                                <Select
                                    required
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="Enter meta keywords"
                                    allowClear
                                    // onChange={handleChange}
                                    // options={options}
                                />
                            </Form.Item>

                            <div className="flex items-center px-8 mt-8 mb-4">
                                <h1 className="md:text-[1vw] font-semibold ">
                                    Additional Features -
                                </h1>
                                <Select
                                    mode="multiple"
                                    className="ms-3  min-w-md "
                                    placeholder="Size in ml"
                                    defaultValue={['ingredients']}
                                    style={{
                                        minWidth: '150px',
                                    }}
                                    onChange={(e, v) =>
                                        handleAddFeatureSelect(e, v)
                                    }
                                    value={selectedFeature}
                                    options={store.attributes_mapping.optional}
                                />
                            </div>
                            <div className="flex flex-wrap px-8 justify-between">
                                {selectedFeature.map((selectedItem, index) => {
                                    const selected =
                                        store.attributes_mapping.optional.find(
                                            (option) =>
                                                option.value === selectedItem
                                        );

                                    return (
                                        <>
                                            {selected && (
                                                <div
                                                    key={selected.id}
                                                    className="w-[32%]"
                                                >
                                                    <GetAttributesOptions
                                                        id={selected.id}
                                                        data={selected}
                                                        selectAttribute={
                                                            additionalAttribute
                                                        }
                                                        product_details={store.product_details}
                                                        value={
                                                            attributes[
                                                                selected.value
                                                            ] ?? undefined
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </>
                                    );
                                })}
                            </div>

                            {/* Variant Section Starts */}
                            {showVariantFields && (
                                <VariantCardWrapper ref={variant_state_ref} />
                            )}
                            {/* Variant section END */}

                            <div className="flex gap-4 mt-6 w-2/5 px-8">
                                <Button
                                    htmlType="submit"
                                    className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                    loading={btn_loader}
                                >
                                    SAVE CHANGES
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                    onClick={goBack}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            </div>

            {/* Image upload modals opens when the variant is choosed  */}
            <MultipleImageVariant
                open={image_variant_modal}
                onCancel={toggleImageVariantModal}
                data={image_variant_local}
                centered
                // onOk={uploadImageVariant}
            />
            {/* Image modals for the is Introducing new */}
            <ImageVariantModal
                open={is_IntroducingNewModal}
                onCancel={toggleIntroducingNewImageModal}
                data={introducing_new_local}
                centered
                onOk={uploadIntroducingNewImage}
            />
            {/* Image modal for the isFeaturedProducts */}
            <ImageVariantModal
                open={isFeaturedProducts_modal}
                onCancel={toggleFeaturedProductsModal}
                data={featured_products_local}
                centered
                onOk={uploadFeaturedProductImage}
            />
        </>
    );
};

export default AddNewProduct;

const ImageVariantModal = ({ open, onCancel, data, ...restProps }) => {
    return (
        <>
            <Modal open={open} onCancel={() => onCancel(false)} {...restProps}>
                <div>Image Preview:-</div>
                {data.file && (
                    <div>
                        <img
                            src={URL.createObjectURL(data.file)}
                            className="w-20 h-20"
                            alt="variant"
                        />
                    </div>
                )}
            </Modal>
        </>
    );
};

const MultipleImageVariant = ({ open, onCancel, data = [], ...restProps }) => {
    return (
        <>
            <Modal open={open} onCancel={() => onCancel(false)} {...restProps}>
                <div className="font-bold text-14">Image Preview:-</div>
                <div className="flex flex-row gap-2 mt-4">
                    {data.file.length > 0 &&
                        data.file.map((item) => (
                            <div key={item.name}>
                                <img
                                    src={URL.createObjectURL(item)}
                                    className="w-20 h-20"
                                    alt="variant"
                                />
                            </div>
                        ))}
                </div>
            </Modal>
        </>
    );
};
