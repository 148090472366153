import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import DetailsCustomer from '../../../../components/userDetails/detailsCustomer';
import { Button } from 'antd';
// import DetailsCustomer from "../../../components/userDetails/detailsCustomer";

// details of the customer to be sent to the DetailsCustomer component
const details = [
    {
        key: 1,
        label: 'ID',
        content: 'ABC123',
    },
    {
        key: 2,
        label: 'Admin/Sub-Admin Name',
        content: 'Sumit Kumar',
    },
    {
        key: 3,
        label: 'Mobile Number',
        content: '+91-8844557788',
    },
    {
        key: 4,
        label: 'Email Address',
        content: 'sumitkumar@gmail.com',
    },
    {
        key: 8,
        label: 'Created On',
        content: '20 Feb 2023',
    },
];
const DeliveryPartnerDetails = () => {
    function goBack() {
        window.history.back();
    }

    return (
        <div className="bg-white rounded-[6px]">
            <div className="flex gap-4 items-center p-4 border-b-1">
                <Link to="#">
                    <ArrowLeftOutlined onClick={goBack} />
                </Link>
                <h1 className="table-name">Delivery Partner Details</h1>
                <img
                    src="/icons/common/edit.svg"
                    className="h-[20px]"
                    alt="Edit"
                />
            </div>
            <DetailsCustomer dataSource={details} />
            <div className="border border-black rounded-lg w-fit p-16 m-5 flex flex-col items-center justify-center">
                <h1 className="table-name mb-4 w-full">Account Limit</h1>
                <div className="flex gap-4">
                    <Button className="border-2 border-[#3B3B3B] text-[#3B3B3B] h-auto flex items-center py-2  px-8">
                        Deposit
                    </Button>
                    <Button className="border-2 border-transparent text-white h-auto flex items-center py-2 px-8 bg-[#A6C363]">
                        Renew
                    </Button>
                </div>
            </div>
            <div className="w-full mt-5 h-[1px]"></div>
        </div>
    );
};

export default DeliveryPartnerDetails;
