import { Button, ConfigProvider, Input, message } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Alertify from '../../scripts/alertify';
import { userLogin } from '../../redux/actions/auth-action';
import { ROLES } from '../../constant/roles-constant';
import { useLoader } from '../../hooks/useLoader';
import { validateEmail } from '../../scripts/validations';

const SignInScreen = () => {
    const [loader, startLoader, endLoader] = useLoader();
    const [input, setInput] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function signIn() {
        if (!input || !password) {
            return Alertify.error('Please, enter email/mobile and password');
        }
        if(!validateEmail(input)){
            return Alertify.error('Please, enter valid email');
        }
        startLoader();
        dispatch(userLogin({ input, password }))
            .unwrap()
            .then((res) => {
                if (
                    res?.data?.role === ROLES.Doctor ||
                    res?.data?.role === ROLES.Delivery ||
                    res?.data?.role === ROLES.PHRAMA
                ) {
                    navigate('/orders');
                } else {
                    navigate('/users');
                }
            })
            .catch((error) => {
                // console.log('Error catch', error);
            })
            .finally(endLoader);
    }
    return (
        <div className="flex flex-row justify-evenly items-center w-full h-screen">
            <div className="md:h-4/5 md:w-1/2 hidden md:block">
                <img
                    className="h-full m-auto"
                    src="/images/left.png"
                    alt="user"
                />
            </div>
            <div className="flex h-fit md:w-1/2 flex-col rounded-[20px] w-full">
                <div className="bg-[#F4F5F5] px-8 py-16 md:px-16 md:py-8 w-[90%] m-auto rounded-lg">
                    <div className=" flex flex-col gap-1">
                        <h2 className="mb-6 text-18 md:text-[2.5vw] font-bold text-[#1A3353]">
                            Sign In
                        </h2>
                    </div>

                    <div className="flex flex-col gap-3">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Input: {
                                        hoverBorderColor: 'none',
                                        activeBorderColor: 'none',
                                    },
                                },
                            }}
                        >
                            <div className="flex flex-col gap-1">
                                <label
                                    className="text-12 md:text-14 font-normal text-[#1A3353]"
                                    htmlFor="Name"
                                >
                                    Email/Mobile
                                </label>
                                <Input
                                    // bordered={false}
                                    placeholder="Email/Mobile "
                                    className="rounded-md px-3 h-[40px] text-[#1A3353]"
                                    onChange={(e) =>
                                        setInput(e.target.value.trim())
                                    }
                                    value={input}
                                />
                            </div>
                            <div className="flex flex-col gap-3">
                                <label
                                    className="text-12 md:text-14 font-normal text-[#1A3353]"
                                    htmlFor="Name"
                                >
                                    Password
                                </label>
                                <Input.Password
                                    type="password"
                                    placeholder="Password"
                                    className="rounded-md px-3 h-[40px] text-[#45494d]"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.code === 'Enter' && signIn()
                                    }
                                    value={password}
                                />
                            </div>
                        </ConfigProvider>
                    </div>

                    <div className="mt-5 flex flex-row gap-20 ">
                        <Button
                            className="w-1/2 flex items-center justify-center rounded-[6px] bg-[#FF8F2F] p-4 h-[40px] font-semibold text-white"
                            onClick={signIn}
                            loading={loader}
                        >
                            Sign In
                        </Button>

                        <Link to={'/forgot-password'}>
                            <p className="mt-2 @sm:text-[4.0vw]  md:text-[1.0vw]  text-[#1A3353] cursor-pointer">
                                Forgot Password ?
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInScreen;
