import React, { useEffect, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import { ConfigProvider, Image, Pagination, Switch } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useAppSelector from '../../hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import {
    DeleteDeal,
    EditCrazyDeals,
    GetCrazyDeals,
} from '../../redux/actions/crazy-deals-actions';

const columns = [
    {
        title: 'BANNER IMAGE',
        dataIndex: 'bannerImage',
        render: (bannerImage) => (
            <>
                <div className="w-[100px] h-[100px]">
                    <img src={bannerImage} alt="banner" />
                </div>
            </>
        ),
    },
    {
        title: 'BANNER NAME',
        dataIndex: 'bannerName',
    },
    {
        title: 'DISCOUNT ON',
        dataIndex: 'types',
        render: (types) => (
            <>
                <p>{types}</p>
            </>
        ),
    },
    {
        title: 'MIN DISCOUNT',
        dataIndex: 'minDiscount',
    },
    {
        title: 'MAX DISCOUNT',
        dataIndex: 'maxDiscount',
    },
];

const CrazyDealsListing = () => {
    const [searchP] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const store = useAppSelector((state) => ({
        deals_list: state.crazydeals_store.deals_list,
        deals_list_count: state.crazydeals_store.deals_list_count,
    }));

    const action = [
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (_, record) => (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimaryBorder: '#6E3362',
                            colorPrimary: '#6E3362',
                        },
                    }}
                >
                    <Switch
                        checked={record.status}
                        className="flex self-center"
                        onClick={() => toggleStatus(record._id, !record.status)}
                        // style={{ border: "1px solid #6E3362" }}
                    />
                </ConfigProvider>
            ),
        },
        {
            title: 'ACTIONS',
            dataIndex: 'action',
            render: (_, record) => (
                <span className="flex gap-4">
                    <Link to={`/crazy-deals/crazy-deals-detail/${record._id}`}>
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px] cursor-pointer"
                        />
                    </Link>
                    <div onClick={() => deleteDeals(record._id)}>
                        <img
                            src="/icons/common/delete.svg"
                            alt="delete"
                            className="h-[20px] cursor-pointer"
                        />
                    </div>
                </span>
            ),
        },
    ];
 
    const all_columns = [...columns, ...action];

    function paginate(page, pageSize) {
        setCurrentPage(page);
        setPageSize(pageSize);
        navigate(`?page=${page}&pageSize=${pageSize}`, { replace: true });
    }


    function deleteDeals(id) {
        dispatch(DeleteDeal({ id }));
    }

    function toggleStatus(id, status) {
        alert('are you sure want to change the status');
        dispatch(EditCrazyDeals({ id, payload: { status } }));
    }

    useEffect(() => {
        dispatch(GetCrazyDeals({ page: Number(currentPage), pageSize: Number(pageSize)  })).then((res)=>{
            setLoading(false)
        })
    }, [currentPage, pageSize]);

    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <SimpleTable
                tableName="Crazy Deals"
                addNew={true}
                addNewLink="/crazy-deals/crazy-deals-detail/0"
                actionSelect={false}
                export={false}
                columns={all_columns}
                dataSource={store.deals_list}
                loading={loading}
            />
            <div className="flex justify-center  py-10">
                <Pagination
                    current={currentPage}
                    total={store.deals_list_count}
                    pageSize={pageSize}
                    onChange={paginate}
                    hideOnSinglePage
                    pageSizeOptions={[10, 20, 50]}
                />
            </div>
        </div>
    );
};

export default CrazyDealsListing;
