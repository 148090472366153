import React, { useEffect, useMemo, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import {
    AutoComplete,
    Button,
    DatePicker,
    Form,
    Input,
    Modal,
    Pagination,
    Select,
} from 'antd/es';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom/dist';
import { useDispatch } from 'react-redux';
import {
    CreateNewInventory,
    EditInventory,
    InventoryList,
    SearchProductBySku,
    SearchProductBySkuDetails,
} from '../../redux/actions/inventory-action';
import useAppSelector from '../../hooks/useAppSelector';
import dayjs from 'dayjs';
import Topbar from '../../components/topbar/topbar';
import SearchBar from '../../components/topbar/searchbar';
import { useDebounce } from '../../hooks/useDebounce';



const StoreDetails = () => {
    const { id: storeId } = useParams();

    // console.log(storeId);
    const dispatch = useDispatch();
    const [searchP] = useSearchParams();
    const navigate = useNavigate();
    const [type_creation, setTypeCreation] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const store = useAppSelector((state) => ({
        inventoryList: state.inventory.inventoryList,
        totalInventory: state.inventory.totalInventory,
    }));

    const [products_lists, setProductsList] = useState([]);
    const [selectedProductType, setSelectedProductType] = useState('simple');
    const INITIAL_STATE = {
        productType: 'simple',
        productName: '',
        sku: '',
        status: 'active',
        expiryDate: '',
        storeId: storeId,
        discount: '',
        mrp: '',
        salePrice: '',
        gst: '',
        discountPrice: '',
        productVariantId: undefined,
        id: 0,
    }
    const [selected_product, setSelectedProduct] = useState(INITIAL_STATE);
    const closeModal = () => {
        setTypeCreation(null);
    };
    const showAddNew = () => {
        setTypeCreation('add');
        setSelectedProduct({});
    };
    const [form] = Form.useForm();

    const columns = [
        { title: 'PRODUCT NAME', dataIndex: 'productName' },
        { title: 'SKU', dataIndex: 'sku' },
        { title: 'TYPE', dataIndex: 'productType' },
        { title: 'BATCH', dataIndex: 'batchId' },
        { title: 'MRP', dataIndex: 'mrp' },
        { title: 'DISCOUNT', dataIndex: 'discount' },
        { title: 'SPECIAL PRICE', dataIndex: 'salePrice' },
        { title: 'INVENTORY', dataIndex: 'quantity' },
        {
            title: 'EXPIRY DATE',
            dataIndex: 'expiryDate',
            render: (expiryDate) => {
                const formattedDate = new Date(expiryDate).toLocaleDateString(
                    'en-GB'
                );
                return formattedDate;
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (_id, record) => {
                if (record?.quantity === 0) return 'Out of Stock';
                else if (record?.quantity <= 100) return 'Low';
                else if (record?.quantity >= 1000) return 'Good';
            },
        },
    ];

    const action_column = [
        {
            title: 'ACTIONS',
            dataIndex: 'action',
            render: (id, record) => {
                return (
                    <div className="flex" onClick={() => editInventory(record)}>
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px] cursor-pointer"
                            onClick={() => { }}
                        />
                    </div>
                );
            },
        },
    ];

    const all_columns = [...columns, ...action_column];

    const paginate = (pageNumber, pageSize) => {
        setCurrentPage(pageNumber);
        setPageSize(pageSize);
        navigate(`?page=${pageNumber}&pageSize=${pageSize}`, { replace: true });
    };

    function searchProductInHeader(type, value) {
        // dispatch(SearchProductBySku({ value, is_search: true }));
        setSearchValue(value)
    }

    function searchProductBySku(key, value) {
        setSelectedProduct((prev) => ({ ...prev, sku: value }));
        dispatch(SearchProductBySkuDetails({ key, value, selectedProductType }))
            .unwrap()
            .then((data) => {
                setProductsList(data.res.data.data);
            })
            .catch();
    }

    function editInventory(record) {
        const data = { ...record, storeId: storeId };
        setSelectedProduct({ ...data });
        console.log("Record---------", record)
        setTypeCreation('edit');
    }

    const memoized_list = useMemo(() => {
        return products_lists?.map((item, index) => ({
            productName: item.name,
            sku: item.sku,
            batch: item.batch,
            gst: item.gst,
            id: item._id,
            productId: item.productId ?? item._id,
            productVariantId: item._id,
            label: item.sku,
            value: item.sku,
            index: index,
            productType: item.type ?? 'simple',
            discount: item.discount,
            status: item.status,
            mrp: item.mrp,
            salePrice: item.salePrice,
            expiryDate: item.expiryDate,
        }));
    }, [products_lists]);

    function handleInventoryDetails(key, value) {
        let data = { ...selected_product, [key]: value };
        if ((key === 'discount' || key === 'salePrice') && !!data.salePrice) {
            const discountPrice =
                Number(data.salePrice) -
                (Number(data.salePrice) * Number(data.discount)) / 100;
            const afterGst =
                (discountPrice * Number(data.gst)) / 100 + discountPrice;
            data = {
                ...data,
                discountPrice: String(afterGst),
            };
        }

        setSelectedProduct(data);
    }

    // function searchProductBySku(type, value) {
    //     dispatch(
    //         InventoryList({
    //             page: currentPage,
    //             pageSize: pageSize,
    //             search: value,
    //         })
    //     );
    // }

    function saveDetails() {
        if (selected_product.productType === 'simple') {
            delete selected_product.productVariantId
        }
        if (type_creation === 'add') {
            dispatch(CreateNewInventory(selected_product))
                .unwrap()
                .then(() => {
                    dispatch(
                        InventoryList({ page: Number(currentPage), pageSize: Number(pageSize) })
                    );
                    setSelectedProduct(INITIAL_STATE)
                    setTypeCreation(null);
                })
                .catch(() => { });
        } else if (type_creation === 'edit') {
            dispatch(
                EditInventory({ selected_product, id: selected_product.id })
            )
                .unwrap()
                .then(() => {
                    dispatch(
                        InventoryList({ page: Number(currentPage), pageSize: Number(pageSize) })
                    );
                    setSelectedProduct(INITIAL_STATE)
                    setTypeCreation(null);
                })
                .catch(() => { });
        }
    }

    const debouncedRequest = useDebounce((callback) => callback(), 300);

    useEffect(() => {
        if (searchValue) {
            debouncedRequest(() => {
                dispatch(InventoryList({ page: Number(currentPage), pageSize: Number(pageSize), search: searchValue }));
            })
        } else {
            dispatch(InventoryList({ page: Number(currentPage), pageSize: Number(pageSize) }));
        }
    }, [currentPage, pageSize, searchValue]);

    return (
        <>
            <SearchBar
                onSearch={searchProductInHeader}
                placeholder={'Search by SKU'}
            />
            <div className="bg-white pb-2 rounded-[6px]">
                <SimpleTable
                    tableName={'Inventory Listings'}
                    goBack={true}
                    search={false}
                    showAddNew={showAddNew}
                    actionSelect={true}
                    export={true}
                    columns={all_columns}
                    dataSource={store.inventoryList}
                />
                <div className="flex justify-center  py-10">
                    <Pagination
                        current={currentPage}
                        total={store.totalInventory}
                        pageSize={pageSize}
                        onChange={paginate}
                        hideOnSinglePage
                        pageSizeOptions={[10, 20, 50]}
                    />{' '}
                </div>
            </div>
            <Modal
                centered
                open={!!type_creation}
                onCancel={closeModal}
                footer={false}
                width={1000}
            >
                <div className="w-full h-full p-16">
                    <h1 className="table-name mb-4">Add New</h1>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <div className="w-1/2 flex flex-col mb-6">
                            <label className="text-[#1A3353] mb-2">
                                Product Type
                            </label>
                            <Select
                                className="h-[40px]"
                                options={[
                                    {
                                        value: 'simple',
                                        label: 'Simple Product',
                                    },
                                    {
                                        value: 'variable',
                                        label: 'Variable Product',
                                    },
                                ]}
                                value={
                                    selected_product.productType
                                        ? selected_product.productType
                                        : selectedProductType
                                }
                                onChange={(value) => {
                                    setSelectedProductType(value);
                                    handleInventoryDetails(
                                        'productType',
                                        value
                                    );
                                }}
                            />
                        </div>

                        <div className="flex gap-8">
                            <div className="w-1/2 flex flex-col" name="sku">
                                <label className="text-[#1A3353] mb-2">
                                    SKU
                                </label>
                                <AutoComplete
                                    options={memoized_list}
                                    onSelect={(e, options) =>
                                        setSelectedProduct({
                                            ...options,
                                            storeId: storeId,
                                        })
                                    }
                                    value={selected_product.sku}
                                    placeholder=""
                                    filterOption={(inputValue, option) =>
                                        option.value
                                            .toUpperCase()
                                            .indexOf(
                                                inputValue.toUpperCase()
                                            ) !== -1
                                    }
                                >
                                    <Input.Search
                                        size="large"
                                        placeholder="Enter Sku"
                                        enterButton
                                        allowClear
                                        onChange={(e) =>
                                            searchProductBySku(
                                                'search',
                                                e.target.value
                                            )
                                        }
                                    />
                                </AutoComplete>{' '}
                            </div>
                            <div className="w-1/2">
                                <label className="text-[#1A3353] mb-2">
                                    Product Name
                                </label>
                                <Input
                                    placeholder="Enter Product Name"
                                    className="h-[40px] mt-2"
                                    disabled
                                    value={selected_product.productName}
                                />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="w-1/2" name="address">
                                <label className="text-[#1A3353] mb-2">
                                    Batch
                                </label>
                                <Input
                                    placeholder="Enter Batch"
                                    value={selected_product.batchId}
                                    className="h-[40px] mt-2"
                                    onChange={(e) =>
                                        handleInventoryDetails(
                                            'batchId',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="w-1/2" name="quantity">
                                <label className="text-[#1A3353] mb-2">
                                    Quantity
                                </label>
                                <Input
                                    placeholder="Enter Quantity"
                                    className="h-[40px] mt-2"
                                    value={selected_product.quantity}
                                    onChange={(e) =>
                                        handleInventoryDetails(
                                            'quantity',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="w-1/2" name="status">
                                <label className="text-[#1A3353] mb-2">
                                    MRP
                                </label>
                                <Input
                                    placeholder="Enter MRP"
                                    className="h-[40px] mt-2"
                                    value={selected_product.mrp}
                                    onChange={(e) =>
                                        handleInventoryDetails(
                                            'mrp',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="w-1/2" name="specialPrice">
                                <label className="text-[#1A3353] mb-2">
                                    Sale Rate
                                </label>
                                <Input
                                    placeholder="Enter Sale Rate"
                                    className="h-[40px] mt-2"
                                    value={selected_product.salePrice}
                                    onChange={(e) =>
                                        handleInventoryDetails(
                                            'salePrice',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex gap-8 mt-4">
                            <div className="w-1/2" name="discount">
                                <label className="text-[#1A3353] mb-2">
                                    Discount Percentage
                                </label>
                                <Input
                                    className="w-full h-[40px] mt-2"
                                    type="number"
                                    placeholder="Enter discount percentage"
                                    maxLength={3}
                                    max={100}
                                    value={selected_product.discount}
                                    onChange={(e) =>
                                        handleInventoryDetails(
                                            'discount',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="w-1/2" name="discountPrice">
                                <label className="text-[#1A3353] mb-2">
                                    Discounted Price
                                </label>
                                <Input
                                    className="w-full h-[40px] mt-2"
                                    type="number"
                                    disabled
                                    placeholder="Discounted Price"
                                    value={selected_product.discountPrice}
                                />
                            </div>
                        </div>
                        <div className="mt-4 flex gap-8">
                            <div className="w-1/2" name="expiryDate">
                                <label className="text-[#1A3353] mb-2">
                                    Expiry Date
                                </label>
                                <DatePicker
                                    className="w-full h-[40px] mt-2"
                                    format={'YYYY-MM-DD'}
                                    value={
                                        selected_product.expiryDate
                                            ? dayjs(selected_product.expiryDate)
                                            : null
                                    }
                                    onChange={(e, date) =>
                                        handleInventoryDetails(
                                            'expiryDate',
                                            date
                                        )
                                    }
                                />
                            </div>
                            <div className="w-1/2"></div>
                        </div>
                        <div className="flex gap-4 w-1/2 mt-6">
                            <Button
                                htmlType="submit"
                                className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                onClick={saveDetails}
                            >
                                SAVE CHANGES
                            </Button>
                            <Button
                                htmlType="reset"
                                className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                onClick={closeModal}
                            >
                                CANCEL
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default StoreDetails;
