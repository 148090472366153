import uuid from 'react-native-uuid';

export const uniqueId = () => {
    const unique_id = uuid.v4();
    return unique_id.slice(0, 8);
};

export function FindDifferentString(array1, array2) {
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    // Find the difference between set1 and set2
    const differenceSet = new Set([...set1].filter((x) => !set2.has(x)));

    // Convert the Set back to an array
    const differenceArray = Array.from(differenceSet);

    // If there are multiple differences, you may want to handle them differently
    // For simplicity, let's assume we're only interested in the first difference
    if (differenceArray.length > 0) {
        return differenceArray[0];
    } else {
        return 'No difference found';
    }
}
