import { createSlice } from '@reduxjs/toolkit';

import {
    InventoryList,
    SearchProductBySku,
    _ManipulateInventoryList,
    _ManipulateSkuInventoryList,
} from '../actions/inventory-action';

const initialState = {
    inventoryList: [],
};

const inventorySlice = createSlice({
    name: 'inventory-slice',
    initialState,
    reducers: {
        // StoreListId: (state, action) => {
        //     state.storeListId = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(InventoryList.fulfilled, (state, { payload }) => {
            console.log('Payload', payload.data.list);
            state.inventoryList = _ManipulateInventoryList(payload.data.list);
            console.log(
                '_ManipulateInventoryList',
                _ManipulateInventoryList(payload.data.list)
            );
            state.totalInventory = payload.data.count;
        });
        builder.addCase(SearchProductBySku.fulfilled, (state, { payload }) => {
            if (payload.is_search === true) {
                state.inventoryList = _ManipulateSkuInventoryList(
                    payload.res.data.data.list
                );
                state.totalInventory = payload.res.data.data.count;
            }
        });
    },
});

export default inventorySlice.reducer;
