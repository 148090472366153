import { ArrowLeftOutlined } from '@ant-design/icons';
import { Checkbox, ConfigProvider, Form, Input, Radio, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    CategoryListData,
    GetThirdLevelCategories,
} from '../../redux/actions/category-action';
import { GetBrandsData } from '../../redux/actions/container-actions';
import { useDispatch, useSelector } from 'react-redux';
import useAppSelector from '../../hooks/useAppSelector';
import {
    CreateBanner,
    EditBanner,
    GetBannerDetails,
} from '../../redux/actions/banner-action';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd/es';
import { UploadImages } from '../../redux/actions/product-action';

const BannerDetail = () => {
    const { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [desktopBanner, setDesktopBanner] = useState(null);
    const [selectedValue, setSelectedValue] = useState('brand');
    const [details, setDetails] = useState({
        bannerName: '',
        desktopBannerImg: '',
        mobileBannerImg: '',
        status: null,
        types: '',
        minDiscount: '',
        maxDiscount: '',
    });
    const [mobileBanner, setMobileBanner] = useState(null);
    const [image_modal, setToggleImageModal] = useState(false);
    const [brandId, setBrandId] = useState(null);
    const [is_simple_banner, setSimpleBanner] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [imageMobile_modal, setToggleImageMobileModal] = useState(false);
    const [thirdLevelCategory, setThirdLevelCategory] = useState(null);

    const dispatch = useDispatch();
    const store = useAppSelector((state) => ({
        brands: state.container_store.brands_data,
        primary_category: state.container_store.categories,
    }));

    // const memoized_category = useMemo(() => {
    //     return store.primary_category.map((item) => ({
    //         label: item.name,
    //         value: item._id,
    //     }));
    // }, [store.primary_category]);

    function goBack() {
        window.history.back();
    }
    const handleChange = (e) => {
        setSelectedValue(e.target.value);
        console.log(selectedValue);
        if (e.target.value === 'simple-brand') {
            setSelectedValue(e.target.value);
            setSimpleBanner(e.target.value);
        } else if (e.target.value === 'simple-category') {
            setSelectedValue(e.target.value);
            setSimpleBanner(e.target.value);
        }
    };

    function handleDesktopImageChange(e) {
        setDesktopBanner(e.target.files[0]);
        toggleImageUploadModal(true);
    }

    function handleMoileImageChange(e) {
        setMobileBanner(e.target.files[0]);
        toggleImageMobileUploadModal(true);
    }

    function toggleImageUploadModal(state) {
        setToggleImageModal(state);
    }

    function toggleImageMobileUploadModal(state) {
        setToggleImageMobileModal(state);
    }

    function uploadDesktopImage() {
        dispatch(UploadImages({ images: [desktopBanner] }))
            .unwrap()
            .then(({ res }) => {
                setDesktopBanner(res.data.data.urls[0]);
                setToggleImageModal(false);
            })
            .catch(() => {});
    }

    function uploadMobileImage() {
        dispatch(UploadImages({ images: [mobileBanner] }))
            .unwrap()
            .then(({ res }) => {
                setMobileBanner(res.data.data.urls[0]);
                setToggleImageMobileModal(false);
            })
            .catch(() => {});
    }

    function handleSubmit(form) {
        console.log(form);
        const data = {
            ...form,
            desktopBannerImg: desktopBanner,
            mobileBannerImg: mobileBanner,
            minDiscount: Number(form.minDiscount),
            maxDiscount: Number(form.maxDiscount),
        };
        if (selectedValue === 'category') {
            data['categoryId'] = categoryId;
            data['types'] = selectedValue;
        } else if (selectedValue === 'brand') {
            data['brandId'] = brandId;
            data['types'] = selectedValue;
        } else if (selectedValue === 'simple-brand') {
            data['brandId'] = brandId;
            data['types'] = selectedValue;
        } else if (selectedValue === 'simple-category') {
            data['categoryId'] = categoryId;
            data['types'] = selectedValue;
        }

        if (id === '0') {
            dispatch(
                CreateBanner({
                    payload: data,
                })
            )
        } else if (id !== '0') {
            // console.log("Category Id--------", categoryId)
            if (selectedValue === 'category') {
                data['categoryId'] = categoryId;
                data['types'] = selectedValue;
            } else if (selectedValue === 'brand') {
                data['brandId'] = brandId;
                data['types'] = selectedValue;
            } else if (selectedValue === 'simple-brand') {
                data['brandId'] = brandId;
                data['types'] = selectedValue;
            } else if (selectedValue === 'simple-category') {
                data['categoryId'] = categoryId;
                data['types'] = selectedValue;
            }
            dispatch(
                EditBanner({
                    payload: data,
                    id,
                })
            );
        }
    }

    function handleCheckbox(key, value) {
        if (key === 'simpleBanner') {
            setSimpleBanner(value);
        }
    }

    useEffect(() => {
        dispatch(GetThirdLevelCategories())
            .unwrap()
            .then((data) => {
                setThirdLevelCategory(data.res.data);
            })
            .catch(() => {});
        dispatch(GetBrandsData());
        if (id !== '0') {
            dispatch(GetBannerDetails({ id }))
                .unwrap()
                .then((data) => {
                    setBrandId(data.res.data.brandId);
                    setCategoryId(data.res.data?.categoryId?._id);
                    setSelectedValue(data.res.data.types);
                    setDetails(data.res.data);
                    setDesktopBanner(data.res.data.desktopBannerImg);
                    setMobileBanner(data.res.data.mobileBannerImg);
                    setLoaded(true);
                })
                .catch(() => {});
        } else {
            setLoaded(true);
        }
    }, []);

    // console.log("Desktop banner ", desktopBanner)

    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <div className="flex gap-4 items-center p-4 border-b-1">
                <Link to="#">
                    <ArrowLeftOutlined onClick={goBack} />
                </Link>
                <h1 className="table-name">Create/Edit Banner</h1>
            </div>
            <div className="p-4 mt-2 rounded-lg">
                <ConfigProvider
                    theme={{
                        components: {
                            Radio: {
                                buttonBg: '#000',
                                buttonCheckedBg: '#FF8F2F',
                                radioSize: 20,
                            },
                        },
                    }}
                >
                    <Radio.Group
                        name="radiogroup"
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        <Radio value={'brand'}>Discount on Brand</Radio>
                        <Radio value={'category'}>Discount on Category</Radio>
                        <Radio value={'simple-brand'}>Simple Brand</Radio>
                        <Radio value={'simple-category'}>Simple Category</Radio>
                    </Radio.Group>
                </ConfigProvider>
                <div className="border-1 rounded-lg p-8 mt-6">
                    {loaded && (
                        <Form
                            layout="vertical"
                            onFinish={handleSubmit}
                            initialValues={{
                                bannerName: details.bannerName,
                                minDiscount: details.minDiscount,
                                maxDiscount: details.maxDiscount,
                            }}
                        >
                            <div className="flex flex-row gap-4">
                                <Form.Item
                                    className="w-4/5"
                                    name="bannerName"
                                    label={
                                        <label className="text-[#1A3353]">
                                            Banner Name *
                                        </label>
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter banner name',
                                        },
                                    ]}
                                >
                                    <Input
                                        // onChange={}
                                        className="h-[40px]"
                                        placeholder="Banner Name"
                                    />
                                </Form.Item>
                                {/* <div className="flex justify-center items-center">
                                    <Checkbox
                                        checked={is_simple_banner}
                                        onChange={(e) =>
                                            handleCheckbox(
                                                'simpleBanner',
                                                e.target.checked
                                            )
                                        }
                                        // disabled={isDisabled('featured')}
                                    >
                                        Simple Banner
                                    </Checkbox>
                                </div> */}
                            </div>
                            <div className="flex gap-8 w-4/5">
                                {selectedValue === 'brand' ||
                                selectedValue === 'simple-brand' ? (
                                    <div className="w-1/2">
                                        <label className="text-[#1A3353]">
                                            Brand
                                        </label>
                                        <Select
                                            className="h-[40px] w-[250px]"
                                            placeholder="Please Select Brands"
                                            options={store.brands.map((brand) => ({
                                                label: brand.value,
                                                value: brand._id,
                                                key: brand._id,
                                            }))}
                                            value={brandId}
                                            onChange={(value, options) => setBrandId(value)}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {store.brands?.map((item) => (
                                                <Select.Option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                ) : selectedValue === 'category' ||
                                  selectedValue === 'simple-category' ? (
                                    <div className="w-1/2">
                                        <label className="text-[#1A3353]">
                                            Category
                                        </label>
                                        {/* Keep in mind only the third level categories will go here in this select box */}
                                        <Select
                                            className="h-[40px] w-[250px]"
                                            placeholder="Please Select Category"
                                            value={categoryId}
                                                // options={third}
                                                onChange={(val) =>
                                                    setCategoryId(val)
                                                }
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1
                                                }
                                        >
                                            {thirdLevelCategory?.map((item) => (
                                                <Select.Option
                                                    key={item._id}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                ) : // <Form.Item
                                //     className="w-1/2"
                                //     name="productSku"
                                //     label={
                                //         <label className="text-[#1A3353]">
                                //             Product SKU
                                //         </label>
                                //     }
                                //     rules={[
                                //         {
                                //             required: true,
                                //             message:
                                //                 'Please enter Product sku',
                                //         },
                                //     ]}
                                // >
                                //     <Input
                                //         placeholder="SKU122"
                                //         className="h-[40px]"
                                //     />
                                // </Form.Item>
                                null}
                                {selectedValue === 'simple-brand' ||
                                    selectedValue === 'simple-category' || (
                                        <>
                                            <Form.Item
                                                className="w-1/2"
                                                name="minDiscount"
                                                label={
                                                    <label className="text-[#1A3353]">
                                                        Minimum Discount
                                                        Percentage *
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter minimum discount percentage',
                                                    },
                                                    {
                                                        validator: (_, value) =>
                                                            value > 0 ||
                                                            value === undefined
                                                                ? Promise.resolve()
                                                                : Promise.reject(
                                                                      'Minimum discount percentage must be greater than 0'
                                                                  ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    className="h-[40px]"
                                                    placeholder="Enter discount value only"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                className="w-1/2"
                                                name="maxDiscount"
                                                label={
                                                    <label className="text-[#1A3353]">
                                                        Maximum Discount
                                                        Percentage *
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter maximum discount percentage',
                                                    },
                                                    {
                                                        validator: (_, value) =>
                                                            value > 0 ||
                                                            value === undefined
                                                                ? Promise.resolve()
                                                                : Promise.reject(
                                                                      'Maximum discount percentage must be less than 0'
                                                                  ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    className="h-[40px]"
                                                    placeholder="Enter discount value only"
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                            </div>
                            <div className="flex gap-8 mt-6 w-full">
                                <div className="flex flex-col gap-4 w-1/2">
                                    <p className="table-name text-[1vw] font-normal">
                                        Desktop Banner *
                                    </p>
                                    <div className="w-full FirstTab border-dashed border-2 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                                        <div>
                                            <img
                                                className="m-auto"
                                                src="/icons/common/upload-file.svg"
                                                alt="Upload File"
                                            />
                                            <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                                Drag & drop files or{' '}
                                                <span className="">
                                                    <label
                                                        // htmlFor="img"
                                                        className="cursor-pointer text-[#FF8F2F] text-[22px] underline"
                                                        // onClick={() =>
                                                        //     document
                                                        //         .getElementById(
                                                        //             'getFileD'
                                                        //         )
                                                        //         .click()
                                                        // }
                                                    >
                                                        Browse
                                                        <input
                                                            type="file"
                                                            data-max-size="2048"
                                                            id="getFileD"
                                                            className="fileIcon invisible"
                                                            onChange={
                                                                handleDesktopImageChange
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                </span>
                                            </div>
                                        </div>

                                        <p className="info">
                                            Supported files: JPEG, PNG
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 w-1/2">
                                    <p className="table-name text-[1vw] font-normal">
                                        Mobile Banner *
                                    </p>
                                    <div className="w-full FirstTab border-dashed border-2 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                                        <div>
                                            <img
                                                className="m-auto"
                                                src="/icons/common/upload-file.svg"
                                                alt="Upload File"
                                            />
                                            <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                                Drag & drop files or{' '}
                                                <span className="">
                                                    <label
                                                        htmlFor="img"
                                                        className="cursor-pointer text-[#FF8F2F] text-[22px] underline"
                                                        onClick={() =>
                                                            document
                                                                .getElementById(
                                                                    'getFile'
                                                                )
                                                                .click()
                                                        }
                                                    >
                                                        Browse
                                                        <input
                                                            type="file"
                                                            data-max-size="2048"
                                                            id="getFile"
                                                            className="fileIcon invisible"
                                                            onChange={
                                                                handleMoileImageChange
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                </span>
                                            </div>
                                        </div>

                                        <p className="info">
                                            Supported files: JPEG, PNG
                                        </p>
                                    </div>
                                    <div className="flex gap-10">
                                        <div>
                                            {desktopBanner &&
                                                desktopBanner !== null && (
                                                    <>
                                                        <div>
                                                            <img
                                                                alt="banner"
                                                                src={
                                                                    desktopBanner
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                        <div>
                                            {mobileBanner &&
                                                mobileBanner !== null && (
                                                    <>
                                                        <div>
                                                            <img
                                                                alt="banner"
                                                                src={
                                                                    mobileBanner
                                                                }
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-4 mt-6 w-2/5 px-8">
                                <Button
                                    htmlType="submit"
                                    className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                    // loading={btn_loader}
                                >
                                    SAVE CHANGES
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                    onClick={goBack}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            </div>
            <ImageVariantModal
                open={image_modal}
                onCancel={toggleImageUploadModal}
                data={desktopBanner}
                centered
                onOk={uploadDesktopImage}
            />
            <ImageVariantModal
                open={imageMobile_modal}
                onCancel={toggleImageMobileUploadModal}
                data={mobileBanner}
                centered
                onOk={uploadMobileImage}
            />
        </div>
    );
};

export default BannerDetail;

const ImageVariantModal = ({ open, onCancel, data, ...restProps }) => {
    return (
        <>
            <Modal open={open} onCancel={() => onCancel(false)} {...restProps}>
                <div>Image Preview:-</div>
                {data && typeof data === 'object' ? (
                    <div>
                        <img
                            src={URL.createObjectURL(data)}
                            className="w-20 h-20"
                            alt="variant"
                        />
                    </div>
                ) : typeof data === 'string' ? (
                    <div>
                        <img
                            src={data} // If data is already a URL
                            className="w-20 h-20"
                            alt="variant"
                        />
                    </div>
                ) : null}
            </Modal>
        </>
    );
};
