import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from '../../scripts/api-services';
import {
    CREATE_FOLLOWUP,
    FOLLOWUP_JOURNEY_LIST,
    FOLLOWUP_LIST,
    UPDATE_CALL_STATUS,
} from '../../constant/api-constant';
import Alertify from '../../scripts/alertify';

export const createFollowUp = createAsyncThunk(
    'followup/createFollowUp',
    async ({ followUpDate, note, isCalled , orderId}, { dispatch, getState }) => {
        console.log("id",orderId)
        try {
            const doctorId = getState().auth_store.userId; // doctor's user id
            const userId = getState().followup_store.userId; // user id of the person doctor marked the timeline

            let reqData = {
                userId: userId,
                doctorId: doctorId,
                followUpDate: followUpDate,
                note: note,
                orderId: orderId
             
                // isCalled: isCalled,
            };
            console.log('reqData in redux store', reqData);
            const response = await postApi(CREATE_FOLLOWUP, reqData);
            Alertify.success('Followup created Uploaded !');
            dispatch(getFollowupList(userId));
            return response;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// get getFollowupJourneyList
export const getFollowupJourneyList = createAsyncThunk(
    'journey/getFollowupJourneyList',
    async (user_id, { dispatch, getState }) => {
        try {
            const { startDate, endDate } = getState().followup_store;
            let reqData = {};
            if (startDate && endDate) {
                reqData.startDate = startDate;
                reqData.endDate = endDate;
            }

            const response = await postApi(
                `${FOLLOWUP_JOURNEY_LIST}${user_id}`,
                reqData
            );
            return response;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// get getFollowupList
export const getFollowupList = createAsyncThunk(
    'journey/getFollowupList',
    async (user_id, { getState }) => {
        try {
            const { startDate, endDate } = getState().followup_store;
            let reqData = {};
            if (startDate && endDate) {
                reqData.startDate = startDate;
                reqData.endDate = endDate;
            }
            const response = await postApi(
                `${FOLLOWUP_LIST}${user_id}`,
                reqData
            );
            return response;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// updateCallStatus
export const updateCallStatus = createAsyncThunk(
    'journey/getFollowupJourneyList',
    async (user_id, { dispatch, getState }) => {
        try {
            const { startDate, endDate } = getState().followup_store;
            let reqData = {};
            if (startDate && endDate) {
                reqData.startDate = startDate;
                reqData.endDate = endDate;
            }

            const response = await postApi(
                `${UPDATE_CALL_STATUS}${user_id}`,
                reqData
            );
            return response;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);
