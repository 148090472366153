import React, { useEffect, useState } from 'react';
import { Tabs, ConfigProvider, Modal } from 'antd';
import { Link, useParams } from 'react-router-dom';
import {
    ArrowLeftOutlined,
    FieldTimeOutlined,
    HomeOutlined,
} from '@ant-design/icons';
import DetailsCustomer from '../../../components/userDetails/detailsCustomer';
import Prescription from '../../../components/userDetails/prescription';
import AddNewCustomer from '../../../components/userDetails/addNewCustomer';
import { useDispatch, useSelector } from 'react-redux';
import {
    CustomersAddress,
    CustomersDetails,
} from '../../../redux/actions/user-action';
import Timeline from '../../../components/userDetails/timeline';
import {
    getFollowupJourneyList,
    getFollowupList,
} from '../../../redux/actions/followup-action';
import { setFollowupUserId } from '../../../redux/slices/followup-slice';
import UserAddress from '../../../components/userDetails/userAddress';

// details of the customer to be sent to the DetailsCustomer component
const details = [
    {
        key: 1,
        label: 'ID',
        content: 'ABC123',
    },
    {
        key: 2,
        label: 'Customers Name',
        content: 'Sumit Kumar',
    },
    {
        key: 3,
        label: 'Phone Number',
        content: '+91-8844557788',
    },
    {
        key: 4,
        label: 'Email Address',
        content: 'sumitkumar@gmail.com',
    },
    {
        key: 5,
        label: 'Gender',
        content: 'Male',
    },
    {
        key: 6,
        label: 'City',
        content: 'Delhi',
    },
    {
        key: 7,
        label: 'Address',
        content: 'NA',
    },
    {
        key: 8,
        label: 'Created On',
        content: '20 Feb 2023',
    },
    {
        key: 9,
        label: 'Birthday',
        content: 'dd/mm/yyyy',
    },
    {
        key: 10,
        label: 'Engagement',
        content: 'dd/mm/yyyy',
    },
    {
        key: 11,
        label: 'Wedding',
        content: 'dd/mm/yyyy',
    },
];

// items for tabs
const items = [
    {
        key: '1',
        label: (
            <div className="flex items-center gap-2">
                <img
                    src="/images/users/user-icon.svg"
                    alt="icon"
                    className="h-[14px]"
                />
                Customer Details
            </div>
        ),
        children: (
            <DetailsCustomer dataSource={details} title="Customer Details" />
        ),
    },
    {
        key: '2',
        label: (
            <div className="flex items-center gap-2">
                <img
                    src="/images/users/prescription.svg"
                    alt="icon"
                    className="h-[14px]"
                />
                Prescription
            </div>
        ),
        children: <Prescription />,
    },
    {
        key: '3',
        label: (
            <div className="flex items-center gap-2">
                <FieldTimeOutlined className="text-12" />
                Timeline
            </div>
        ),
        children: <Timeline />,
    },
    {
        key: '4',
        label: (
            <div className="flex items-center gap-2">
                <HomeOutlined className="text-12" />
                Addresses
            </div>
        ),
        children: <UserAddress />,
    },
];

// details for customer

const CustomerDetails = () => {
    const [activeTabKey, setActiveTabKey] = useState(
        localStorage.getItem('activeTabKey') || '3'
    );

    // onChange for tabs
    const onChange = (key) => {
        setActiveTabKey(key);
    };

    const { id } = useParams();
    const dispatch = useDispatch();
    const customerData = useSelector(
        (state) => state.customer_store.singleCustomerDetails
    );

    const { startDate, endDate } = useSelector((state) => state.followup_store);

    useEffect(() => {
        dispatch(setFollowupUserId(id));
        dispatch(CustomersDetails(id));
        dispatch(CustomersAddress(id));
    }, [id]);

    useEffect(() => {
        dispatch(getFollowupJourneyList(id));
        dispatch(getFollowupList(id));
    }, [id, startDate, endDate]);

    function goBack() {
        window.history.back();
    }

    const [addNewModal, setAddNewModal] = useState(false);
    const showAddNew = () => {
        setAddNewModal(!addNewModal);
    };
    const closeModal = () => {
        setAddNewModal(false);
    };

    return (
        <>
            <div className="bg-white rounded-[6px]">
                <div className="flex items-center gap-4 p-4 border-b-1">
                    <Link to="#">
                        <ArrowLeftOutlined onClick={goBack} />
                    </Link>
                    <h1 className="table-name">
                        {activeTabKey === '1' ? (
                            <div className="flex items-center gap-3">
                                Customer Details
                                {/* <img
                                    onClick={showAddNew}
                                    src="/icons/common/edit.svg"
                                    className="h-[20px] cursor-pointer"
                                    alt="Edit"
                                /> */}
                            </div>
                        ) : activeTabKey === '2' ? (
                            <>Prescription</>
                        ) : (
                            <>Timeline</>
                        )}
                    </h1>
                </div>

                <ConfigProvider
                    theme={{
                        components: {
                            Tabs: {
                                itemSelectedColor: '#1A3353',
                                itemColor: '#455560',
                                inkBarColor: '#1A3353',
                                itemHoverColor: '#5f5a5a',
                                colorBgContainer: '#E5E5E5',
                                padding: '40',
                            },
                        },
                    }}
                >
                    <Tabs
                        type="card"
                        tabPosition="left"
                        defaultActiveKey={activeTabKey}
                        items={items}
                        onChange={onChange}
                        className="details-tab"
                    />
                    <Modal
                        centered
                        open={addNewModal}
                        footer={false}
                        onCancel={closeModal}
                        width={1000}
                    >
                        <div className="w-full h-full p-16">
                            <AddNewCustomer customerData={customerData} />
                        </div>
                    </Modal>
                </ConfigProvider>
            </div>
        </>
    );
};

export default CustomerDetails;
