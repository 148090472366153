/*--------------Customer List --------------------*/

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ADD_NEW_SUB_ATTRIBUTES,
    ATTRIBUTES_LIST,
    ATTRIBUTES_VALUE_LIST,
    ATTRIBUTE_EXPORT,
    DELETE_SUB_ATTRIBUTES,
    IS_TOP_BRAND,
    SUB_ATTRIBUTES_LIST,
    UPDATE_SUB_ATTRIBUTES,
} from '../../constant/api-constant';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import { Link } from 'react-router-dom';
import Alertify from '../../scripts/alertify';

/*------------------Get Attrributes List-------------*/

export const AttributeListData = createAsyncThunk(
    'attributes/attributes-list',
    async ({ search, page = 1, pageSize = 10 }) => {
        try {
            const response = await getApi(ATTRIBUTES_LIST);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

/*------------------Get Sub Attrributes List-------------*/

export const SubAttributeList = createAsyncThunk(
    'attributes/sub-attributes-list',
    async ({ search, page = 1, pageSize = 10, attribute }) => {
        try {
            const response = await postApi(SUB_ATTRIBUTES_LIST, {
                search,
                page,
                pageSize,
                attribute,
            });
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

/*------------------Get Attrributes Value List-------------*/

export const AttributeValueListData = createAsyncThunk(
    'attributes/attributes-value-list',
    async (id) => {
        try {
            const response = await getApi(`${ATTRIBUTES_VALUE_LIST}/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

/*------------------Create Categories-------------*/

export const CreateAttribute = createAsyncThunk(
    'attribute/create-attribute',
    async (formData) => {
        try {
            console.log('Data-------------', formData);
            const response = await postApi(ADD_NEW_SUB_ATTRIBUTES, formData);
            // console.log("Response-----------", response)
            let attribute = formData.get('attribute');
            Alertify.success(`${attribute} created successfully`);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

/*------------------Update Categories-------------*/

export const UpdateAttribute = createAsyncThunk(
    'attribute/update-attribute',
    async ({ formData, id }) => {
        try {
            console.log('Data-------------', formData, id);
            const response = await patchApi(
                `${UPDATE_SUB_ATTRIBUTES}/${id}`,
                formData
            );
            console.log('Response-----------', response);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

/*------------------ top brand -------------*/

export const isTopBrand = createAsyncThunk(
    'attribute/isTopBrand',
    async ({ status, storeId }) => {
        try {
            const reqData = {
                isTopBrand: status,
                id: [storeId],
            };
            const response = await postApi(IS_TOP_BRAND, reqData);
            return response;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);
/*-------------- Delete Sub Attributes Details --------------------*/

export const DeleteSubAttribute = createAsyncThunk(
    'DeleteSubAttribute',
    async ({ id, attribute }, { dispatch }) => {
        try {
            const response = await deleteApi(`${DELETE_SUB_ATTRIBUTES}/${id}`);
            Alertify.success('sub-attribute deleted successfully');
            await dispatch(SubAttributeList({ attribute }));
            return response.data;
        } catch (error) {
            console.error('Error:', error);

            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*------------------Manipulate Attribute list-------------*/

export function ManipulateAttributesLists(data) {
    return Object.entries(data)?.map(([key, value]) => ({
        key: key,
        name: value.name,
        slug: key || '-',
        action: (
            <span className="flex gap-2">
                <Link to={`/attributes/sub-attribute-list/${key}`}>
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                {/* <img src="/icons/common/delete.svg" alt="delete" className="h-[20px]" /> */}
            </span>
        ),
    }));
}

export const AttributeExport = createAsyncThunk(
    'attributeExport',
    async ({ attribute }) => {
        try {
            const res = await postApi(ATTRIBUTE_EXPORT, {
                attribute: [attribute],
            });
            Alertify.success('Attribute exported successfully');
            return res.data;
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*------------------Manipulate Attribute list-------------*/

export function ManipulateSubAttributesLists(data) {
    return data?.map((item) => ({
        key: item._id,
        name: item.value,
        isTopBrand: item.isTopBrand,
        slug: item.slug,
        createdOn: Date.now(),
        image: item.image || '',
        hexCode: item.hexCode,
    }));
}
