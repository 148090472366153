import React, { useEffect } from 'react';
import SimpleTable from '../tables/simpletable';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomersAddress } from '../../redux/actions/user-action';
import { ConfigProvider, Switch } from 'antd';

const UserAddress = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const customer_address = useSelector(
        (state) => state.customer_store.customer_addresses
    );

    useEffect(() => {
        dispatch(CustomersAddress(id));
    }, []);
    const columns = [
        { title: 'ADDRESS TYPE', dataIndex: 'addressType' },
        {
            title: 'ADDRESS ',
            dataIndex: 'address',
        },
        { title: 'MOBILE', dataIndex: 'mobile' },
        {
            title: 'DEFAULT',
            dataIndex: 'isDefault',
            render: (_, record) => (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimaryBorder: '#6E3362',
                            colorPrimary: '#6E3362',
                        },
                    }}
                >
                    <Switch
                        checked={record.isDefault}
                        className="flex self-center"
                        // onClick={() => toggleStatus(record.id, !record.status)}
                        // style={{ border: "1px solid #6E3362" }}
                    />
                </ConfigProvider>
            ),
        },
    ];

    return (
        <>
            <SimpleTable
                tableName="Customer Address"
                // search={true}
                columns={columns}
                dataSource={customer_address}
            />
        </>
    );
};

export default UserAddress;
