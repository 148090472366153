import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CustomersList from '../screens/users/customers/customers';
import OrdersList from '../screens/orders/order-list/orders';
import CustomerDetails from '../screens/users/customers/customerDetails';
import CategoryListing from '../screens/categories/categoriesList/categoryListing';
import EditCategory from '../screens/categories/editCategory/editCategory';
import AttributeListing from '../screens/attributes/attributesListing/attributesListing';
import AddNewAttribute from '../screens/attributes/attributesListing/addNewAttribute';

import SubAttributesListing from '../screens/attributes/subAttributes/subAttributesListing';
import AddNewSubAttribute from '../screens/attributes/subAttributes/addNewSubAttribute';
import AdminDetails from '../screens/users/customers/admin/adminDetails';
import DoctorDetails from '../screens/users/customers/doctor/doctorDetails';
import ProductListing from '../screens/products/productListing';
import AddNewProduct from '../screens/products/addNewProduct';
import OrderDetails from '../screens/orders/orderDetails/orderDetails';
import BannerListing from '../screens/banners/bannerListing';
import BannerDetail from '../screens/banners/bannerDetail';
import CrazyDealsListing from '../screens/crazyDeals/CrazyDealsListing';
import CrazyDealsDetail from '../screens/crazyDeals/CrazyDealsDetail';
import StoreListing from '../screens/store/StoreListing';
import StoreDetails from '../screens/store/StoreDetails';
import { useDispatch } from 'react-redux';

import { AttributeListData } from '../redux/actions/attribute-action';
import BlogsListing from '../screens/blogs/blogsListing';
import ReviewListing from '../screens/reviews/reviewListing';
import ReviewDetails from '../screens/reviews/reviewDetails';
import BlogDetails from '../screens/blogs/blogDetails';
import {
    GetBrandsData,
    GetPrimaryCategories,
} from '../redux/actions/container-actions';
import DeliveryPartnerDetails from '../screens/users/customers/deliveryPartner/deliveryPartnerDetails';
import useAppSelector from '../hooks/useAppSelector';
import { SECURE_ROUTES } from './secure-routes';
import PageNotFound from '../screens/404';

import { ROLES } from '../constant/roles-constant';
import Profile from '../screens/profile/profile';
import Logs from '../screens/products/logs';
import Dashboard from '../screens/dashboard/dashboard';

function PagesRoutes() {
    const location = useLocation();
    const store = useAppSelector((state) => ({ role: state.auth_store.role }));
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/users');
        }
        //! container actions call
        dispatch(AttributeListData({}));
        dispatch(GetPrimaryCategories({}));
        dispatch(GetBrandsData());
    }, []);

    useEffect(() => {
        // if(store.role === ROLES.Doctor){
        //     if (!SECURE_ROUTES[ROLES.Doctor].includes(location.pathname)) { // removed not in front of secure routes
        //         console.log(SECURE_ROUTES[ROLES.Doctor]?.includes(location.pathname));
        //         console.log(SECURE_ROUTES[ROLES.Doctor])
        //         navigate('/404');
        //     }
        // }
        if (store.role === ROLES.PHRAMA) {
            console.log('entered in pharma');
            if (!SECURE_ROUTES[ROLES.PHRAMA].includes(location.pathname)) {
                navigate('/404');
            }
        } else if (store.role === ROLES.Delivery) {
            if (!SECURE_ROUTES[ROLES.Delivery].includes(location.pathname)) {
                navigate('/404');
            }
        }
    }, []);

    return (
        <>
            <Routes>
                <Route path="/users" element={<CustomersList />} />
                <Route path="/orders" element={<OrdersList />} />
                <Route
                    path="/orders/order-details/:_id"
                    element={<OrderDetails />}
                />
                <Route
                    path="/users/user-details/:id"
                    element={<CustomerDetails />}
                />
                <Route path="/users/admin-details" element={<AdminDetails />} />
                <Route
                    path="/users/doctor-details/:_id"
                    element={<DoctorDetails />}
                />
                <Route path="/categories" element={<CategoryListing />} />
                <Route
                    path="/categories/edit-category/:id"
                    element={<EditCategory />}
                />
                <Route path="/attributes" element={<AttributeListing />} />
                <Route path="/edit-attribute" element={<AddNewAttribute />} />
                <Route
                    path="/attributes/sub-attribute-list/:key"
                    element={<SubAttributesListing />}
                />
                <Route
                    path="/attributes/sub-attribute-list/edit-sub-attribute/:id/:attribute_id?"
                    element={<AddNewSubAttribute />}
                />
                <Route path="/products" element={<ProductListing />} />
                <Route
                    path="/products/product-detail/:id"
                    element={<AddNewProduct />}
                />
                <Route path="/banners" element={<BannerListing />} />
                <Route
                    path="/banners/banner-detail/:id"
                    element={<BannerDetail />}
                />
                <Route path="/crazy-deals" element={<CrazyDealsListing />} />
                <Route
                    path="/crazy-deals/crazy-deals-detail/:id"
                    element={<CrazyDealsDetail />}
                />
                <Route path="/stores" element={<StoreListing />} />
                <Route
                    path="/stores/store-detail/:id?"
                    element={<StoreDetails />}
                />
                <Route path="/blogs" element={<BlogsListing />} />
                <Route path="/blogs/blogs-detail" element={<BlogDetails />} />
                <Route path="/reviews" element={<ReviewListing />} />
                <Route
                    path="/reviews/review-details/:id"
                    element={<ReviewDetails />}
                />
                <Route
                    path="/users/delivery-partner-details"
                    element={<DeliveryPartnerDetails />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                {/* 👇️ only match this when no other routes match */}
                <Route path="*" element={<PageNotFound />} />
                <Route path="/profile" element={<Profile />} />

                <Route path="/products/logs" element={<Logs />} />
            </Routes>
        </>
    );
}

export default PagesRoutes;
