import React, { useEffect, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import { ConfigProvider, Pagination, Switch } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    DeleteReview,
    EditReview,
    GetReviewList,
} from '../../redux/actions/review-action';

const columns = [
    {
        title: 'PRODUCT NAME',
        dataIndex: 'productName',
        width: '20%',
    },
    {
        title: 'USER NAME',
        dataIndex: 'userName',
        width: '15%',
    },
    {
        title: 'REVIEW',
        dataIndex: 'review',
        width: '20%',
    },
    {
        title: 'RATINGS',
        dataIndex: 'ratings',
        align: 'center',
    },
    {
        title: 'DATE',
        dataIndex: 'date',
    },
    // {
    //     title: 'STATUS',
    //     dataIndex: 'status',
    // },
    // {
    //     title: '',
    //     dataIndex: 'action',
    //     width: '90px',
    // },
];

const dataSource = [
    {
        key: 1,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 2,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 3,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet',
        ratings: 1,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 4,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 5,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 6,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet',
        ratings: 1,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 7,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 8,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 9,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet',
        ratings: 1,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 10,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 11,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        ratings: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 12,
        productName: 'The Face Shop Rice & Ceramide Moisturizing Cream',
        userName: 'Sumit',
        review: 'Lorem ipsum dolor sit amet',
        ratings: 1,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/reviews/review-details">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
];

// slice review to 50 character limit
const reviewModified = dataSource.map((item) => ({
    ...item,
    review:
        item.review.length > 50
            ? item.review.slice(0, 50) + '...'
            : item.review,
}));

const ReviewListing = () => {
    const [loading, setLoading] = useState(true);
    const [searchP] = useSearchParams();

    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const dispatch = useDispatch();
    const reviewList = useSelector((state) => state.review_store.reviewList);
    const reviewList_count = useSelector(
        (state) => state.review_store.reviewList_count
    );

    const navigate = useNavigate();

    // console.log('Review list--------------', reviewList);

    useEffect(() => {
        dispatch(GetReviewList({ page: Number(currentPage), pageSize: Number(pageSize) })).then(
            (res) => {
                setLoading(false);
            }
        );
    }, [currentPage, pageSize]);

    const action = [
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (_, record) => (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimaryBorder: '#6E3362',
                            colorPrimary: '#6E3362',
                        },
                    }}
                >
                    <Switch
                        checked={record.status}
                        className="flex self-center"
                        onClick={() => toggleStatus(record.key, !record.status)}
                        // style={{ border: "1px solid #6E3362" }}
                    />
                </ConfigProvider>
            ),
        },
        {
            title: 'ACTIONS',
            dataIndex: 'action',
            render: (_, record) => (
                <span className="flex gap-4">
                    <Link to={`/reviews/review-details/${record.key}`}>
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px]"
                        />
                    </Link>
                    <div onClick={() => deleteBanner(record.key)}>
                        <img
                            src="/icons/common/delete.svg"
                            alt="delete"
                            className="h-[20px] cursor-pointer"
                        />
                    </div>
                </span>
            ),
        },
    ];

    const all_columns = [...columns, ...action];

    function paginate(page, pageSize) {
        setCurrentPage(page);
        setPageSize(pageSize);
        navigate(`?page=${page}&pageSize=${pageSize}`, { replace: true });
    }

    function deleteBanner(id) {
        dispatch(DeleteReview({ id })).then((res) => {
            setCurrentPage(1);
        });
    }

    function toggleStatus(id, status) {
        alert('are you sure want to change the status');
        dispatch(EditReview({ id, payload: { status } })).then((res) => {
            // navigate('/reviews')
            setCurrentPage(1);
        });
    }

    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <SimpleTable
                tableName="Review Approval"
                addNew={false}
                addNewLink="/reviews/review-details/0"
                actionSelect={true}
                export={true}
                columns={all_columns}
                dataSource={reviewList}
                loading={loading}
            />
            <div className="flex justify-center  py-10">
                <Pagination
                    current={currentPage}
                    total={reviewList_count}
                    pageSize={pageSize}
                    onChange={paginate}
                    hideOnSinglePage
                    pageSizeOptions={[10, 20, 50]}
                />
            </div>
        </div>
    );
};

export default ReviewListing;
