// ModalComponent.js
import React, { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { ProductExport } from '../../redux/actions/product-action';
import { SetCategoryId } from '../../redux/slices/product-slice';
import { AttributeExport } from '../../redux/actions/attribute-action';
import { CategoryExport } from '../../redux/actions/category-action';

const ExportModal = (props) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('')

    const getSubCategory = (value) => {
        dispatch(SetCategoryId(value))
        setValue(value)
    };

    const handleDownload = () => {
        if (props.relatedComponent === "product") {
            dispatch(ProductExport({ firstCategoryId: value })).then((res) => {
                props.onClose();
                setValue('');
            })
        } else if (props.relatedComponent === "attribute") {
            dispatch(AttributeExport({ attribute: value })).then((res) => {
                props.onClose();
                setValue('');
            })
        }
        else if (props.relatedComponent === "category") {
            dispatch(CategoryExport({ level: value })).then((res) => {
                props.onClose();
                setValue('');
            })
        }
    }

    const handleCancel = () => {
        props.onClose();
        setValue('');
    };

    return (
        <Modal
            title="Select Options"
            visible={props.visible}
            onCancel={handleCancel}
            footer={null}
        >
            <div>

                {props.relatedComponent === "product" && (
                    <div>
                        <Select
                            className="w-full h-[40px]"
                            placeholder="Select Category"
                            // onChange={getSubCategory}
                            value={value || 'Select Category'}
                            onChange={
                                getSubCategory
                            }
                            options={
                                props.parentCategoryOptions
                            }
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </div>
                )}

                {props.relatedComponent === "attribute" && (
                    <div>
                        <Select
                            className="w-full h-[40px]"
                            placeholder="Select Attribute"
                            // onChange={getSubCategory}
                            value={value || "Select Attribute"}
                            onChange={
                                getSubCategory
                            }
                            options={
                                props.attributeOption
                            }
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </div>
                )}

                {props.relatedComponent === "category" && (
                    <div>
                        <Select
                            className="w-full h-[40px]"
                            placeholder="Select Category Level"
                            // onChange={getSubCategory}
                            value={value || "Select Category Level"}
                            onChange={
                                getSubCategory
                            }
                            options={[
                                {
                                    value: 'first',
                                    label: 'First',
                                },
                                {
                                    value: 'second',
                                    label: 'Second',
                                },
                                {
                                    value: 'third',
                                    label: 'Third',
                                },
                            ]}
                            showSearch
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </div>
                )}

                <div className='flex justify-end mt-10'>
                    <button
                        onClick={handleDownload}
                        className="py-2 mr-3 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                    >
                        Download
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ExportModal;
