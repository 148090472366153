import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { Button, Form, Input } from "antd";

const AddNewAttribute = (props) => {
  const [form] = Form.useForm();

  function goBack() {
    window.history.back();
  }


  // const handleSubmit = () => {
  //   form
  //     .validateFields()
  //     .then((values) => {
  //       const { name } = values;
  //       console.log("Name----------", name);
  //       dispatch(CreateAttribute(attribute, name, file.selectedFile));
  //       form.resetFields();
  //       setFile({ selectedFile: null });
  //       setMsg("");
  //     })
  //     .catch((errorInfo) => {
  //       console.log("Validation failed:", errorInfo);
  //     });
  // };

  return (
    <>
      <div className="bg-white pb-2 rounded-[6px]">
        <div className="flex p-4 items-center justify-between border-b-1">
          <div className="flex gap-4 items-center">
            <ArrowLeftOutlined onClick={goBack} />
            {props.title ? (
              <div className="table-name">Edit Attribute</div>
            ) : (
              <div className="table-name">Add New Attribute</div>
            )}
          </div>
        </div>
        <div className="rounded-[6px] border-1 p-8 m-2">
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="md:pr-48"
          >
            <div className="flex gap-8 mt-6">
              <Form.Item
                className="w-1/2"
                name="name"
                label={<label style={{ color: "#1A3353" }}>Name *</label>}
              >
                <Input placeholder="Attribute Name" className="h-[40px]" />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                name="name"
                label={<label style={{ color: "#1A3353" }}>Slug *</label>}
              >
                <Input placeholder="slug" className="h-[40px]" />
              </Form.Item>
            </div>

            <div className="flex gap-4 mt-6 w-2/5">
              <Button
                htmlType="submit"
                className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
              >
                SAVE CHANGES
              </Button>
              <Button
                htmlType="reset"
                className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                onClick={goBack}
              >
                CANCEL
              </Button>
            </div>
          </Form>
        </div>

      </div>
    </>
  );
};

export default AddNewAttribute;
