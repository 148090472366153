import { createSlice, current } from '@reduxjs/toolkit';
import {
    CategoryListData,
    CreateCategory,
    GetCategoryDetail,
    GetFilterList,
    ManipulateCategoryLists,
    ManipulateFiltersList,
    PrimaryCategoryListData,
} from '../actions/category-action';
import { AttributeListData } from '../actions/attribute-action';

const initialState = {
    is_login: false,
    categoryList: [],
    primaryCategory: [],
    secondCategory: [],
    thirdCategory: [],
    search: '',
    category_count: 0,
    attributes_list: [],
    filters_list: [],
    attributes_object: {},
    primaryCategoryOptions: [],
    secondCategoryOptions: [],
    categoryFilterValue: '',
    featuredCategoryValue: '',
};

const categorySlice = createSlice({
    name: 'category-slice',
    initialState,
    reducers: {
        SetCategorySearch: (state, { payload }) => {
            state['search'] = payload.value;
        },
        SetCategoryData: (state, { payload }) => {
            state['search'] = payload.value;
        },
        SetCategoryFilter: (state, { payload }) => {
            state.categoryFilterValue = payload;
        },
        SetFeaturedCategoryFilter: (state, { payload }) => {
            state.featuredCategoryValue = payload;
        },
        ClearSecondCategory: (state,) => {
            state.secondCategory = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(CategoryListData.fulfilled, (state, { payload }) => {
            console.log("Category paylaod----------", payload)
            if (payload.level === 'first') {
                state.primaryCategory = payload.res?.data?.data?.list;
            } else if (payload.level === 'second') {
                state.secondCategory = payload.res?.data?.data?.list;
            } else if (payload.level === 'third') {
                state.thirdCategory = payload.res?.data?.data?.list;
            }
            state.categoryList = ManipulateCategoryLists(
                payload.res.data.data.list
            );
            state.category_count = payload.res.data.data.count;
        });
        builder.addCase(
            PrimaryCategoryListData.fulfilled,
            (state, { payload }) => {
                state.primaryCategory = payload.res.data.data?.list;
                if (payload.level === 'first') {
                    state.primaryCategoryOptions =
                        payload.res.data.data?.list?.map((item) => ({
                            value: item._id,
                            label: item.name,
                            id: item._id,
                        }));
                } else if (payload.level === 'second') {
                    state.secondCategoryOptions =
                        payload.res.data.data?.list?.map((item) => ({
                            value: item._id,
                            label: item.name,
                            id: item._id,
                        }));
                }
            }
        );
        builder.addCase(CreateCategory.fulfilled, (state, { payload }) => {
            state.categoryList = payload.data?.list;
            state.category_count = payload.data?.count;
        });
        builder.addCase(AttributeListData.fulfilled, (state, { payload }) => {
            if (Object.keys(payload).length > 0) {
                const data = { ...payload };
                delete data.brand;
                const attributes = Object.entries(data).map(([key, value]) => ({
                    label: value.name,
                    value: key,
                }));
                const attributes_object = attributes.reduce((result, item) => {
                    result[item.value] = item.label;
                    return result;
                }, {});
                state.attributes_list = attributes;
                state.attributes_object = attributes_object;
            }
        });
        builder.addCase(GetFilterList.fulfilled, (state, { payload }) => {
            const attributes = current(state.attributes_object);
            state.filters_list = ManipulateFiltersList(
                payload.res.data?.data?.list,
                attributes
            );
        });
    },
});

export const {
    SetCategorySearch,
    SetCategoryData,
    SetCategoryFilter,
    SetFeaturedCategoryFilter,
    ClearSecondCategory
} = categorySlice.actions;

export default categorySlice.reducer;
