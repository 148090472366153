import React, { useEffect, useState } from 'react';
import SimpleTable from '../../../components/tables/simpletable';
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    DeleteSubAttribute,
    SubAttributeList,
} from '../../../redux/actions/attribute-action';
import { Pagination } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Alertify from '../../../scripts/alertify';
import toast from 'react-hot-toast';
import {
    ClearAttributesSearch,
    SelectedAttribute,
    SetAttributeName,
    SetAttributesSearch,
} from '../../../redux/slices/attribute-slice';
import Topbar from '../../../components/topbar/topbar';
import SearchBar from '../../../components/topbar/searchbar';

const columns = [
    {
        title: 'NAME',
        dataIndex: 'name',
        width: '30%',
    },
    {
        title: 'SLUGS',
        dataIndex: 'slug',
        width: '50%',
    },
    {
        title: 'CREATED ON',
        dataIndex: 'createdOn',
        width: '50%',
    },
];

const SubAttributesListing = () => {
    const { key } = useParams();
    const attribute = key;
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const subAttributeList = useSelector(
        (state) => state.attribute_store.subAttributeList
    );
    const search = useSelector((state) => state.attribute_store.search);
    const sub_attribute_count = useSelector(
        (state) => state.attribute_store.sub_attribute_count
    );

    const [searchP] = useSearchParams();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);

    const handleSearch = (key, value) => {
        // console.log("Kay-----", key, "value-------", value);
        dispatch(SetAttributesSearch({ key, value }));
    };

    // useEffect(() => {
    //     dispatch(SubAttributeList({ search, attribute })).then((res) => {
    //         setLoading(false);
    //         // dispatch(SetAttributeName(attribute))
    //         localStorage.setItem('attributeName', attribute);
    //     });
    // }, [search]);

    function getAttributesLists(key, value, page, pageSize) {
        dispatch(
            SubAttributeList({ key, value, page, pageSize, attribute })
        ).then(() => setLoading(false));
    }

    const paginate = (pageNumber, pageSize) => {
        setCurrentPage(pageNumber);
        setPageSize(pageSize);
        navigate(`?page=${pageNumber}&pageSize=${pageSize}`,{replace:true});
    };

    function deleteSubAttribute(id) {
        dispatch(DeleteSubAttribute({ id: id.trim(), attribute }));
    }

    useEffect(() => {
        dispatch(
            SubAttributeList({
                page: Number(currentPage),
                pageSize: Number(pageSize),
                attribute,
                search,
            })
        ).then((res) =>{
            setLoading(false)
            localStorage.setItem('attributeName', attribute);
        })
    }, [currentPage, pageSize,search,]);

    function goBack() {
        window.history.back();
    }

    function selectAttribute(record) {
        // console.log(record);
        dispatch(SelectedAttribute(record));
    }

    const selectColumn = {
        title: 'ACTIONS',
        dataIndex: 'action',
        width: '90px',
        key: 'action',
        render: (id, record) => {
            // console.log('Record: ', record);
            return (
                <>
                    <span className="flex gap-2">
                        <Link
                            to={`/attributes/sub-attribute-list/edit-sub-attribute/${attribute}/${record.key}`}
                            // onClick={() => selectAttribute(record)}
                        >
                            <img
                                src="/icons/common/edit.svg"
                                alt="edit"
                                className="h-[20px]"
                            />
                        </Link>
                        <div
                            className="cursor-pointer"
                            onClick={() => deleteSubAttribute(record.key)}
                        >
                            <img
                                src="/icons/common/delete.svg"
                                alt="delete"
                                className="h-[20px]"
                            />
                        </div>
                    </span>
                </>
            );
        },
    };

    const combinedColumns = [...columns, selectColumn];

    return (
        <>
            {/* <ArrowLeftOutlined onClick={goBack} /> */}
            {/* {attribute === 'brand' && (
                <SearchBar
                    onSearch={handleSearch}
                    placeholder={'Search Brand'}
                />
            )} */}

            <SearchBar
                onSearch={handleSearch}
                placeholder={`Search ${attribute}`}
            />

            <SimpleTable
                tableName="Sub Attributes"
                goBack={true}
                addNewLink={`/attributes/sub-attribute-list/edit-sub-attribute/${attribute}/0`}
                addNew={true}
                filterByCategory={false}
                columns={combinedColumns}
                dataSource={subAttributeList}
                loading={loading}
            />
            <div className="flex justify-center  py-10">
                {' '}
                <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={sub_attribute_count}
                    // onChange={(page, size) =>
                    //     getAttributesLists(null, null, page, size)
                    // }
                    onChange={paginate}
                    hideOnSinglePage
                    pageSizeOptions={[10, 20, 50]}
                />{' '}
            </div>
        </>
    );
};

export default SubAttributesListing;
