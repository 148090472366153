import React, { useEffect, useState } from 'react';
import SimpleTable from '../tables/simpletable';
import { Button, Modal } from 'antd';
import UploadPrescription from './uploadPrescription';
import PrescriptionDetails from './prescriptionDetails';
import { getPrescriptionByUser } from '../../redux/actions/prescription-action';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Prescription = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const getUserPrescription = useSelector(
        (state) => state?.prescription_store?.getUserPrescription
    );

    const userData = getUserPrescription.data;
    const [uploadSection, setUploadSection] = useState(true);
    const [modalImage, setModalImage] = useState();

    const toggleDiv = (image) => {
        setUploadSection(!uploadSection);
        setModalImage(image);
    };

    const [uploadModal, setUploadModal] = useState(false);
    const showUploadModal = () => {
        setUploadModal(!uploadModal);
    };
    const closeModal = () => {
        setUploadModal(false);
    };

    useEffect(() => {
        dispatch(getPrescriptionByUser(id));
    }, []);
    const columns = [
        {
            title: 'INDENT NO',
            dataIndex: 'prescriptionImage',
            render: (text, record, index) => (
                <p
                    className="text-[#5757f0] cursor-pointer"
                    onClick={() => toggleDiv(record.prescriptionImage)}
                >
                    {index + 1}
                </p>
            ), // Displaying index + 1 (1-indexed) or index (0-indexed) depending on your preference
        },
        {
            title: 'FILE NAME',
            dataIndex: 'prescriptionImage',
            render: (prescriptionImage) => {
                const trimmedUrl = prescriptionImage.replace(
                    'https://aakash-life-web.s3.ap-south-1.amazonaws.com/prescription/',
                    ''
                );
                return (
                    <p
                        className="text-[#5757f0] cursor-pointer"
                        onClick={() => toggleDiv(prescriptionImage)}
                    >
                        {trimmedUrl}
                    </p>
                );
            },
        },
        { title: 'PRESCRIPTION NAME', dataIndex: 'name' },
    ];

    return (
        <>
            {uploadSection ? (
                <SimpleTable
                    tableName="Indent Request"
                    showUploadModal={showUploadModal}
                    uploadPrescription={false}
                    uploadPrescriptionLink="/"
                    search={true}
                    columns={columns}
                    dataSource={userData}
                />
            ) : (
                <PrescriptionDetails
                    toggleDiv={toggleDiv}
                    modalImage={modalImage}
                />
            )}

            <Modal
                centered
                open={uploadModal}
                footer={false}
                onCancel={closeModal}
                className=""
            >
                <UploadPrescription />
            </Modal>
        </>
    );
};

export default Prescription;
