import { createSlice } from '@reduxjs/toolkit';

import {
    getFollowupJourneyList,
    getFollowupList,
} from '../actions/followup-action';

const initialState = {
    followupJourneyData: [],
    followupData: [],
    userId: '',
    startDate: '',
    endDate: '',
};

const followUpSlice = createSlice({
    name: 'followup_store',
    initialState,
    reducers: {
        setFollowupUserId: (state, action) => {
            state.userId = action.payload;
        },
        setFollowUpStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setFollowUpEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getFollowupJourneyList.fulfilled,
            (state, { payload }) => {
                state.followupJourneyData = payload?.data?.data?.list;
            }
        );
        builder.addCase(getFollowupList.fulfilled, (state, { payload }) => {
            state.followupData = payload?.data?.data?.list;
        });
    },
});

export const { setFollowupUserId, setFollowUpStartDate, setFollowUpEndDate } =
    followUpSlice.actions;
export default followUpSlice.reducer;
