import { Modal } from 'antd';

export const DeleteModal = ({
    header = 'Delete Product',
    text = 'Are you sure want to delete the product',
    visible,
    onCancel,
    onDelete,
}) => {
    return (
        <>
            <Modal
                title={header}
                className="text-20"
                open={visible}
                onCancel={onCancel}
                footer={false}
            >
                <div className="flex flex-col gap-8">
                    <div className="text-center text-18">{text}</div>
                    <div className="flex flex-row justify-end gap-4 font-medium">
                        <button
                            onClick={onDelete}
                            className="rounded-2xl bg-red-400 p-4 text-white"
                        >
                            Delete
                        </button>
                        <button
                            onClick={onCancel}
                            className="rounded-2xl border p-4 text-black"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
