import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';
import {
    CREATE_CRAZY_DEALS,
    DELETE_CRAZY_DEALS,
    GET_CRAZY_DEALS,
    GET_DETAILS_CRAZY_DEALS,
    UPDATE_CRAZY_DEALS,
} from '../../constant/api-constant';

export const GetCrazyDeals = createAsyncThunk(
    'GetCrazyDeals',
    async ({ page = 1, pageSize = 10 }) => {
        try {
            const res = await postApi(GET_CRAZY_DEALS, {
                page,
                pageSize,
            });
            // _ManipulateCrazyDeals;
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const DeleteDeal = createAsyncThunk(
    'DeleteDeal',
    async ({ id }, { dispatch }) => {
        try {
            const res = await deleteApi(`${DELETE_CRAZY_DEALS}/${id}`);
            dispatch(GetCrazyDeals({}));
            Alertify.success('Successfully deleted');
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const EditCrazyDeals = createAsyncThunk(
    'EditCrazyDeals',
    async ({ id, payload }, { dispatch }) => {
        try {
            const res = await patchApi(`${UPDATE_CRAZY_DEALS}/${id}`, {
                ...payload,
            });
            Alertify.success('Updated successfully');
            dispatch(GetCrazyDeals({}));
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const GetCrazyDealsDetails = createAsyncThunk(
    'GetCrazyDealsDetails',
    async ({ id }) => {
        try {
            const res = await getApi(`${GET_DETAILS_CRAZY_DEALS}/${id}`);
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const CreateCrazyDeals = createAsyncThunk(
    'CreateCrazyDeals',
    async ({ payload }) => {
        try {
            const res = await postApi(CREATE_CRAZY_DEALS, {
                ...payload,
                status: true,
            });
            Alertify.success('Successfully created a deal');
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);
