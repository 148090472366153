import React, { useEffect, useState } from 'react';
import SimpleTable from '../../../components/tables/simpletable';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeListData } from '../../../redux/actions/attribute-action';
import { Pagination } from 'antd';
import { SetAttributesSearch } from '../../../redux/slices/attribute-slice';
import SearchBar from '../../../components/topbar/searchbar';
import { useDebounce } from '../../../hooks/useDebounce';

const columns = [
    {
        title: 'NAME',
        dataIndex: 'name',
        width: '30%',
    },
    {
        title: 'SLUG',
        dataIndex: 'slug',
        width: '50%',
    },
    {
        title: '',
        dataIndex: 'action',
        width: '90px',
    },
];

const AttributeListing = () => {
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    // const [currentPage, setCurrentPage] = useState(1);
    const attributeList = useSelector(
        (state) => state.attribute_store.attributeList
    );
    const [filterSearch, setFilterSearch] = useState(attributeList);
    const search = useSelector((state) => state.attribute_store.search);
    const attribute_count = useSelector(
        (state) => state.attribute_store.attribute_count
    );
    // const pageSize = 10;
    const [searchP] = useSearchParams();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);

    const debouncedRequest = useDebounce((callback) => callback(), 4000);

    useEffect(() => {
        if (attributeList) {
            setFilterSearch(attributeList);
        }
    }, [attributeList]);

    const handleSearch = (key, value) => {
        const filteredData = attributeList?.filter((item) =>
            item?.name?.toLowerCase().includes(value?.toLowerCase())
        );
        setFilterSearch(filteredData);
        setSearchValue(value);
    };

    useEffect(() => {
        if (searchValue) {
            dispatch(AttributeListData({ search: searchValue })).then((res) => {
                setLoading(false);
            });
        } else {
            dispatch(AttributeListData({ search })).then((res) => {
                setLoading(false);
            });
        }
    }, []);

    const attributeOption = attributeList?.map((item) => ({
        value: item.slug,
        label: item.name,
        slug: item.slug,
    }));

    const paginate = (pageNumber, pageSize) => {
        setCurrentPage(pageNumber);
        setPageSize(pageSize);
        navigate(`?page=${pageNumber}&pageSize=${pageSize}`);
    };

    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = filterSearch?.slice(indexOfFirstItem, indexOfLastItem);

    const setAttrributeSearch = (key, value) => {
        dispatch(SetAttributesSearch({ key, value }));
        dispatch(AttributeListData({ key, value }));
    };

    return (
        <>
            <SearchBar
                onSearch={handleSearch}
                placeholder={'Search Attribute'}
            />
            <SimpleTable
                tableName="Attributes"
                addNewLink="/edit-attribute"
                addNew={false}
                placeholder={'Attributes'}
                filterByCategory={false}
                export={true}
                columns={columns}
                dataSource={currentItems}
                loading={loading}
                onSearch={setAttrributeSearch}
                exportModal={'attribute'}
                attributeOption={attributeOption}
            />

            <div className="flex justify-center  py-10">
                {' '}
                <Pagination
                    current={currentPage}
                    total={filterSearch?.length}
                    pageSize={pageSize}
                    onChange={paginate}
                    hideOnSinglePage
                    pageSizeOptions={[10, 20, 50]}
                />{' '}
            </div>
        </>
    );
};

export default AttributeListing;
