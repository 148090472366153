import { Modal } from 'antd';
import Alertify from '../../scripts/alertify';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLoader } from '../../hooks/useLoader';
import { UploadImages } from '../../redux/actions/product-action';
import { uniqueId } from '../../utils/utils';

const ImageUpload = ({
    setUploadedImages = () => {},
    title = 'Upload Image',
    name,
    uploaded_images,
    type,
}) => {
    const maxSize = 2.5 * 1024 * 1024; // 2.5 MB in bytes

    const [loader, startLoader, endLoader] = useLoader(false);
    const [open_modal, setOpenModal] = useState(false);
    const [local_images, setLocalImages] = useState([]);

    const dispatch = useDispatch();

    const handleImageChange = (e) => {
        const files = e.target.files;
        const updatedFiles = Array.from(files).map((image) => {
            // Convert spaces to hyphens in the image name
            const updatedName = image.name.replace(/\s+/g, '-');

            // Create a new File object with the updated name
            return new File([image], updatedName, {
                type: image.type,
                lastModified: image.lastModified,
                id: uniqueId(),
            });
        });

        // Validate each selected file
        const validImages = updatedFiles.filter((image) => {
            // Validate image type
            const isImage = image.type.startsWith('image/');
            if (!isImage) {
                console.warn(`File ${image.name} is not an image.`);
                Alertify.error(`File ${image.name} is not an image.`);
                return false;
            }

            // Validate image size
            const isSizeValid = image.size <= maxSize;
            if (!isSizeValid) {
                console.warn(
                    `File ${image.name} exceeds the maximum allowed size.`
                );
                Alertify.error(
                    `File ${image.name} exceeds the maximum allowed size.`
                );
                return false;
            }

            return true;
        });

        // Update state with valid images
        if (type === 'multiple') {
            setLocalImages([...validImages]);
        } else if (type === 'single') {
            setLocalImages([validImages[0]]);
        }
        toggleImagePreviewModal(true);
    };

    function toggleImagePreviewModal(state) {
        setOpenModal(state);
    }

    function uploadImages() {
        startLoader();
        dispatch(UploadImages({ images: local_images }))
            .unwrap()
            .then((payload) => {
                const upload_url = payload.res.data?.data?.urls;
                if (type === 'multiple') {
                    const data = [...uploaded_images, ...upload_url];
                    setUploadedImages(data);
                } else if (type === 'single') {
                    setUploadedImages(upload_url[0]);
                }
                toggleImagePreviewModal(false);
            })
            .finally(() => endLoader());
    }

    function deleteLocalImages(name) {
        setLocalImages(local_images.filter((item) => item.name !== name));
    }

    return (
        <>
            {/* Image section */}
            <div className="p-8 border-1 rounded-lg h-max">
                <label className="w-full text-[#1A3353] md:text-[1vw] font-semibold pb-4">
                    {title}
                </label>

                <div className="mt-4 FirstTab border-dashed border-1 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                    <div>
                        <img
                            className="m-auto"
                            src="/icons/common/upload-file.svg"
                            alt="Upload File"
                        />
                        <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                            Drag & drop files or{' '}
                            <span className="">
                                <label
                                    htmlFor={name}
                                    className="cursor-pointer text-[#FF8F2F] underline font-semibold my-6 text-[22px]"
                                >
                                    Browse
                                    <input
                                        type="file"
                                        data-max-size="2048"
                                        id={name}
                                        className="fileIcon invisible"
                                        onChange={handleImageChange}
                                        multiple={
                                            type === 'multiple' ? true : false
                                        }
                                        accept="image/*"
                                    />
                                </label>
                            </span>
                        </div>
                    </div>

                    <p className="info">Supported files: JPEG, PNG, WebP</p>
                </div>
            </div>
            <ImagePreviewModal
                open={open_modal}
                onCancel={toggleImagePreviewModal}
                confirmLoading={loader}
                data={local_images}
                centered
                onOk={uploadImages}
                {...{ deleteLocalImages }}
            />
        </>
    );
};

const ImagePreviewModal = ({
    open,
    onCancel,
    data,
    deleteLocalImages,
    ...restProps
}) => {
    return (
        <Modal open={open} onCancel={() => onCancel(false)} {...restProps}>
            <div className="font-bold text-14">Image Preview:-</div>
            <div className="flex flex-row gap-4 mt-4 max-w-full overflow-x-scroll">
                {data.length > 0 &&
                    data.map((item) => (
                        <div
                            className="flex flex-col gap-2 relative"
                            key={item.name}
                        >
                            <img
                                src={URL.createObjectURL(item)}
                                className="w-20 h-20"
                                alt="variant"
                            />
                            <p>{item.name}</p>
                            <p
                                className="absolute -top-2 -right-2 cursor-pointer bg-white rounded-full p-1"
                                onClick={() => deleteLocalImages(item.name)}
                            >
                                <img
                                    src={'/images/delete-image.png'}
                                    className="w-6 h-6"
                                    alt="variant"
                                />
                            </p>
                        </div>
                    ))}
            </div>
        </Modal>
    );
};

export default ImageUpload;
