import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import DetailsCustomer from "../../../../components/userDetails/detailsCustomer";
// import DetailsCustomer from "../../../components/userDetails/detailsCustomer";

// details of the customer to be sent to the DetailsCustomer component
const details = [
  {
    key: 1,
    label: "ID",
    content: "ABC123",
  },
  {
    key: 2,
    label: "Admin/Sub-Admin Name",
    content: "Sumit Kumar",
  },
  {
    key: 3,
    label: "Mobile Number",
    content: "+91-8844557788",
  },
  {
    key: 4,
    label: "Email Address",
    content: "sumitkumar@gmail.com",
  },
  {
    key: 8,
    label: "Created On",
    content: "20 Feb 2023",
  },
];
const AdminDetails = () => {
  function goBack() {
    window.history.back();
  }

  return (
    <div className="bg-white rounded-[6px]">
      <div className="flex gap-4 items-center p-4 border-b-1">
        <Link to="#">
          <ArrowLeftOutlined onClick={goBack} />
        </Link>
        <h1 className="table-name">Admin/Sub-Admin Details</h1>
        <img src="/icons/common/edit.svg" className="h-[20px]" alt="Edit" />
      </div>
      <DetailsCustomer dataSource={details} />
    </div>
  );
};

export default AdminDetails;
