import React, { useEffect } from 'react';
import { Select, Button, Form, Input, DatePicker } from 'antd';

const handleChange = (value) => {
    console.log(`selected ${value}`);
};
const AddNewCustomer = ({ customerData }) => {
    const [form] = Form.useForm();
    // onchange for datepicker
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    useEffect(() => {
        console.log('Customer Details-------------------', customerData);
    }, []);

    return (
        <Form form={form} layout="vertical" autoComplete="off">
            <div className="flex gap-8 ">
                <Form.Item
                    className="w-1/2"
                    name="id"
                    label={<label style={{ color: '#1A3353' }}>ID</label>}
                >
                    <Input placeholder="ABC123" className="h-[40px]" />
                </Form.Item>
                <Form.Item
                    className="w-1/2"
                    name="name"
                    label={
                        <label style={{ color: '#1A3353' }}>
                            Customer Name
                        </label>
                    }
                >
                    <Input placeholder="Enter Name" className="h-[40px]" />
                </Form.Item>
            </div>
            <div className="flex gap-8">
                <Form.Item
                    name="gender"
                    label={<label style={{ color: '#1A3353' }}>Gender</label>}
                    className="w-1/2"
                >
                    <Select
                        className="w-full h-[40px]"
                        placeholder="Please select"
                        onChange={handleChange}
                        options={[
                            {
                                value: 'male',
                                label: 'Male',
                            },
                            {
                                value: 'female',
                                label: 'Female',
                            },
                            {
                                value: 'other',
                                label: 'Other',
                            },
                            {
                                value: 'na',
                                label: 'Prefer not to say',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    className="w-1/2"
                    name="city"
                    label={<label style={{ color: '#1A3353' }}>City</label>}
                >
                    <Input placeholder="Enter City" className="h-[40px]" />
                </Form.Item>
            </div>
            <div className="flex gap-8">
                <Form.Item
                    className="w-1/2"
                    name="address"
                    label={<label style={{ color: '#1A3353' }}>Address</label>}
                >
                    <Input placeholder="Address" className="h-[40px]" />
                </Form.Item>
                <Form.Item
                    className="w-1/2"
                    name="createdOn"
                    label={<label className="text-[#1A3353">Created On</label>}
                >
                    <DatePicker
                        className="w-full h-[40px]"
                        onChange={onChange}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>
            </div>
            <div className="flex gap-8">
                <Form.Item
                    className="w-1/2"
                    name="birthday"
                    label={<label className="text-[#1A3353">Birthday</label>}
                >
                    <DatePicker
                        className="w-full h-[40px]"
                        onChange={onChange}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>
                <Form.Item
                    className="w-1/2"
                    name="engagement"
                    label={<label className="text-[#1A3353">Engagement</label>}
                >
                    <DatePicker
                        className="w-full h-[40px]"
                        onChange={onChange}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>
            </div>
            <div className="flex gap-8">
                <Form.Item
                    className="w-1/2"
                    name="wedding"
                    label={<label className="text-[#1A3353">Wedding</label>}
                >
                    <DatePicker
                        className="w-full h-[40px]"
                        onChange={onChange}
                        format={'DD/MM/YYYY'}
                    />
                </Form.Item>
                <Form.Item
                    className="w-1/2"
                    name=""
                    label={<label className="text-[#1A3353"></label>}
                >
                    {/* <DatePicker className="w-full" onChange={onChange} format={'DD/MM/YYYY'} /> */}
                </Form.Item>
            </div>

            <div className="flex gap-4 w-1/2 mt-6">
                <Button
                    htmlType="submit"
                    className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                >
                    SAVE CHANGES
                </Button>
                <Button
                    htmlType="reset"
                    className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                >
                    CANCEL
                </Button>
            </div>
        </Form>
    );
};

export default AddNewCustomer;
