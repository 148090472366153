import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import {
    Select,
    Button,
    Form,
    Input,
    Checkbox,
    ConfigProvider,
    Modal,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    CategoryListData,
    CreateCategory,
    CreateFilter,
    DeleteFilter,
    GetFilterList,
    PrimaryCategoryListData,
    CategoriesDetailsById,
    GetCategoryDetail,
    UpdateCategory,
} from '../../../redux/actions/category-action';
import Alertify from '../../../scripts/alertify';
import SimpleTable from '../../../components/tables/simpletable';
import { AttributeListData } from '../../../redux/actions/attribute-action';
import useAppSelector from '../../../hooks/useAppSelector';
import { ClearSecondCategory } from '../../../redux/slices/category-slice';

// handlechange for select box
const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

const attCols = [
    {
        title: 'ATTRIBUTE NAME',
        dataIndex: 'name',
        width: '50%',
    },
    {
        title: 'SORT ORDER',
        dataIndex: 'order',
        width: '50%',
    },
];

const ORDER_DATA = [...Array(31).keys()]
    .filter(Boolean)
    .map((item) => ({ label: item, value: item }));

const EditCategory = () => {
    const [form] = Form.useForm();

    const [name, setName] = useState('');
    const [level, setLevel] = useState('first');
    const [loading, setLoading] = useState(true);
    const [image_files, setImageFiles] = useState([]);
    const [form_loaded, setFormLoaded] = useState(false);
    const [edit_filter, setEditFilter] = useState(false);
    const [sub_category, setSubCategory] = useState(null);
    const [slug, setSlug] = useState();
    const [modal_visible, setModalVisible] = useState(false);
    const [is_bestSelling, setBestSelling] = useState(false);
    const [parent_category, setParentCategory] = useState(null);
    const [selected_filter, setSelectedFilter] = useState({});
    const [selected_order, setSelectedOrder] = useState(undefined);
    const [is_featuredCategory, setFeaturedCategory] = useState(false);
    const [delete_filter_modal, setDeleteFilterModal] = useState(false);
    const [selected_attribute, setSelectedAttribute] = useState(undefined);

    const { id } = useParams();
    const parentId = id;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const store = useAppSelector((state) => ({
        attributes_list: state.category_store.attributes_list,
        filters_list: state.category_store.filters_list,
        parent_category_list: state.container_store.categories,
        secondCategory: state.category_store.secondCategory,
    }));

    // console.log("Second level category--------", store.secondCategory)

    function toggleModalVisible() {
        if (!parent_category) {
            return Alertify.error('Please select the parent category first');
        }
        setModalVisible(!modal_visible);
    }

    useEffect(()=>{
        if(!parent_category){
            dispatch(ClearSecondCategory());
        }
    },[parent_category])

    function slugify(string) {
        const uniqueTimestamp = new Date().getTime();
        const slugifyValue = `${string
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/&\s*/g, 'and')
            .replace(/&/g, '')
            .replace(/-+/g, '-')
            .replace(/[^a-z0-9-_]/g, '')}-${uniqueTimestamp}`;

        return slugifyValue;
    }

    const handleNameChange = (e) => {
        const { value } = e.target;
        const slugValue = slugify(value);
        form.setFieldsValue({ slug: slugValue });
        setName(e.target.value);
    };

    const handleChange = (value) => {
        setSubCategory(value);
    };

    const getSubCategory = (value) => {
        setLevel('second');
        setParentCategory(value);
        const parentId = value;
        dispatch(CategoryListData({ level: 'second', parentId })).then(() =>
            setLoading(false)
        );
        if(value){
            dispatch(ClearSecondCategory())
        }
    };

    function mapFilter() {
        dispatch(
            CreateFilter({
                categoryId: parent_category,
                order: selected_order,
                attribute: selected_attribute,
                type: edit_filter,
                id: selected_filter.key,
            })
        )
            .unwrap()
            .then(() => {
                toggleModalVisible();
                setEditFilter(false);
                setSelectedAttribute('');
                setSelectedOrder('');
            })
            .catch(() => {});
    }

    function getMapAttributesList(selectedParent) {
        dispatch(GetFilterList({ categoryId: selectedParent }))
            .unwrap()
            .then()
            .catch(() => {});
    }

    function goBack() {
        window.history.back();
    }

    function editFilter(data, type) {
        if (type === 'delete') {
            toggleDeleteModal();
        } else {
            setSelectedAttribute(data.attribute);
            setSelectedOrder(data.order);
            setEditFilter(true);
            toggleModalVisible(!modal_visible);
        }
        setSelectedFilter(data);
    }

    function deleteFilter() {
        dispatch(
            DeleteFilter({
                id: selected_filter.key,
                categoryId: selected_filter.categoryId,
            })
        )
            .unwrap()
            .then(() => toggleDeleteModal())
            .catch(() => {});
    }

    function toggleDeleteModal() {
        setDeleteFilterModal(!delete_filter_modal);
    }

    function handleImage(e) {
        const maxSize = 2.5 * 1024 * 1024; // 2.5 MB in bytes
        const files = e.target.files;
        // toggleImagePreviewModal(true);
        // Validate each selected file
        const validImages = Array.from(files).filter((image) => {
            // Validate image type
            const isImage = image.type.startsWith('image/');
            if (!isImage) {
                console.warn(`File ${image.name} is not an image.`);
                return false;
            }

            // Validate image size
            const isSizeValid = image.size <= maxSize;
            if (!isSizeValid) {
                console.warn(
                    `File ${image.name} exceeds the maximum allowed size.`
                );
                return false;
            }

            return true;
        });

        // Update state with valid images
        setImageFiles([...validImages]);
    }

    const action_column = {
        title: 'ACTIONS',
        dataIndex: 'action',
        width: '90px',
        key: 'action',
        render: (id, record) => {
            return (
                <>
                    <span className="flex gap-2">
                        <div onClick={() => editFilter(record, 'edit')}>
                            <img
                                src="/icons/common/edit.svg"
                                alt="edit"
                                className="h-[20px]"
                            />
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => editFilter(record, 'delete')}
                        >
                            <img
                                src="/icons/common/delete.svg"
                                alt="delete"
                                className="h-[20px]"
                            />
                        </div>
                    </span>
                </>
            );
        },
    };

    const col = [...attCols, action_column];

    const parentCategoryOptions = useMemo(() => {
        return store.parent_category_list?.map((item) => ({
            value: item._id,
            label: item.name,
            id: item._id,
        }));
    }, [store.parent_category_list]);

    const onFinish = (values) => {
        const { slug, parentCategory, subCategory } = values;
        if (!parent_category) {
            return Alertify.error('Please select the parent category first');
        }
        if (is_featuredCategory && image_files.length === 0) {
            return Alertify.error('Please upload image for this category');
        }
        let newLevel = '';
        let categoryId = '';
        if (parent_category && !sub_category) {
            newLevel = 'second';
            categoryId = parentCategory;
        } else if (parent_category && sub_category) {
            newLevel = 'third';
            categoryId = subCategory;
        }

        const newCategoryData = {
            name,
            slug,
            level: newLevel,
            parentId: categoryId,
            image: image_files[0],
            isFeaturedCategory: is_featuredCategory,
            isBestSelling: is_bestSelling,
        };

        if (id === '0') {
            dispatch(CreateCategory(newCategoryData))
                .unwrap()
                .then(() => {
                    Alertify.success('New category created');
                    navigate('/categories');
                })
                .catch(() => {});
        } else if (id !== '0') {
            dispatch(UpdateCategory({ id, ...newCategoryData }))
                .unwrap()
                .then((res) => {
                    navigate('/categories');
                })
                .catch(() => {});
        }
    };

    function handleCheckbox(key, value) {
        if (key === 'featured') {
            setFeaturedCategory(value);
        } else if (key === 'best_selling') {
            setBestSelling(value);
        }
    }

    function deleteImage() {
        setImageFiles([]);
    }

    function isDisabled(type) {
        if (type === 'featured') {
            if (parent_category && name && !sub_category) {
                return false;
            }
        } else if (type === 'best_selling') {
            if (name && sub_category) {
                return false;
            }
        }
        return true;
    }

    function fieldDisabled(field_name) {
        let disabled = false;
        if (field_name === 'name' && id !== '0' && level === 'first') {
            return true;
        } else if (field_name === 'parent_category' && id !== '0') {
            return true;
        } else if (field_name === 'second_category' && id !== '0') {
            return true;
        }
        return disabled;
    }

    console.log("Store ", store.secondCategory)

    useEffect(() => {
        dispatch(PrimaryCategoryListData({ level })).then((res) => {
            setLoading(false);
        });
        dispatch(AttributeListData({ key: '', value: '' }));
        if (id !== '0') {
            dispatch(CategoriesDetailsById({ id }))
                .unwrap()
                .then(({ res }) => {
                    setFormLoaded(true);
                    if (res.data.level === 'first') {
                        setParentCategory(res.data._id);
                        setLevel(res.data.level);
                    } else if (res.data.level === 'second') {
                        setFeaturedCategory(res.data.isFeaturedCategory);
                        setName(res.data.name);
                        setParentCategory(res.data.parentId._id);
                        if (res.data.image) {
                            setImageFiles([res.data.image]);
                        }
                        setLevel(res.data.level);
                        setSlug(res.data.slug);
                    } else if (res.data.level === 'third') {
                        setName(res.data.name);
                        setSlug(res.data.slug);
                        setLevel(res.data.level);
                        setSubCategory(res.data.parentId._id);
                        setParentCategory(res.data.parentId.parentId._id);
                        setBestSelling(res.data.isBestSelling);
                        setFeaturedCategory(res.data.isFeaturedCategory);
                        dispatch(
                            CategoryListData({
                                level: 'second',
                                parentId: res.data.parentId.parentId._id,
                            })
                        ).then(() => setLoading(false));
                    }
                })
                .catch(() => {});
        } else if (id === '0') {
            setFormLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (!!parent_category) {
            getMapAttributesList(parent_category);
        }
    }, [parent_category]);

    return (
        <>
            <div className="bg-white pb-2 rounded-[6px]">
                <div className="flex p-4 items-center justify-between border-b-1">
                    <div className="flex gap-4 items-center">
                        <ArrowLeftOutlined onClick={goBack} />
                        <div className="table-name">
                            {parentId === '0'
                                ? 'Add Category'
                                : 'Edit Category'}
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <Select
                            className="ms-3"
                            defaultValue="Action"
                            style={{
                                width: 120,
                            }}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'edit',
                                    label: 'Edit',
                                },
                                {
                                    value: 'delete',
                                    label: 'Delete',
                                },
                            ]}
                        />
                        <Button className="ms-3">Export</Button>
                    </div>
                </div>
                <div className="rounded-[6px] border-1 p-8 m-2 md:w-[80%]">
                    {form_loaded && (
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            onFinish={onFinish}
                            initialValues={{
                                parentCategory: parent_category,
                                subCategory: sub_category,
                                slug: slug,
                            }}
                        >
                            <div className="flex gap-8 ">
                                <div className="w-1/2" name="name">
                                    <label style={{ color: '#1A3353' }}>
                                        Name *
                                    </label>
                                    <Input
                                        onChange={handleNameChange}
                                        placeholder="Category Name"
                                        value={name}
                                        disabled={fieldDisabled('name')}
                                    />
                                </div>
                                <Form.Item
                                    className="w-1/2"
                                    name="slug"
                                    label={
                                        <label style={{ color: '#1A3353' }}>
                                            Slug *
                                        </label>
                                    }
                                >
                                    <Input
                                        disabled={true}
                                        value={form.getFieldValue('slug')}
                                        placeholder="all letter to be lower case and only '_' & '-' signs are allowed"
                                    />
                                </Form.Item>
                            </div>
                            <div className="flex gap-8 items-center">
                                <Form.Item
                                    name="parentCategory"
                                    label={
                                        <label style={{ color: '#1A3353' }}>
                                            Parent Category
                                        </label>
                                    }
                                    className="w-1/2"
                                >
                                    <Select
                                        defaultValue="Select Category"
                                        disabled={fieldDisabled(
                                            'parent_category'
                                        )}
                                        className="w-full h-[40px]"
                                        //   defaultValue="Select Category"
                                        placeholder="Please select category"
                                        onChange={getSubCategory}
                                        options={parentCategoryOptions}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="subCategory"
                                    label={
                                        <label style={{ color: '#1A3353' }}>
                                            Sub Category
                                        </label>
                                    }
                                    className="w-1/2"
                                >
                                    <Select
                                        className="w-full"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select attributes"
                                        //   defaultValue={["a10", "c12"]}
                                        onChange={handleChange}
                                        disabled={fieldDisabled(
                                            'second_category'
                                        )}
                                    >
                                        {store.secondCategory && store.secondCategory?.map((item) => (
                                            <>
                                                <Select.Option
                                                    key={item?._id}
                                                    value={item?._id}
                                                >
                                                    {item?.name}
                                                </Select.Option>
                                            </>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* START->  image upload */}

                            <>
                                <div className="flex gap-8 items-center mb-6">
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Checkbox: {
                                                    // colorBgContainer: "#000000",
                                                    colorPrimary: '#FF8F2F',
                                                },
                                            },
                                        }}
                                    >
                                        <Checkbox
                                            checked={is_featuredCategory}
                                            onChange={(e) =>
                                                handleCheckbox(
                                                    'featured',
                                                    e.target.checked
                                                )
                                            }
                                            disabled={isDisabled('featured')}
                                        >
                                            Featured Category
                                        </Checkbox>
                                        <Checkbox
                                            checked={is_bestSelling}
                                            onChange={(e) =>
                                                handleCheckbox(
                                                    'best_selling',
                                                    e.target.checked
                                                )
                                            }
                                            disabled={isDisabled(
                                                'best_selling'
                                            )}
                                        >
                                            Best Selling Category
                                        </Checkbox>
                                    </ConfigProvider>
                                </div>
                                <div className="flex flex-row gap-2">
                                    <div className="mt-4 FirstTab border-dashed border-1 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                                        <div>
                                            <img
                                                className="m-auto"
                                                src="/icons/common/upload-file.svg"
                                                alt="Upload File"
                                            />
                                            <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                                Drag & drop files or{' '}
                                                <span className="">
                                                    <label
                                                        htmlFor="img"
                                                        className="cursor-pointer text-[#FF8F2F] underline font-semibold my-6 text-[22px]"
                                                        onClick={() =>
                                                            document
                                                                .getElementById(
                                                                    'getFile'
                                                                )
                                                                .click()
                                                        }
                                                    >
                                                        Browse
                                                        <input
                                                            type="file"
                                                            data-max-size="2048"
                                                            id="getFile"
                                                            className="fileIcon invisible"
                                                            onChange={
                                                                handleImage
                                                            }
                                                            accept="image/*"
                                                        />
                                                    </label>
                                                </span>
                                            </div>
                                        </div>

                                        <p className="info">
                                            Supported files: JPEG, PNG, WebP
                                        </p>
                                    </div>
                                    <div className="flex gap-4 mb-4 flex-wrap">
                                        {image_files?.map((image, index) => (
                                            <div
                                                key={index}
                                                className="w-[100%] z-1 relative"
                                            >
                                                {typeof image === 'string' ? (
                                                    <img
                                                        src={image}
                                                        alt={`Selected ${
                                                            index + 1
                                                        }`}
                                                    />
                                                ) : (
                                                    <>
                                                        <img
                                                            src={URL.createObjectURL(
                                                                image
                                                            )}
                                                            alt={`Selected ${
                                                                index + 1
                                                            }`}
                                                        />
                                                        <div
                                                            className="cursor-pointer absolute top-0 z-10 left-0"
                                                            onClick={
                                                                deleteImage
                                                            }
                                                        >
                                                            Delete
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>

                            {/* END -> Image Upload */}
                            <div className="flex gap-4 w-2/5 mt-6">
                                <Button
                                    htmlType="submit"
                                    className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                >
                                    SAVE CHANGES
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                    onClick={goBack}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Form>
                    )}

                    {/* For attributes  */}
                    {/* keep in mind to add modal functionality */}
                    {/* table rows will append on the basis of selected attributes from the modal */}
                    <SimpleTable
                        tableName="Attributes"
                        showAddNew={
                            toggleModalVisible
                        } /* please put your function name for add new modal here */
                        addNew={false}
                        columns={col}
                        dataSource={store.filters_list}
                        select_row={false}
                    />
                </div>
                {modal_visible && (
                    <Modal
                        open={modal_visible}
                        onCancel={toggleModalVisible}
                        width={800}
                        onOk={mapFilter}
                        okText={'Save'}
                    >
                        <div className="flex flex-row gap-3">
                            <div className="p-8 flex gap-3 justify-center items-center">
                                <label>Attributes</label>
                                <Select
                                    style={{
                                        width: 220,
                                    }}
                                    options={store.attributes_list}
                                    placeholder="Select a attribute for mapping"
                                    onChange={(e) => setSelectedAttribute(e)}
                                    value={selected_attribute}
                                />
                            </div>
                            <div className="p-8 flex gap-3 justify-center items-center">
                                <label>Orders</label>
                                <Select
                                    style={{
                                        width: 220,
                                    }}
                                    placeholder="Select a order"
                                    options={ORDER_DATA}
                                    onChange={(e) => setSelectedOrder(e)}
                                    value={selected_order}
                                />
                            </div>
                        </div>
                    </Modal>
                )}
                {delete_filter_modal && (
                    <Modal
                        footer={false}
                        open={delete_filter_modal}
                        onCancel={toggleDeleteModal}
                    >
                        <p className="font-semibold text-16">
                            Do you want to delete the filters
                        </p>

                        <div className="flex justify-end gap-4 lg:pt-10  items-center">
                            <Button
                                type="primary"
                                className="inline-flex items-center justify-center border border-[#084245] text-[#084245]  text-center text-14 font-medium bg-[#ffff] "
                                onClick={toggleDeleteModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                className="inline-flex items-center justify-center border border-red-500 bg-red-500  text-center text-14 font-medium text-[#ffff] "
                                onClick={deleteFilter}
                            >
                                Delete
                            </Button>
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default EditCategory;
