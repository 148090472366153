import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';
import {
    DELETE_REVIEW_LIST,
    REVIEW_DETAIL_LIST,
    REVIEW_LIST,
    UPDATE_REVIEW_LIST,
} from '../../contant/api-constant';

/*--------------------- Review List --------------------*/

export const GetReviewList = createAsyncThunk(
    'review-list',
    async ({ page = 1, pageSize = 10 }) => {
        try {
            const res = await postApi(REVIEW_LIST, {
                page,
                pageSize,
            });
            return res.data;
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/* -------------------- Get review Details List --------------------*/

export const GetReviewDetails = createAsyncThunk(
    'GetReviewDetails',
    async ({ id }) => {
        try {
            const res = await getApi(`${REVIEW_DETAIL_LIST}/${id}`);
            return res;
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

/*---------------- Update Review List-------------------*/

export const EditReview = createAsyncThunk(
    'EditReview',
    async ({ id, payload }, { dispatch }) => {
        try {
            const res = await patchApi(`${UPDATE_REVIEW_LIST}/${id}`, {
                ...payload,
            });
            Alertify.success('Updated successfully');
            dispatch(GetReviewList({}));
            dispatch(GetReviewDetails({ id }));
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

/*-------------------------Delete Review --------------------*/

export const DeleteReview = createAsyncThunk(
    'DeleteReview',
    async ({ id }, { dispatch }) => {
        try {
            const res = await deleteApi(`${DELETE_REVIEW_LIST}/${id}`);
            Alertify.success('successfully deleted banner');
            dispatch(GetReviewList({}));
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export function ManipualateReviewData(data) {
    return data?.map((item) => {
        return {
            key: item?._id,
            productName: item?.productId?.name,
            productId: item?.productId?._id,
            userName: `${item?.userId?.firstName} ${item?.userId?.lastName}`,
            review: item?.review,
            ratings: item?.rating,
            date: item._id,
            status: item?.status,
        };
    });
}
