export function saveToLocalStorage(token) {
    window.localStorage.setItem('token', token);
}

export function removeTokenLocalStorage() {
    window.localStorage.removeItem('token');
}

export function deletePersistStore() {
    window.localStorage.removeItem('persist:root');
    removeTokenLocalStorage();
}
