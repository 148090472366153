import { createSlice } from '@reduxjs/toolkit';
import {
    AttributeListData,
    AttributeValueListData,
    CreateAttribute,
    DeleteSubAttribute,
    ManipulateAttributesLists,
    ManipulateSubAttributesLists,
    SubAttributeList,
    UpdateAttribute,
} from '../actions/attribute-action';

const initialState = {
    search: '',
    attributeList: [],
    selected_attribute: {},
    subAttributeList: [],
    attribute_count: 0,
    sub_attribute_count: 0,
    attributeName: '',
    attributeData: {},
};

const attributeSlice = createSlice({
    name: 'attribute-slice',
    initialState,
    reducers: {
        SelectedAttribute: (state, { payload }) => {
            state.selected_attribute = payload;
        },
        SetAttributesSearch: (state, { payload }) => {
            // console.log('payload', payload);
            state['search'] = payload.value;
        },
        ClearAttributesSearch: (state) => {
            state['search'] = '';
        },
        SetAttributeName: (state, { payload }) => {
            state.attributeName = payload;
        },
        SetAttributesDetails: (state, { payload }) => {
            state.attributeData = {
                ...state.attributeData,
                [payload.key]: payload.value,
            };
        },
        ClearSelectedAttributes: (state) => {
            state.selected_attribute = {}; 
        },
        updateAttributeName: (state, { payload }) => {
            state.selected_attribute.name = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(AttributeListData.fulfilled, (state, { payload }) => {
            // console.log("Payload----------", payload);
            state.attributeList = ManipulateAttributesLists(payload);
            state.attribute_count = state.attributeList?.length;
        });
        builder.addCase(SubAttributeList.fulfilled, (state, { payload }) => {
            state.subAttributeList = ManipulateSubAttributesLists(
                payload.data?.list
            );
            state.sub_attribute_count = payload.data?.count;
        });
        builder.addCase(AttributeValueListData.fulfilled, (state, { payload }) => {
            // console.log("Payload----------", payload);
            state.selected_attribute = payload
        });
        builder.addCase(CreateAttribute.fulfilled, (state, { payload }) => {});
        builder.addCase(UpdateAttribute.fulfilled, (state, { payload }) => {
            state.subAttributeList = payload.data?.list;
        });
        builder.addCase(
            DeleteSubAttribute.fulfilled,
            (state, { payload }) => {}
        );
    },
});

export const {
    SetAttributesSearch,
    SelectedAttribute,
    SetCategoryData,
    SetAttributeName,
    ClearSelectedAttributes,
    updateAttributeName,
    ClearAttributesSearch
} = attributeSlice.actions;

export default attributeSlice.reducer;
