'use client';
import React, { useEffect, useState } from 'react';
import './sidebar.css';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../redux/slices/auth-slice';

const menuItems = [
    {
        logo: '/icons/sidebar/categories.svg',
        link: '/dashboard',
        activelogo: '/icons/sidebar/activecategories.svg',
        name: 'Dashboard',
        permission_name: 'user',
    },
    {
        logo: '/icons/sidebar/user.svg',
        link: '/users',
        activelogo: '/icons/sidebar/activeuser.svg',
        name: 'Users',
        permission_name: 'user',
    },
    {
        logo: '/icons/sidebar/products.svg',
        link: '/products',
        activelogo: '/icons/sidebar/activeproducts.svg',
        name: 'Products',
        permission_name: 'product',
    },
    {
        logo: '/icons/sidebar/attributes.svg',
        link: '/attributes',
        activelogo: '/icons/sidebar/activeattributes.svg',
        name: 'Attributes',
        permission_name: 'attribute',
    },
    {
        logo: '/icons/sidebar/categories.svg',
        link: '/categories',
        activelogo: '/icons/sidebar/activecategories.svg',
        name: 'Categories',
        permission_name: 'category',
    },
    {
        logo: '/icons/sidebar/banners.svg',
        link: '/banners',
        activelogo: '/icons/sidebar/activebanners.svg',
        name: 'Banners',
        permission_name: 'banner',
    },
    {
        logo: '/icons/sidebar/crazy-deals.svg',
        link: '/crazy-deals',
        activelogo: '/icons/sidebar/active-crazy-deals.svg',
        name: 'Crazy Deals',
        permission_name: 'crazyDeal',
    },
    {
        logo: '/icons/sidebar/store.svg',
        link: '/stores',
        activelogo: '/icons/sidebar/activestore.svg',
        name: 'Store',
        permission_name: 'store',
    },
    {
        logo: '/icons/sidebar/orders.svg',
        link: '/orders',
        activelogo: '/icons/sidebar/activeorders.svg',
        name: 'Orders',
        permission_name: 'order',
    },
    {
        logo: '/icons/sidebar/review-approval.svg',
        link: '/reviews',
        activelogo: '/icons/sidebar/activereview-approval.svg',
        name: 'Review Approval',
        permission_name: 'reviewApproval',
    },
    // {
    //     logo: '/icons/sidebar/blogs.svg',
    //     link: '/blogs',
    //     activelogo: '/icons/sidebar/activeblogs.svg',
    //     name: 'Blogs',
    //     permission_name: 'blog',
    // },
    {
        logo: '/icons/sidebar/logout.svg',
        link: '/logout',
        activelogo: '/icons/sidebar/activelogout.svg',
        name: 'Logout',
    },
];

const Sidebar = ({ data }) => {
    const location = useLocation();
    const [isMenuActive, setMenuActive] = useState(false);
    const [activeItemIndex, setActiveItemIndex] = useState(
        '/' + location.pathname.split('/')[1]
    );

    const toggleMenu = () => {
        setMenuActive(!isMenuActive);
    };

    const handleItemClick = (index) => {
        setActiveItemIndex(index);
        setMenuActive(!isMenuActive);
    };
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logOut());
    };

    const filteredMenuItems = menuItems?.filter((item) => {
        if (item.name === 'Logout') {
            return true;
        }
        return data?.some((permission) =>
            permission.includes(item?.permission_name)
        );
    });

    useEffect(() => {
        setActiveItemIndex('/' + location.pathname.split('/')[1]);
        return () => {};
    }, [location.pathname]);

    return (
        <div className="display">
            {/* <div className={`navigation ${isMenuActive ? "active" : ""}`}> */}
            <div className="toggle mb-6 absolute p-2 pt-3" onClick={toggleMenu}>
                <img src="/icons/sidebar/toggle.svg" alt="" />
            </div>
            <div
                className={`navigation active navigation active @sm:z-[100] @sm:ml-0 @sm:p-0 @sm:border-0 @lg:w-[19vw] @xl:w-[16vw] @2xl:w-[15vw] overflow-y-scroll ${
                    isMenuActive
                        ? '@sm:w-[70vw]'
                        : 'transition-[width 5s] @sm:w-[0vw]'
                }`}
            >
                <div className="first-col h-fit min-h-full">
                    <div className="toggle mb-6" onClick={toggleMenu}>
                        <img src="/icons/sidebar/toggle.svg" alt="" />
                    </div>
                    <div>
                        <h1 className="invisible heading">Dashboard</h1>
                        <ul className="icon-list">
                            {filteredMenuItems?.map((item, index) => (
                                <li
                                    className={`list py-0 my-5 ${
                                        item.link === activeItemIndex
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => handleItemClick(item.link)}
                                    key={index}
                                >
                                    {item.link === '/logout' ? (
                                        <Link
                                            onClick={handleLogout}
                                            className="flex items-center justify-center rounded-[16px]"
                                        >
                                            <span className="icon">
                                                <img
                                                    src={
                                                        item.link ===
                                                        activeItemIndex
                                                            ? item.activelogo
                                                            : item.logo
                                                    }
                                                    alt="Icon"
                                                />
                                            </span>
                                        </Link>
                                    ) : (
                                        <Link
                                            to={item.link}
                                            className="flex items-center justify-center rounded-[16px]"
                                        >
                                            <span className="icon">
                                                <img
                                                    src={
                                                        item.link ===
                                                        activeItemIndex
                                                            ? item.activelogo
                                                            : item.logo
                                                    }
                                                    alt="Icon"
                                                />
                                            </span>
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="second-col h-fit min-h-full">
                    <div className="toggle invisible mb-6" onClick={toggleMenu}>
                        <img src="/icons/sidebar/toggle.svg" alt="" />
                    </div>
                    <div>
                        <h1 className="heading text-white ps-4">DASHBOARD</h1>
                        <ul>
                            {filteredMenuItems.map((item, index) => (
                                <li
                                    className={`list my-5 py-0 ${
                                        item.link === activeItemIndex
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => handleItemClick(item.link)}
                                    key={index}
                                >
                                    <b></b>
                                    <b></b>
                                    {item.link === '/logout' ? (
                                        <Link
                                            onClick={handleLogout}
                                            className="flex items-center ps-4"
                                        >
                                            <span className="title">
                                                {item.name}
                                            </span>
                                        </Link>
                                    ) : (
                                        <Link
                                            to={item.link}
                                            className="flex items-center ps-4"
                                        >
                                            <span className="title">
                                                {item.name}
                                            </span>
                                        </Link>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
