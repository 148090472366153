import React, { useState } from 'react';
import OTPInput from 'react-otp-input';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderStatus } from '../../redux/actions/user-action';
import { orderList } from '../../redux/actions/order-action';

const Otp = ({ pageNumber, pageSize, searchInput }) => {
    const dispatch = useDispatch();
    const role = useSelector((state) => state.auth_store.role);
    const userId = useSelector((state) => state.auth_store.userId);
    const orderListId = useSelector((state) => state?.order?.orderListId);
    const [otp, setOtp] = useState('');

    const submitHandler = () => {
        dispatch(
            updateOrderStatus({
                status: 'delivered',
                otp,
                orderId: orderListId,
            })
        ).then((res) => {
            dispatch(
                orderList({
                    page: pageNumber,
                    pageSize: pageSize,
                    search: searchInput,
                    role: role,
                    userId: userId,
                })
            );
            setOtp('');
            window.location.reload();
        });
    };

    return (
        <div className="otp py-8">
            <div className="table-name text-center">OTP Verification</div>
            <p className="text-[#575555] my-6 text-center">
                Enter the code from the sms : {orderListId?.deliveryOTP}
                {/* we sent to +91 9876543210 */}
            </p>
            {/* <h2 className="table-name my-6 text-center">{timer}</h2> */}
            <div className="flex justify-center">
                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                />
            </div>
            {/* <p className="text-[#575555] my-6 text-center">
                Did not receiver the OTP?{' '}
                <Button className="border-0">RESEND</Button>
            </p> */}
            <button
                className="mx-auto py-2 my-6 px-8 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                onClick={() => submitHandler()}
            >
                Submit
            </button>

            {/* <Checkbox onChange={onChange}>Please select this to deliver your order.</Checkbox> */}
        </div>
    );
};

export default Otp;
