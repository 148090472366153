import React, { useEffect, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import { Modal, Pagination, Table } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from '../../components/topbar/searchbar';
import { useDispatch, useSelector } from 'react-redux';
import { BulkErrorLogs } from '../../redux/actions/product-action';

const columns = [
    // {
    //     title: 'PRODUCT NAME',
    //     dataIndex: 'name',
    // },
    {
        title: 'Date ',
        dataIndex: 'date',
    },
    {
        title: 'Total Data',
        dataIndex: 'totalData',
    },
    {
        title: 'File type',
        dataIndex: 'detailType',
    },
];

const Logs = () => {
    const [variantListing, setVariantListing] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [searchP] = useSearchParams();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);

    const bulkErrorLogs = useSelector(
        (state) => state.product_store.bulk_errors
    );
    const bulkErros_count = useSelector(
        (state) => state.product_store.bulkErros_count
    );

    console.log('Bulk error logs------', bulkErrorLogs);

    useEffect(() => {
        dispatch(
            BulkErrorLogs({
                page: Number(currentPage),
                pageSize: Number(pageSize),
            })
        ).then((res) => {
            setLoading(false);
        });
    }, [currentPage, pageSize]);

    const showModal = (record) => {
        console.log('record errorData---------->', record?.errorData);
        setVariantListing(record.errorData);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function paginate(page, pageSize) {
        setCurrentPage(page);
        setPageSize(pageSize);
        navigate(`?page=${page}&pageSize=${pageSize}`, { replace: true });
    }

    console.log("bulkErros_count-------", bulkErros_count)

    const action_column = [
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (id, record) => {
                return (
                    <span className="flex">
                        {/* <Link to="#">
                            <img
                                src="/icons/common/edit.svg"
                                alt="edit"
                                className="h-[20px]"
                            />
                        </Link> */}
                        <img
                            src="/images/product-images/eye.png"
                            alt="edit"
                            className=" ml-5 h-[20px] cursor-pointer"
                            onClick={() => showModal(record)}
                        />
                    </span>
                );
            },
        },
    ];
    const all_columns = [...columns, ...action_column];

    const newCol = [
        {
            title: 'Line Number',
            dataIndex: 'lineNumber',
            key: 'lineNumber',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Sku',
            dataIndex: 'value',
            key: 'value',
        },
        // {
        //     title: 'Created At',
        //     dataIndex: 'createdAt',
        //     key: 'createdAt',
        //     render: (createdAt) => new Date(createdAt).toLocaleString(),
        // },
    ];
    return (
        <>
            {/* <SearchBar
                // onSearch={}
                placeholder={'Search by SKU'}
            /> */}
            <div className="bg-white pb-2 rounded-[6px]">
                <SimpleTable
                    tableName={'Error Logs Listings'}
                    columns={all_columns}
                    dataSource={bulkErrorLogs}
                    loading={loading}
                    placeholder={'products'}
                    goBack={true}
                />
                <div className="flex justify-center  py-10">
                    <Pagination
                        current={currentPage}
                        total={bulkErros_count}
                        pageSize={pageSize}
                        onChange={paginate}
                        hideOnSinglePage
                        pageSizeOptions={[10, 20, 50]}
                    />
                </div>
                <Modal
                    title={variantListing?.length ? `Error Logs List` : ''}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className="w-[70%]"
                >
                    {variantListing?.length > 0 ? (
                        <Table
                            dataSource={variantListing}
                            columns={newCol}
                            pagination={false}
                        />
                    ) : (
                        <p className="text-center text-16 py-10">
                            No Error Logs Found
                        </p>
                    )}
                </Modal>
            </div>
        </>
    );
};

export default Logs;
