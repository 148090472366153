import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    AttributeValueListData,
    CreateAttribute,
    SubAttributeList,
    UpdateAttribute,
    isTopBrand,
} from '../../../redux/actions/attribute-action';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ClearSelectedAttributes,
    SetAttributeName,
} from '../../../redux/slices/attribute-slice';
import Alertify from '../../../scripts/alertify';
import useAppSelector from '../../../hooks/useAppSelector';

const AddNewSubAttribute = () => {
    const [form] = Form.useForm();
    const [status, setStatus] = useState(false);
    const dispatch = useDispatch();
    const { id, attribute_id } = useParams();
    const attribute = id;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const subAttributeValueList = useSelector(
        (state) => state.attribute_store.selected_attribute
    );

    const store = useAppSelector((state) => ({
        topBrandStatus: state.attribute_store.selected_attribute.isTopBrand,
        id: state.attribute_store.selected_attribute.key,
        name: state.attribute_store.selected_attribute.name,
        image: state.attribute_store.selected_attribute.image,
        hexCode: state.attribute_store.selected_attribute.hexCode,
    }));

    const storeId = store.id;

    const attributeName = localStorage.getItem('attributeName');
    const onChange = (e) => {
        setStatus(e.target.checked);
    };

    useEffect(() => {
        if (attribute_id !== '0') {
            dispatch(AttributeValueListData(attribute_id)).then((res) => {
                setIsLoading(true);
                setStatus(res?.payload?.isTopBrand)
            })
        }else if(attribute_id==='0'){
            setIsLoading(true);
        }
    }, [attribute_id]);

    useEffect(() => {
        if (attribute_id === '0') {
            dispatch(ClearSelectedAttributes());
        }
        dispatch(SubAttributeList({ attribute: attributeName }));

        return () => {
            dispatch(ClearSelectedAttributes());
        };
    }, [dispatch, attributeName]);

    function goBack() {
        window.history.back();
    }

    const [file, setFile] = useState(null);

    const [dragActive, setDragActive] = useState(false);
    const [msg, setMsg] = useState('');

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        }
    };

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                const { name, hexCode } = values;
                const formData = new FormData();
                formData.append('attribute', attribute);
                formData.append('value', name);

                formData.append('isTopBrand', status);

                if (attribute === 'shade') {
                    formData.append('hexCode', hexCode);
                }
                if (attribute === 'brand' && file !== null) {
                    formData.append('image', file);
                }

                if (attribute_id !== '0') {
                    dispatch(
                        UpdateAttribute({ formData, id: attribute_id })
                    ).then(() => {
                        navigate(
                            `/attributes/sub-attribute-list/${attributeName}`
                        );
                    });
                } else if (attribute_id === '0') {
                    dispatch(CreateAttribute(formData)).then(() => {
                        navigate(`/attributes/sub-attribute-list/${attribute}`);
                    });
                }
                form.resetFields();
                setFile(null);
                setMsg('');
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });
    };

    return (
        <>
            <div className="bg-white pb-2 rounded-[6px]">
                <div className="flex p-4 items-center justify-between border-b-1">
                    <div className="flex gap-4 items-center">
                        <ArrowLeftOutlined onClick={goBack} />
                        <div className="table-name">
                            {attribute_id === '0'
                                ? 'Add Sub-Attribute'
                                : 'Edit Sub-Attribute'}
                        </div>
                    </div>
                </div>
                <div className="rounded-[6px] border-1 p-8 m-2">
                    {isLoading && (
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            className="md:pr-48"
                            onFinish={handleSubmit}
                            initialValues={{
                                name: subAttributeValueList?.value,
                                hexCode: subAttributeValueList?.hexCode,
                            }}
                        >
                            <div className="flex gap-8 mt-6">
                                <Form.Item
                                    className="w-1/2"
                                    name="name"
                                    label={
                                        <label style={{ color: '#1A3353' }}>
                                            Name *
                                        </label>
                                    }
                                >
                                    <Input
                                        placeholder="Attribute Name"
                                        className="h-[40px]"
                                    />
                                </Form.Item>
                                {subAttributeValueList.image && (
                                    <img
                                        src={subAttributeValueList.image}
                                        alt="brand"
                                        className="w-[200px] h-[200px]"
                                    />
                                )}
                                {attribute === 'shade' ||
                                attributeName === 'shade' ? (
                                    <Form.Item
                                        className="w-1/2"
                                        name="hexCode"
                                        label={
                                            <label style={{ color: '#1A3353' }}>
                                                Hex Code *
                                            </label>
                                        }
                                    >
                                        <Input
                                            placeholder="Hex code"
                                            className="h-[40px]"
                                        />
                                    </Form.Item>
                                ) : null}
                            </div>
                            {attribute == 'brand' && (
                                <Form.Item
                                    name="topBrand"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        className="me-8"
                                        onChange={onChange}
                                        defaultChecked={
                                            subAttributeValueList.isTopBrand ===
                                            true
                                        }
                                    >
                                        Top Brand
                                    </Checkbox>
                                </Form.Item>
                            )}

                            <div className="flex gap-4 mt-6 w-2/5">
                                <Button
                                    htmlType="submit"
                                    className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                >
                                    SAVE CHANGES
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
                {attribute == 'brand' || attributeName == 'brand' ? (
                    <div className="p-8 w-1/2">
                        <label className="w-full text-[#1A3353]">
                            Attribute Name
                        </label>
                        <form
                            className="uploadBox"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <div className="FirstTab border-dashed border-2 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                                <div>
                                    <img
                                        className="m-auto"
                                        src="/icons/common/upload-file.svg"
                                        alt="Upload File"
                                    />
                                    <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                        Drag & drop files or{' '}
                                        <span className="">
                                            <label
                                                htmlFor="img"
                                                className="cursor-pointer text-[#FF8F2F] underline"
                                                onClick={() =>
                                                    document
                                                        .getElementById(
                                                            'getFile'
                                                        )
                                                        .click()
                                                }
                                            >
                                                Browse
                                                <input
                                                    type="file"
                                                    data-max-size="2048"
                                                    id="getFile"
                                                    className="fileIcon invisible"
                                                    onChange={(e) =>
                                                        setFile(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                />
                                            </label>
                                        </span>
                                    </div>
                                </div>

                                <p className="info">
                                    Supported files: JPEG, PNG, PDF
                                </p>
                            </div>

                            {/* <Input className="mt-3 mb-6 w-[100%]" placeholder="Enter Name" /> */}
                        </form>

                        {file !== null ? (
                            <>
                                <div className="drag text-[#3B3B3B] font-semibold mt-2 mb-2 text-[22px]">
                                    Uploaded
                                </div>
                                <p className="mb-6 border-1 p-2 rounded-[6px]">
                                    {file ? file.name : ''}
                                </p>
                            </>
                        ) : msg !== '' ? (
                            <p className="text-[#e96c6c]">{msg}</p>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default AddNewSubAttribute;
