import { Modal } from 'antd';

export const ActiveInactiveModal = ({
    header = 'Change Product Status',
    visible,
    onToggle,
    onCancel,
    text = 'Change the product status',
    ...props
}) => {
    return (
        <>
            <>
                <Modal
                    title={header}
                    className="text-20"
                    open={visible}
                    onCancel={onCancel}
                    footer={false}
                >
                    <div className="flex flex-col gap-8">
                        <div className="text-center text-14">
                            change the product status to{' '}
                            {props.status === 'active' ? 'Inactive' : 'Active'}
                        </div>
                        <div className="flex flex-row justify-end gap-4 font-medium">
                            <button
                                onClick={() => onToggle()}
                                className="rounded-2xl bg-red-400 p-4 text-white"
                            >
                                Change Status
                            </button>
                            <button
                                onClick={onCancel}
                                className="rounded-2xl border p-4 text-black"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        </>
    );
};
