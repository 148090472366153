import { CameraFilled } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const Profile = () => {
    return (
        <div className=" h-full p-8 sm:p-16">
            <div className="h-full sm:flex block">
                <div className="grid place-items-center md:p-8 md:pt-0 sm:w-1/2 relative">
                    <img
                        src="./images/avatars/Avatar.png"
                        className="right-14 top-32"
                        width="250"
                        alt="Logo"
                    />
                    <div className="bg-gray-200 shadow-md absolute right-12 top-48 sm:right-40 sm:top-96 p-2 rounded-full">
                        <CameraFilled
                            className=" "
                            style={{ fontSize: '32px' }}
                        />
                    </div>
                </div>
                <div className="mt-6 sm:mt-0 sm:w-1/2 flex flex-col justify-center">
                    <h2 className="table-name mb-4">Edit Profile</h2>
                    <Form
                        layout="vertical"
                        className="border p-4 rounded bg-white sm:border-0 sm:p-0 sm:rounded-0 sm:bg-transparent"
                    >
                        <div className="flex gap-4">
                            <Form.Item
                                className="w-1/2"
                                label={
                                    <label
                                        className="text-[#1A3353]"
                                        htmlFor="fName"
                                    >
                                        First Name
                                    </label>
                                }
                            >
                                <Input
                                    name="fName"
                                    type="text"
                                    className="h-[35px]"
                                    placeholder="Gayatri"
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                label={
                                    <label
                                        className="text-[#1A3353]"
                                        name="lName"
                                    >
                                        Last Name
                                    </label>
                                }
                            >
                                <Input
                                    name="lName"
                                    type="text"
                                    className="h-[35px]"
                                    placeholder="Grover"
                                />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label={
                                <label
                                    className="text-[#1A3353]"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                            }
                        >
                            <Input
                                name="email"
                                className="h-[35px] w-full"
                                placeholder="gayatrigrover5@gmail.com"
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <label
                                    className="text-[#1A3353]"
                                    htmlFor="address"
                                >
                                    Address
                                </label>
                            }
                        >
                            <Input
                                name="address"
                                className="h-[35px] w-full"
                                placeholder="H.No. 301, Sect 19, Dwarka"
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <label
                                    className="text-[#1A3353]"
                                    htmlFor="contact"
                                >
                                    Contact
                                </label>
                            }
                        >
                            <Input
                                name="contact"
                                className="h-[35px] w-full"
                                placeholder="9876543210"
                            />
                        </Form.Item>
                        <div className="flex gap-4">
                            <Form.Item
                                className="w-1/2"
                                label={
                                    <label
                                        className="text-[#1A3353]"
                                        htmlFor="state"
                                    >
                                        State
                                    </label>
                                }
                            >
                                <Select
                                    placeholder="Select State"
                                    options={[
                                        {
                                            value: 'delhi',
                                            label: 'Delhi',
                                        },
                                        {
                                            value: 'haryana',
                                            label: 'Haryana',
                                        },
                                        {
                                            value: 'rajasthan',
                                            label: 'Rajasthan',
                                        },
                                        {
                                            value: 'punjab',
                                            label: 'Punjab',
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                className="w-1/2"
                                label={
                                    <label
                                        className="text-[#1A3353]"
                                        name="city"
                                    >
                                        City
                                    </label>
                                }
                            >
                                <Input
                                    name="city"
                                    type="text"
                                    className="h-[35px]"
                                    placeholder="Enter city"
                                />
                            </Form.Item>
                        </div>
                        <div className="text-right text-red-600 mb-4 ">
                            <Link to="#">Reset Password</Link>
                        </div>
                        <div className="flex w-1/2 gap-8">
                            <button className="py-2 px-8 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] text-[#FF8F2F] md:text-[.85vw]">
                                Cancel
                            </button>
                            <button className="py-2 px-8 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]">
                                Save
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Profile;
