import { Button, ConfigProvider, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Alertify from '../../scripts/alertify';
import { userResetPassword } from '../../redux/actions/auth-action';
import { useLoader } from '../../hooks/useLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPasswordScreen = () => {
    const [loader, startLoader, endLoader] = useLoader();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();
    const [params, setParams] = useSearchParams();
    const [token, setToken] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        setToken(params?.get('token'));
        return () => {};
    }, [params]);

    function resetPassword() {
        if (!token) {
            return Alertify.error('Sorry! Auth Token is missing');
        }
        if (!password) {
            return Alertify.error('Please, enter new password');
        }
        if (!confirmPassword) {
            return Alertify.error('Please, enter confirm password');
        }
        if (password !== confirmPassword) {
            return Alertify.error('Passwords does not match !');
        }
        startLoader();
        dispatch(userResetPassword({ token, password }))
            .unwrap()
            .then((res) => {
                console.log('reset password response ----->', res.message);
                Alertify.success(res?.message);
                navigate('/login');
            })
            .catch((error) => {
                // console.log('Error catch', error);
            })
            .finally(endLoader);
    }
    return (
        <div className="flex flex-row justify-evenly items-center w-full h-screen">
            <div className="md:h-4/5 md:w-1/2 hidden md:block">
                <img
                    className="h-full m-auto"
                    src="/images/left.png"
                    alt="user"
                />
            </div>
            <div className="flex h-fit md:w-1/2 flex-col rounded-[20px] w-full">
                <div className="bg-[#F4F5F5] px-8 py-16 md:px-16 md:py-8 w-[90%] m-auto rounded-lg">
                    <div className=" flex flex-col gap-1">
                        <h2 className="mb-6 text-18 md:text-[2.5vw] font-bold text-[#1A3353]">
                            Reset Password
                        </h2>
                    </div>

                    <div className="flex flex-col gap-3">
                        <ConfigProvider
                            theme={{
                                components: {
                                    Input: {
                                        hoverBorderColor: 'none',
                                        activeBorderColor: 'none',
                                    },
                                },
                            }}
                        >
                            <div className="flex flex-col gap-1">
                                <label
                                    className="text-12 md:text-14 font-normal text-[#1A3353]"
                                    htmlFor="Name"
                                >
                                    New Password
                                </label>
                                <Input
                                    type="password"
                                    placeholder="New Password"
                                    className="rounded-md px-3 h-[40px] text-[#1A3353]"
                                    onChange={(e) =>
                                        setPassword(e.target.value.trim())
                                    }
                                    value={password}
                                />
                            </div>
                            <div className="flex flex-col gap-3">
                                <label
                                    className="text-12 md:text-14 font-normal text-[#1A3353]"
                                    htmlFor="Name"
                                >
                                    Confirm Password
                                </label>
                                <Input.Password
                                    type="password"
                                    placeholder="Confirm Password"
                                    className="rounded-md px-3 h-[40px] text-[#45494d]"
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.code === 'Enter' && resetPassword()
                                    }
                                    value={confirmPassword}
                                />
                            </div>
                        </ConfigProvider>
                    </div>

                    <div className="mt-5 flex flex-row gap-20 ">
                        <Button
                            className="w-1/2 flex items-center justify-center rounded-[6px] bg-[#FF8F2F] p-4 h-[40px] font-semibold text-white"
                            onClick={resetPassword}
                            loading={loader}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordScreen;
