import { createSlice } from '@reduxjs/toolkit';
import {
    deletePersistStore,
    saveToLocalStorage,
} from '../../utils/localStorage';
import { LogoutUser, userLogin } from '../actions/auth-action';

const initialState = {
    token: '',
    is_login: false,
    user_details: [],
    permissions: [],
    userId:'',
    role: '',
};

const authSlice = createSlice({
    name: 'auth-slice',
    initialState,
    reducers: {
        setLogin: (state, action) => {},
        logOut: (state, action) => {
            state.token = null;
            state.is_login = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.fulfilled, (state, { payload }) => {
            console.log('payload-----------------', payload);
            state.is_login = true;
            state.user_details = payload.data?.admin;
            state.permissions = payload.data?.permissions;
            state.token = payload.data?.accessToken;
            state.role = payload?.data?.role
            state.userId = payload?.data?.admin?._id
            saveToLocalStorage(payload.data.accessToken);
        });
        builder.addCase(LogoutUser.fulfilled, (state) => {
            deletePersistStore();
            return { ...initialState };
        });
    },
});

export const { setLogin, logOut } = authSlice.actions;

export default authSlice.reducer;
