import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CREATE_NEW_INVENTORY,
    INVENTORY_LIST,
    PRODUCT_LIST,
    SEARCH_BY_SKU,
    SEARCH_BY_SKU_DETAILS,
    UPDATE_INVENTORY,
} from '../../constant/api-constant';
import { patchApi, postApi } from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';
import { CloudFilled } from '@ant-design/icons';

export const SearchProductBySku = createAsyncThunk(
    'SearchProductBySku',
    async ({ key, value, page = 1, pageSize = 10, is_search = false }) => {
        try {
            const res = await postApi(SEARCH_BY_SKU, {
                page,
                pageSize,
                search: value,
            });
            return { res, is_search };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const SearchProductBySkuDetails = createAsyncThunk(
    'SearchProductBySku',
    async ({
        key,
        value,
        selectedProductType,
        page = 1,
        pageSize = 10,
        is_search = false,
    }) => {
        try {
            const res = await postApi(SEARCH_BY_SKU_DETAILS, {
                page,
                pageSize,
                search: value,
                productType: selectedProductType,
            });
            return { res, is_search };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const SearchProductBySkuDetailsInProductListing = createAsyncThunk(
    'SearchProductBySkuDetailsInProductListing',
    async ({
        key,
        value,
        selectedProductType,
        page = 1,
        pageSize = 10,
        is_search = false,
    }) => {
        try {
            const res = await postApi(PRODUCT_LIST, {
                page,
                pageSize,
                search: value,
            });
            return { res, is_search };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const InventoryList = createAsyncThunk(
    'store/inventory-list',
    async ({ page = 1, pageSize = 10, search }) => {
        try {
            const response = await postApi(INVENTORY_LIST, {
                page,
                pageSize,
                search,
            });
            console.log('response ', response.data.data.list);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export function _ManipulateInventoryList(data = []) {
    console.log("Data by list-------------", data);
    let inventory_arr = [];
    data.forEach((item) => {
        let obj;
        if (item.productId.length === 0) {
            return;
        }
        if (item.productType === 'simple') {
            obj = {
                productName: item.productId[0]?.name,
                productId: item.productId[0]?._id,
                sku: item.productId[0]?.sku,
                productType: item?.productType,
                batchId: item?.batchId,
                salePrice: item?.salePrice,
                mrp: item?.mrp,
                gst: item.productId[0]?.gst,
                quantity: item?.quantity,
                expiryDate: item?.expiryDate,
                discount: item?.discount,
                id: item?._id,
                discountPrice: item?.discountPrice,
            };
            inventory_arr = [...inventory_arr, obj];
        } else if (item.productType === 'variable') {
            obj = {
                productName: item?.productVariantDetail[0]?.name,
                productId: item?.productId[0]?._id,
                sku: item?.productVariantDetail[0]?.sku,
                productVariantId: item?.productVariantDetail[0]?._id,
                productType: item?.productType,
                batchId: item?.batchId,
                salePrice: item?.salePrice,
                mrp: item?.mrp,
                gst: item?.productId[0]?.gst,
                quantity: item?.quantity,
                expiryDate: item?.expiryDate,
                discount: item?.discount,
                id: item?._id,
                discountPrice: item?.discountPrice,
            };
            inventory_arr = [...inventory_arr, obj];
        }
    });
    return inventory_arr;
}

export function _ManipulateSkuInventoryList(data = []) {
    console.log("Data by search----------", data)
    return data.map((item) => {
        return {
            productName: item.name,
            productId: item._id,
            sku: item.sku,
            productType: item.inventoryData?.productType,
            batchId: item.inventoryData.batchId,
            salePrice: item.inventoryData.salePrice,
            mrp: item.inventoryData.mrp,
            productVariantId:item.inventoryData.productVariantId,
            gst: item.gst,
            quantity: item.inventoryData.quantity,
            expiryDate: item.inventoryData.expiryDate,
            discount: item.inventoryData.discount,
            id: item.inventoryData._id,
            discountPrice: item.inventoryData.discountPrice,
        };
    });
}

export const CreateNewInventory = createAsyncThunk(
    'CreateNewInventory',
    async (inventory_details) => {
        try {
            const res = await postApi(CREATE_NEW_INVENTORY, {
                ...inventory_details,
                salePrice: Number(inventory_details.salePrice),
                mrp: Number(inventory_details.mrp),
                discount: Number(inventory_details.discount),
                quantity: Number(inventory_details.quantity),
                discountPrice:
                    inventory_details.discountPrice &&
                    Number(inventory_details.discountPrice),
            });
            Alertify.success('Inventory created successfully');
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const EditInventory = createAsyncThunk(
    'EditInventory',
    async ({ id, selected_product }) => {
        try {
            const res = await patchApi(`${UPDATE_INVENTORY}/${id}`, {
                ...selected_product,
                salePrice: Number(selected_product.salePrice),
                mrp: Number(selected_product.mrp),
                discount: Number(selected_product.discount),
                quantity: Number(selected_product.quantity),
                discountPrice:
                    selected_product.discountPrice &&
                    Number(selected_product.discountPrice),
            });
            Alertify.success('Inventory updated successfully');
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);
