import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    LOGIN_API,
    RESET_PASSWORD_AFTER_LINK,
    RESET_PASSWORD_LINK,
} from '../../constant/api-constant';
import Alertify from '../../scripts/alertify';
import { postApi } from '../../scripts/api-services';
import { validateEmail, validatePhoneNumber } from '../../scripts/validations';

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ input, password }, { rejectWithValue }) => {
        try {
            let requestData = {};
            if (validateEmail(input)) {
                requestData = { email: input.trim(), password };
            } else if (validatePhoneNumber(input)) {
                requestData = { mobile: input.trim(), password };
            } else {
                return rejectWithValue('Invalid input');
            }
            const response = await postApi(LOGIN_API, requestData);
            return response.data;
        } catch (error) {
            if (error?.statusCode >= 400) {
                return Alertify.error('Wrong Credentials');
            }
            return Promise.reject();
        }
    }
);

export const userForgotPassword = createAsyncThunk(
    'auth/userForgotPassword',
    async ({ input }, { rejectWithValue }) => {
        try {
            let requestData = {
                email: input,
            };
            const response = await postApi(RESET_PASSWORD_LINK, requestData);
            return response.data;
        } catch (error) {
            console.log('error ------->', error);
            if (error?.statusCode >= 400) {
                return Alertify.error(error?.message[0]);
            }
            // return rejectWithValue;
        }
    }
);

export const userResetPassword = createAsyncThunk(
    'auth/userResetPassword',
    async ({ token, password }, { rejectWithValue }) => {
        try {
            let requestData = {
                token: token,
                password: password,
            };
            const response = await postApi(
                RESET_PASSWORD_AFTER_LINK,
                requestData
            );
            return response.data;
        } catch (error) {
            if (error?.statusCode >= 400) {
                return Alertify.error(error?.message[0]);
            }
            return Promise.reject();
        }
    }
);

export const LogoutUser = createAsyncThunk('/logout', async () => {
    try {
        return Promise.resolve();
    } catch (error) {
        console.error(error);
    }
});

// Selectors Functions
// getUserSelector()
// return the derived state
