import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    ConfigProvider,
    Form,
    Input,
    Modal,
    Radio,
    Select,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAppSelector from '../../hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import {
    CreateCrazyDeals,
    EditCrazyDeals,
    GetCrazyDealsDetails,
} from '../../redux/actions/crazy-deals-actions';
import { UploadImages } from '../../redux/actions/product-action';
import { ResetCrazyDealsValues } from '../../redux/slices/crazy-deals-slice';
import { GetThirdLevelCategories } from '../../redux/actions/category-action';

const CrazyDealsDetail = () => {
    const { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [image_modal, setToggleImageModal] = useState(false);
    const [bannerImage, setImageUrl] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [brandId, setBrandId] = useState(null);
    const [thirdLevelCategory, setThirdLevelCategory] = useState(null);
    const dispatch = useDispatch();
    const store = useAppSelector((state) => ({
        brands: state.container_store.brands_data,
        primary_category: state.container_store.categories,
        bannerImage: state.crazydeals_store.bannerImage,
        bannerName: state.crazydeals_store.bannerName,
        minDiscount: state.crazydeals_store.minDiscount,
        maxDiscount: state.crazydeals_store.maxDiscount,
    }));

    //! first level category
    // const memoized_category = useMemo(() => {
    //     return store.primary_category.map((item) => ({
    //         label: item.name,
    //         value: item._id,
    //     }));
    // }, [store.primary_category]);

    //! third level category
    const memoized_category = useMemo(() => { });

    const [selectedValue, setSelectedValue] = useState('brand');
    const handleChange = (e) => {
        console.log(e.target.value);
        setSelectedValue(e.target.value);
    };

    function goBack() {
        window.history.back();
    }

    function handleImageChange(e) {
        setFile(e.target.files[0]);
        toggleImageUploadModal(true);
    }

    function toggleImageUploadModal(state) {
        setToggleImageModal(state);
    }
    console.log("brand id in select tag------------", brandId);

    function uploadImage() {
        dispatch(UploadImages({ images: [file] }))
            .unwrap()
            .then(({ res }) => {
                setImageUrl(res.data.data.urls[0]);
                setToggleImageModal(false);
            })
            .catch(() => { });
    }

    function handleSubmit(form) {
        console.log(form);
        const data = {
            bannerImage,
            minDiscount: Number(form.minDiscount),
            maxDiscount: Number(form.maxDiscount),
            types: selectedValue,
            bannerName: form.bannerName,
        };

        if (selectedValue === 'brand') {
            data['brandId'] = brandId;
        } else if (selectedValue === 'category') {
            data['categoryId'] = categoryId;
        }

        if (id === '0') {
            dispatch(
                CreateCrazyDeals({
                    payload: data,
                })
            );
        } else if (id !== '0') {
            dispatch(
                EditCrazyDeals({
                    payload: data,
                    id,
                })
            );
        }
    }

    useEffect(() => {
        dispatch(ResetCrazyDealsValues());
        dispatch(GetThirdLevelCategories())
            .unwrap()
            .then((data) => {
                setThirdLevelCategory(data?.res?.data);
            })
            .catch(() => { });
        if (id !== '0') {
            dispatch(GetCrazyDealsDetails({ id }))
                .unwrap()
                .then(({ res }) => {
                    setSelectedValue(res?.data?.types);
                    setImageUrl(res?.data?.bannerImage);
                    if (res?.data?.types === 'category') {
                        setCategoryId(res?.data?.categoryId?._id);
                    } else {
                        setBrandId(res?.data?.brandId?._id); // what i have changed is, i have put _id in front of brandId  
                    }
                    setLoaded(true);
                })
                .catch();
        } else {
            setLoaded(true);
        }
    }, []);

    const [file, setFile] = useState(null);


    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <div className="flex gap-4 items-center p-4 border-b-1">
                <Link to="#">
                    <ArrowLeftOutlined onClick={goBack} />
                </Link>
                <h1 className="table-name">Create/Edit Banner</h1>
            </div>
            <div className="p-4 mt-2 rounded-lg">
                <ConfigProvider
                    theme={{
                        components: {
                            Radio: {
                                buttonBg: '#000',
                                buttonCheckedBg: '#FF8F2F',
                                radioSize: 20,
                            },
                        },
                    }}
                >
                    <Radio.Group
                        name="radiogroup"
                        value={selectedValue}
                        onChange={handleChange}
                    >
                        <Radio value={'brand'}>Discount on Brand</Radio>
                        <Radio value={'category'}>Discount on Category</Radio>
                    </Radio.Group>
                </ConfigProvider>
                <div className="border-1 rounded-lg p-8 mt-6">
                    {loaded && (
                        <>
                            <Form
                                layout="vertical"
                                onFinish={handleSubmit}
                                initialValues={{
                                    bannerName: store.bannerName,
                                    minDiscount: store.minDiscount,
                                    maxDiscount: store.maxDiscount,
                                    categoryId,
                                }}
                            >
                                <Form.Item
                                    className="w-4/5"
                                    name="bannerName"
                                    label={
                                        <label className="text-[#1A3353]">
                                            Banner Name *
                                        </label>
                                    }
                                    rules={[
                                        { required: true, message: 'Please enter banner name' }
                                    ]}
                                >
                                    <Input
                                        // onChange={}
                                        className="h-[40px]"
                                        placeholder="Banner Name"
                                    />
                                </Form.Item>
                                <div className="flex gap-8 w-4/5">
                                    {selectedValue === 'brand' ? (
                                        <div className="w-1/2">
                                            <label className="text-[#1A3353]">
                                                Brand
                                            </label>
                                            <Select
                                                className="h-[40px] w-[250px]"
                                                placeholder="Please Select Brands"
                                                options={store.brands.map((brand) => ({
                                                    label: brand.value,
                                                    value: brand._id,
                                                    key: brand._id,
                                                }))}
                                                value={brandId}
                                                onChange={(value, options) => setBrandId(value)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div className="w-1/2">
                                            <label className="text-[#1A3353]">
                                                Category
                                            </label>

                                            {/* Keep in mind only the third level categories will go here in this select box */}
                                            <Select
                                                className="h-[40px] w-[250px]"
                                                placeholder="Please Select Category"
                                                value={categoryId}
                                                // options={third}
                                                onChange={(val) =>
                                                    setCategoryId(val)
                                                }
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1
                                                }
                                            >
                                                {thirdLevelCategory?.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item._id}
                                                            value={item._id}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </div>
                                    )}

                                    <Form.Item
                                        className="w-1/2"
                                        name="minDiscount"
                                        label={
                                            <label className="text-[#1A3353]">
                                                Minimum Discount Percentage *
                                            </label>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter minimum discount percentage',
                                            },
                                            {
                                                validator: (_, value) =>
                                                    value > 0 ||
                                                        value === undefined
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                            'Minimum discount percentage must be greater than 0'
                                                        ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            className="h-[40px]"
                                            placeholder="Enter discount value only"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className="w-1/2"
                                        name="maxDiscount"
                                        label={
                                            <label className="text-[#1A3353]">
                                                Maximum Discount Percentage *
                                            </label>
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter maximum discount percentage',
                                            },
                                            {
                                                validator: (_, value) =>
                                                    value < 100
                                                        ? Promise.resolve()
                                                        : Promise.reject(
                                                            'Maximum discount percentage must be less than 100'
                                                        ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            className="h-[40px]"
                                            placeholder="Enter discount value only"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="flex gap-8 mt-6 w-4/5">
                                    <div className="flex flex-col gap-4 w-1/2">
                                        <p className="table-name text-[1vw] font-normal">
                                            Banner Image *
                                        </p>
                                        <div className="w-full FirstTab border-dashed border-2 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                                            <div>
                                                <img
                                                    className="m-auto"
                                                    src="/icons/common/upload-file.svg"
                                                    alt="Upload File"
                                                />
                                                <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                                    Drag & drop files or{' '}
                                                    <span className="">
                                                        <label
                                                            htmlFor="img"
                                                            className="cursor-pointer text-[#FF8F2F] text-[22px] underline"
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        'getFile'
                                                                    )
                                                                    .click()
                                                            }
                                                        >
                                                            Browse
                                                            <input
                                                                type="file"
                                                                data-max-size="2048"
                                                                id="getFile"
                                                                className="fileIcon invisible"
                                                                onChange={
                                                                    handleImageChange
                                                                }
                                                            />
                                                        </label>
                                                    </span>
                                                    <span className="text-[14px] font-normal">
                                                        Image should be portrait
                                                        with 9:16 ratio
                                                    </span>
                                                </div>
                                            </div>

                                            <p className="info">
                                                Supported files: JPEG, PNG
                                            </p>
                                        </div>
                                        {bannerImage && (
                                            <>
                                                <div>
                                                    <img
                                                        alt="banner"
                                                        src={bannerImage}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex gap-4 mt-6 w-2/5 px-8">
                                    <Button
                                        htmlType="submit"
                                        className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                    // loading={btn_loader}
                                    >
                                        SAVE CHANGES
                                    </Button>
                                    <Button
                                        htmlType="reset"
                                        className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                        onClick={goBack}
                                    >
                                        CANCEL
                                    </Button>
                                </div>
                            </Form>
                        </>
                    )}
                </div>
            </div>
            {/* Image upload modals opens when the variant is choosed  */}
            <ImageVariantModal
                open={image_modal}
                onCancel={toggleImageUploadModal}
                data={file}
                centered
                onOk={uploadImage}
            />
        </div>
    );
};

export default CrazyDealsDetail;

const ImageVariantModal = ({ open, onCancel, data, ...restProps }) => {
    return (
        <>
            <Modal open={open} onCancel={() => onCancel(false)} {...restProps}>
                <div>Image Preview:-</div>
                {data && (
                    <div>
                        <img
                            src={URL.createObjectURL(data)}
                            className="w-20 h-20"
                            alt="variant"
                        />
                    </div>
                )}
            </Modal>
        </>
    );
};
