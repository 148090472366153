import { CameraFilled } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomersDetails } from '../../redux/actions/user-action';

const DetailsCustomer = ({ ...props }) => {
    // console.log('props', props);
    const { id } = useParams();
    const dispatch = useDispatch();
    const customerData = useSelector(
        (state) => state.customer_store.singleCustomerDetails
    );

    useEffect(() => {
        dispatch(CustomersDetails(id));
    }, []);

    return (
        <>
            <div className="p-4">
                <div className="flex flex-wrap">
                    <div className="md:w-1/2">
                        {props.title ? (
                            <h1 className="table-name border-b-4 border-[#FF8F2F] w-fit pb-3 mb-6">
                                {props.title}
                            </h1>
                        ) : null}

                        {customerData &&
                            Object.entries(customerData).map(([key, value]) =>
                                key === '__v' ||
                                key === 'ERPId' ||
                                key === 'picture' ||
                                key === 'createdAt' ||
                                key === 'updatedAt' ||
                                key === 'mobileVerified' ||
                                key === 'emailVerified' ? null : (
                                    <div className="flex" key={key}>
                                        <div className="w-1/2 font-semibold pr-5 py-2 text-[#1A3353]">
                                            {key}
                                        </div>
                                        <div className="w-1/2 pr-5 py-2 text-[#455560]">
                                            <span className="mr-2">:</span>
                                            {[
                                                'dob',
                                                'engagement',
                                                'wedding',
                                                'createdAt',
                                                'updatedAt',
                                            ].includes(key)
                                                ? new Date(
                                                      value
                                                  ).toLocaleDateString('en-GB')
                                                : value}
                                        </div>
                                    </div>
                                )
                            )}

                        {/* <div className="flex justify-between w-80">
<h1>Id:</h1>
<h1>{customerData._id}</h1>
</div> */}
                    </div>
                    <div className="relative grid items-start justify-end w-1/2 md:p-8 md:pt-0">
                        {customerData?.picture ? (
                            <img
                                src={customerData?.picture}
                                alt="Logo"
                                className="w-40 rounded-full right-14 top-12 aspect-square"
                                style={{ resize: 'block' }}
                            />
                        ) : (
                            <h1>No image available! </h1>
                        )}

                        <img
                            src="/images/avatars/Avatar.png"
                            className="absolute hidden right-14 top-32"
                            width="40"
                            alt="Logo"
                        />
                        <div className="bg-gray-200 shadow-md absolute right-10 top-[7.5rem] p-2 rounded-full  ">
                            <CameraFilled
                                className=""
                                style={{ fontSize: '32px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailsCustomer;
