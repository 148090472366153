import { createSlice } from '@reduxjs/toolkit';
import { AttributeListData } from '../actions/attribute-action';
import { _ManipulateMappingAttribute } from '../actions/container-actions';
import { GetCrazyDeals } from '../actions/crazy-deals-actions';
import {
    CreateBanner,
    GetBannerList,
    ManipualateBannerData,
} from '../actions/banner-action';

const initialState = {
    bannerList: [],
    bannerList_count: null,
};

const bannersSlice = createSlice({
    name: 'banner_store',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetBannerList.fulfilled, (state, { payload }) => {
            // console.log("Payload---------------", payload)
            state.bannerList_count = payload?.data?.count;
            state.bannerList = ManipualateBannerData(payload?.data?.list);
        });
        builder.addCase(CreateBanner.fulfilled, (state, { payload }) => {
            // console.log("Payload---------------", payload)
            // state.bannerList_count = payload?.data?.count;
            // state.bannerList = ManipualateBannerData(payload?.data?.list);
        });
    },
});

export const { Test } = bannersSlice.actions;
export default bannersSlice.reducer;
