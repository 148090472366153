import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";

const PrescriptionDetails = (props) => {
  return (
    <div className="p-8">
      <div className="flex mb-8 gap-4 border-b-1 pb-4">
        <ArrowLeftOutlined onClick={props.toggleDiv} />

        <div className="table-name">Upload Prescription</div>
      </div>
      <div className="table-name">RX</div>
      <img
        src={props.modalImage}
        alt="Prescription"
        className="border-1 mt-4"
      />
    </div>
  );
};

export default PrescriptionDetails;
