import { createAsyncThunk } from '@reduxjs/toolkit';
import { uniqueId } from '../../utils/utils';
import {
    CATEGORY_LIST,
    DELETE_IMAGE,
    GET_BRANDS_DATA,
} from '../../constant/api-constant';
import { deleteApi, getApi, postApi } from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';

export function _ManipulateMappingAttribute(data) {
    if (Object.keys(data).length > 0) {
        return Object.entries(data).reduce(
            (acc, [key, value]) => {
                if (value.type === 'optional') {
                    acc['optional'].push({
                        label: value.name,
                        value: key,
                        multiple: value.multiple,
                        inputType: value.inputType,
                        id: uniqueId(),
                    });
                } else if (value.type === 'variant') {
                    acc['variant'].push({
                        label: value.name,
                        value: key,
                        multiple: value.multiple,
                        inputType: value.inputType,
                        id: uniqueId(),
                    });
                } else if (value.type === 'mandatory') {
                    acc['mandatory'].push({
                        label: value.name,
                        value: key,
                        multiple: value.multiple,
                        inputType: value.inputType,
                        id: uniqueId(),
                    });
                }
                return acc;
            },
            {
                optional: [],
                variant: [],
                mandatory: [],
            }
        );
    }
    return {};
}

export const GetPrimaryCategories = createAsyncThunk(
    'GetPrimaryCategories',
    async () => {
        try {
            const res = await postApi(CATEGORY_LIST, {
                page: 1,
                pageSize: 10,
                level: 'first',
            });
            return { res };
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }
);

export const GetBrandsData = createAsyncThunk('GetBrandsData', async () => {
    try {
        const res = await getApi(GET_BRANDS_DATA);
        return { res };
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
});

export const DeleteImage = createAsyncThunk(
    'DeleteImage',
    async ({ url }, { dispatch, getState, rejectWithValue }) => {
        try {
            const res = await postApi(DELETE_IMAGE, { url });
            Alertify.success('Image is deleted');
            return { res };
        } catch (error) {
            Alertify.error(error.message[0]);
            return rejectWithValue(error.message[0]);
            // return Promise.reject();
        }
    }
);
