/*--------------Customer List --------------------*/

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ADD_NEW_CATEGORY,
    CATEGORY_EXPORT,
    CATEGORY_LIST,
    CREATE_FILTER,
    DELETE_CATEGORY,
    DELETE_FILTER,
    GET_CATEGORY,
    GET_CATEGORY_DETAILS_BY_ID,
    GET_FILTER_LIST,
    GET_THIRD_LEVEL_CATEGORY,
    UPDATE_CATEGORY,
    UPDATE_FILTER,
} from '../../constant/api-constant';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import { Link } from 'react-router-dom';
import Alertify from '../../scripts/alertify';
// import store from '../store';

export const CategoryListData = createAsyncThunk(
    'category/category-list',
    async ({
        search,
        page = 1,
        pageSize = 10,
        parentId,
        level,
        filter,
    }) => {
        try {
            const data = {};
            // if (level !== 'second') {
                data['page'] = page;
                data['pageSize'] = pageSize;
                data['search'] = search;
            // }
            const res = await postApi(CATEGORY_LIST, {
                ...data,
                parentId,
                level,
                filter
            });

            return { res, level };
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const PrimaryCategoryListData = createAsyncThunk(
    'category/primary-category-list',
    async ({
        key = 'search',
        value = '',
        page = 1,
        pageSize = 10,
        parentId,
        level = 'first',
    }) => {
        try {
            const res = await postApi(CATEGORY_LIST, {
                [key]: value,
                page,
                pageSize,
                parentId,
                level,
            });
            // console.log("Response-----------", response)
            return { res, level };
        } catch (error) {
            console.error('Error:', error);
            return Promise.reject();
        }
    }
);

/*------------------Create Categories-------------*/

export const CreateCategory = createAsyncThunk(
    'category/create-category',
    async (category_data) => {
        try {
            const form_data = new FormData();
            Object.entries(category_data).forEach(([key, value]) =>
                form_data.append(key, value)
            );
            const response = await postApi(ADD_NEW_CATEGORY, form_data, {
                'Content-Type': 'multipart/form-data',
            });
            Alertify.success('Category created successfully');
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const CategoryExport = createAsyncThunk(
    'productExport',
    async ({ level }) => {
        try {
            const res = await postApi(CATEGORY_EXPORT, {
                level:[level]
            });
            Alertify.success('Category exported successfully');
            return res.data;
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*------------------Update Categories-------------*/

export const UpdateCategory = createAsyncThunk(
    'category/update-category',
    async ({ id, ...category_data }) => {
        try {
            const form_data = new FormData();
            Object.entries(category_data).forEach(([key, value]) =>
                form_data.append(key, value)
            );
            const response = await patchApi(
                `${UPDATE_CATEGORY}/${id}`,
                form_data,
                {
                    'Content-Type': 'multipart/form-data',
                }
            );
            // console.log("Response-----------", response)
            Alertify.success('updated successfully');
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

/*------------------Manipulate Categories-------------*/

export function ManipulateCategoryLists(data) {
    return data?.map((item) => {
        return {
            key: item?._id,
            id:item?._id,
            name: item.name,
            slug: item.slug || '-',
            parentCategory: item.parentId?.name || '-',
        };
    });
}

export const CreateFilter = createAsyncThunk(
    'CreateFilter',
    async (
        {
            categoryId,
            categoryLevel = 'first',
            order,
            id,
            attribute,
            type = false,
        },
        { dispatch }
    ) => {
        try {
            let res;
            if (!type) {
                res = await postApi(CREATE_FILTER, {
                    categoryId,
                    categoryLevel,
                    order,
                    attribute,
                });
            } else {
                res = await patchApi(`${UPDATE_FILTER}/${id}`, {
                    categoryId,
                    categoryLevel,
                    order,
                    attribute,
                });
            }

            dispatch(GetFilterList({ categoryId }));
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0] ?? '');
            return Promise.reject();
        }
    }
);

export const GetFilterList = createAsyncThunk(
    'GetFilterList',
    async ({
        categoryId,
        categoryLevel = 'first',
        page = 1,
        pageSize = 10,
    }) => {
        try {
            const res = await postApi(GET_FILTER_LIST, {
                categoryId,
                categoryLevel,
                page,
                pageSize,
            });
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const DeleteFilter = createAsyncThunk(
    'DeleteFilter',
    async ({ id, categoryId }, { dispatch }) => {
        try {
            const res = await deleteApi(`${DELETE_FILTER}/${id}`);
            dispatch(GetFilterList({ categoryId }));
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export function ManipulateFiltersList(data, attributes) {
    // const attributes = store.getState().category_store.attributes_object;
    // const attributes = [];
    return data?.map((item) => {
        return {
            key: item._id,
            name: attributes[item.attribute],
            attribute: item.attribute,
            order: item.order,
            categoryId: item.categoryId,
        };
    });
}

export const DeleteCategory = createAsyncThunk(
    'DeleteCategory',
    async ({ id }, { dispatch }) => {
        try {
            const res = await deleteApi(`${DELETE_CATEGORY}/${id}`);
            Alertify.success('Category deleted successfully');
            dispatch(CategoryListData({}));
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const CategoriesDetailsById = createAsyncThunk(
    'CategoriesDetailsById',
    async ({ id }) => {
        try {
            const res = await getApi(`${GET_CATEGORY_DETAILS_BY_ID}/${id}`);
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const EditCategoriesDetails = createAsyncThunk(
    'EditCategoriesDetails',
    async ({ id }) => {
        try {
            const res = await patchApi(`${GET_CATEGORY_DETAILS_BY_ID}/${id}`);
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);

export const GetThirdLevelCategories = createAsyncThunk(
    'GetThirdLevelCategories',
    async () => {
        try {
            const res = await getApi(GET_THIRD_LEVEL_CATEGORY);
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject(error);
        }
    }
);
