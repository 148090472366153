import React, { useState } from 'react';
import SimpleTable from '../tables/simpletable';
import { Modal } from 'antd';
import UploadPrescription from '../userDetails/uploadPrescription';

const OrderPrescription = ({ prescriptionData, isPrescriptionRequired }) => {
    const [uploadModal, setUploadModal] = useState(false);
    const showUploadModal = () => {
        setUploadModal(!uploadModal);
    };
    const closeModal = () => {
        setUploadModal(false);
    };

    const [uploadModal1, setUploadModal1] = useState(false);
    const [modalImage, setModalImage] = useState();

    console.log('modalImage', modalImage);

    const showUploadModal1 = (image) => {
        console.log('img', image);
        setUploadModal1(!uploadModal1);
        setModalImage(image);
    };
    const closeModal1 = () => {
        setUploadModal1(false);
    };
    const columns = [
        {
            title: 'INDENT NO',
            dataIndex: 'prescriptionImage',
            render: (text, record, index) => (
                <p
                    className="text-[#5757f0] cursor-pointer"
                    onClick={() => showUploadModal1(record.prescriptionImage)}
                >
                    {index + 1}
                </p>
            ), // Displaying index + 1 (1-indexed) or index (0-indexed) depending on your preference
        },
        {
            title: 'FILE NAME',
            dataIndex: 'prescriptionImage',
            render: (prescriptionImage) => {
                const trimmedUrl = prescriptionImage.replace(
                    'https://aakash-life-web.s3.ap-south-1.amazonaws.com/prescription/',
                    ''
                );
                return (
                    <p
                        className="text-[#5757f0] cursor-pointer"
                        onClick={() => showUploadModal1(prescriptionImage)}
                    >
                        {trimmedUrl}
                    </p>
                );
            },
        },

        { title: 'PRESCRIPTION NAME', dataIndex: 'name' },
    ];
    return (
        <>
            <SimpleTable
                tableName="Prescriptions"
                showUploadModal={showUploadModal}
                isPrescriptionRequired={isPrescriptionRequired}
                uploadPrescription={true}
                uploadPrescriptionLink="/"
                search={false}
                columns={columns}
                dataSource={prescriptionData}
            />
            <Modal
                centered
                open={uploadModal1}
                footer={false}
                onCancel={closeModal1}
            >
                <div className="p-8">
                    <div className="text-center table-name">Prescription</div>
                    {modalImage?.endsWith('.pdf') ? (
                        <iframe src={modalImage} loading="lazy"></iframe>
                    ) : (
                        <img
                            src={modalImage}
                            alt="Prescription"
                            className="mx-auto mt-4 border-1"
                        />
                    )}
                </div>
            </Modal>
            {/* <PrescriptionDetails toggleDiv={toggleDiv} /> */}

            <Modal
                centered
                open={uploadModal}
                footer={false}
                onCancel={closeModal}
                className=""
            >
                <UploadPrescription uploadModal={closeModal} />
            </Modal>
        </>
    );
};

export default OrderPrescription;
