import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';
import {
    createDeliveryPartner,
    deliveryRole,
    updateDeliveryPartner,
} from '../../redux/actions/user-action';

import Alertify from '../../scripts/alertify';

const AddNewRoleUser = ({ closeModal, user_details }) => {
    const dispatch = useDispatch();
    console.log({ user_details });

    const listId = user_details._id;
    const [role, setRole] = useState(user_details?.roles?.[0] ?? null);

    const [deliveryRoleId, setDeliveryRoleId] = useState([]);
    const [form] = Form.useForm();

    const handleSelectChange = (e, v) => {
        setRole({ _id: v.value, name: v.children });
    };

    useEffect(() => {
        dispatch(deliveryRole()).then((res) => {
            const fetchedDeliveryRoleId = res?.payload?.data?.list;
            setDeliveryRoleId(fetchedDeliveryRoleId);
        });
    }, [dispatch]);

    const createNewDeliveryPartner = (form) => {
        console.log({ form });
        !listId
            ? dispatch(
                  createDeliveryPartner({
                      form: {
                          ...form,
                          mobile: String(form.mobile),
                          roles: [role?._id],
                      },
                  })
              )
                  .unwrap()
                  .then(() => {
                      Alertify.success('User created successfully');
                      closeModal();
                  })
            : dispatch(
                  updateDeliveryPartner({
                      id: listId,
                      form: {
                          ...form,
                          mobile: String(form.mobile),
                          roles: [role._id],
                      },
                      role,
                  })
              )
                  .unwrap()
                  .then(() => {
                      Alertify.success('User created successfully');
                      closeModal();
                  });
    };

    return (
        <>
            <Form
                form={form}
                onFinish={createNewDeliveryPartner}
                layout="vertical"
                autoComplete="off"
                initialValues={{
                    name: user_details?.name,
                    mobile: user_details?.mobile,
                    email: user_details?.email,
                    roles: user_details?.roles?.[0].name,
                }}
            >
                <div className="flex gap-8 ">
                    <Form.Item
                        className="w-1/2"
                        name="id"
                        label={<label style={{ color: '#1A3353' }}>ID</label>}
                    >
                        <Input
                            placeholder="ABC123"
                            className="h-[40px]"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        className="w-1/2"
                        name="name"
                        label={<label style={{ color: '#1A3353' }}>Name</label>} rules={[
                            {
                                required: true,
                                message: 'Please enter your name',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Name" className="h-[40px]" />
                    </Form.Item>
                </div>
                <div className="flex gap-8">
                    <Form.Item
                        name="mobile"
                        label={
                            <label style={{ color: '#1A3353' }}>
                                Mobile Number
                            </label>
                        }
                        className="w-1/2"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your mobile number',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter mobile number"
                            className="h-[40px]"
                            type='mobile'
                            maxLength={10}
                        />
                    </Form.Item>
                    <Form.Item
                        className="w-1/2"
                        name="email"
                        label={
                            <label style={{ color: '#1A3353' }}>
                                Email Address
                            </label>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your email address',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter email address"
                            className="h-[40px]"
                        />
                    </Form.Item>
                </div>

                <div className="flex gap-8">
                    <Form.Item
                        className="w-1/2"
                        name="password"
                        label={
                            <label style={{ color: '#1A3353' }}>Password</label>
                        }
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password',
                            },
                            {
                                type: 'password',
                                message: 'Please enter a valid password',
                            },
                        ]}
                    >
                        <Input placeholder="Password" className="h-[40px]" />
                    </Form.Item>
                    <div className="w-1/2">
                        <label style={{ color: '#1A3353' }}>
                            Select any role
                        </label>
                        <Select
                            defaultValue="Select any role"
                            style={{
                                width: 400,
                                height: 40,
                                marginTop: 10,
                            }}
                            onChange={(e, v) => handleSelectChange(e, v)}
                            value={role?._id}
                        >
                            {deliveryRoleId.map((item) => (
                                <Select.Option key={item._id} value={item._id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className="flex gap-4 w-1/2 mt-6">
                    <Button
                        htmlType="submit"
                        className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                    >
                        SAVE CHANGES
                    </Button>
                    <Button
                        onClick={() => {
                            closeModal();
                        }}
                        htmlType="reset"
                        className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                    >
                        CANCEL
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default AddNewRoleUser;
