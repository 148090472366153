import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SimpleTable from '../../../components/tables/simpletable';
import { useDispatch, useSelector } from 'react-redux';
import {
    orderDetail,
    orderStatus,
    returnReasonOrder,
} from '../../../redux/actions/order-action';
import OrderPrescription from '../../../components/order/orderPrescription';
import { getPrescriptionByOrder } from '../../../redux/actions/prescription-action';
import TextArea from 'antd/es/input/TextArea';
import toast from 'react-hot-toast';
import { roleOptions } from '../../../components/roleOptions';
import { setSelectedStatus } from '../../../redux/slices/order-slice';

const OrderDetails = () => {
    const dispatch = useDispatch();
    const { _id } = useParams();
    const role = useSelector((state) => state.auth_store.role);
    const ordersDetail = useSelector((state) => state?.order?.orderDetail);
    const selectedStatus = useSelector((state) => state?.order?.selectedStatus);
    const [value, setValue] = useState('');
    const prescription = useSelector(
        (state) => state?.prescription_store?.getPrescription?.prescription
    );
    const deliveryData = useSelector(
        (state) => state.customer_store.delivery_partner
    );
    const [orderedItemList, setOrderedItemList] = useState([]);
    const orderDetailData = ordersDetail?.[0];
    const [returnReason, setReturnReason] = useState('');
    const [otherReasonText, setOtherReasonText] = useState('');

    console.log('id----------', _id);

    useEffect(() => {
        dispatch(orderDetail(_id)).then((res) => {
            setOrderedItemList(res?.payload[0]?.orderedItems);
            dispatch(getPrescriptionByOrder(res?.payload[0]?._id));
        });
        setValue(orderDetailData?.status);
    }, []);

    function goBack() {
        window.history.back();
    }

    const columns = [
        {
            title: 'PRODUCT',
            dataIndex: 'productId',
            render: (productId) => <span>{productId?.name}</span>,
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            align: 'center',
        },
        {
            title: 'SIZE/SHADE',
            align: 'center',
            dataIndex: 'size',
        },
        {
            title: 'HSN',
            align: 'center',
            dataIndex: 'productId',
            render: (productId) => <span>{productId?.hsn}</span>,
        },
        {
            title: 'GST%',
            align: 'center',
            dataIndex: 'gst',
        },
        {
            title: 'SKU',
            align: 'center',
            dataIndex: 'productId',
            render: (productId) => <span>{productId?.sku}</span>,
        },
        {
            title: 'UNIT PRICE',
            align: 'center',
            dataIndex: 'mrp',
            render: (mrp) => <span>{mrp.toFixed(2)}</span>,
        },
        {
            title: 'DISCOUNT',
            align: 'center',
            dataIndex: '',
        },
        {
            title: 'FINAL PRICE',
            align: 'center',
            dataIndex: 'totalDiscountedAmount',
            render: (totalDiscountedAmount) => (
                <span>{totalDiscountedAmount.toFixed(2)}</span>
            ),
        },
        // {
        //     title: 'PRESCRIPTION',
        //     dataIndex: 'prescriptionRequired',
        //     align: 'center',
        //     render: (prescriptionRequired) => {
        //         console.log("value of prescription required", prescriptionRequired);
        //         return (
        //             <span>
        //                 {prescriptionRequired === true ?
        //                     <img src="/images/prescription/Prescription_Required_logo.png" alt="Prescription Required" /> :
        //                     "--"
        //                 }
        //             </span>
        //         );
        //     },
        // },
        {
            title: 'RETURN/REFUND REASON',
            dataIndex: 'returnReason',
            render: (returnReason) => (
                <span
                    className="text-[#F40000] cursor-pointer"
                    onClick={showReason}
                >
                    {returnReason?.length > 20
                        ? `${returnReason?.slice(0, 19)}...`
                        : returnReason}
                </span>
            ),
        },
        // {
        //     title: 'STATUS',
        //     dataIndex: 'status',
        //     render: (status) => (
        //         <span className="text-[#82B40D]">Dispatch</span>
        //     ),
        // },
        {
            title: 'REFUND AMOUNT',
            dataIndex: 'refundAmount',
        },
    ];

    const returnReasonValue = ordersDetail?.map((order) =>
        order.orderedItems?.map((item) => item.returnReason)
    );

    // Flattening the array if needed
    const allReturnReasons = returnReasonValue.flat();

    const [openModal, setOpenModal] = useState(false);
    const showReason = () => {
        setOpenModal(true);
    };
    const closeModal = () => {
        setOpenModal(false);
    };

    const handleSubmit = async (id) => {
        if (returnReason === 'Other_Reason') {
            await dispatch(
                returnReasonOrder({
                    productId: id,
                    orderId: _id,
                    returnReason: otherReasonText,
                })
            ).then((res) => {
                toast.success('Return request submitted successfully');
                setReturnReason('');
                setOtherReasonText('');
                setOpenModal(false);
            });
        } else {
            await dispatch(
                returnReasonOrder({ productId: id, orderId: _id, returnReason })
            ).then((res) => {
                toast.success('Return request submitted successfully');
                setReturnReason('');
                setOtherReasonText('');
                setOpenModal(false);
            });
        }
    };

    const required = ordersDetail[0]?.populatedOrderedItems?.map(
        (item) => item.prescriptionRequired
    );

    const anyPrescriptionRequired = required?.some(
        (isRequired) => isRequired === true
    );

    const handleChange = (selectedValue, orderId) => {
        setValue(selectedValue);
        dispatch(setSelectedStatus({ value: selectedValue, orderId }));
        dispatch(orderStatus({ value: selectedValue, orderId }));
    };

    function formatDate(dateString) {
        if (!dateString || isNaN(Date.parse(dateString))) {
            return '';
        }
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }
    const options = roleOptions[role] || [];

    return (
        <>
            <div className="bg-white pb-2 rounded-[6px]">
                <div className="flex justify-between p-4 items-center border-b-1">
                    <div className="flex gap-5 items-center">
                        <Link to="#">
                            <ArrowLeftOutlined onClick={goBack} />
                        </Link>
                        <h1 className="table-name">
                            Order ID #{orderDetailData?._id}
                        </h1>

                        <Link
                            to={`/users/user-details/${orderDetailData?.userId?._id}`}
                        >
                            <div
                                id=""
                                // onClick={props.showUploadModal}
                                className="py-1 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                            >
                                View Timeline
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="md:flex gap-4 mt-6 justify-center p-4 md:text-[.8vw]">
                    <div className="border-1 md:w-1/3 p-4 rounded-md">
                        <div className="flex">
                            <div className="w-1/3 py-2 flex justify-between">
                                <span className="font-semibold text-[#1A3353]">
                                    Name
                                </span>
                                <span className="mr-2">:</span>
                            </div>
                            <div className="w-2/3 py-2 text-[#455560]">
                                {orderDetailData?.userId?.firstName}{' '}
                                {orderDetailData?.userId?.lastName}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-1/3 py-2 flex justify-between">
                                <span className="font-semibold text-[#1A3353]">
                                    Email
                                </span>
                                <span className="mr-2">:</span>
                            </div>
                            <div
                                className="w-2/3 py-2 text-[#455560]"
                                style={{ overflowWrap: 'break-word' }}
                            >
                                {orderDetailData?.userId?.email}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-1/3 py-2 flex justify-between">
                                <span className="font-semibold text-[#1A3353]">
                                    Mobile
                                </span>
                                <span className="mr-2">:</span>
                            </div>
                            <div
                                className="w-2/3 py-2 text-[#455560]"
                                style={{ overflowWrap: 'break-word' }}
                            >
                                {orderDetailData?.userId?.mobile}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-1/3 py-2 flex justify-between">
                                <span className="font-semibold text-[#1A3353]">
                                    Ordered On
                                </span>
                                <span className="mr-2">:</span>
                            </div>
                            <div
                                className="w-2/3 py-2 text-[#455560]"
                                style={{ overflowWrap: 'break-word' }}
                            >
                                {orderDetailData?.userId?.createdAt
                                    ? new Date(
                                          orderDetailData.userId.createdAt
                                      ).toLocaleString()
                                    : null}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-1/3 py-2 flex justify-between">
                                <span className="font-semibold text-[#1A3353]">
                                    Status
                                </span>
                                <span className="mr-2">:</span>
                            </div>
                            <div
                                className="w-2/3 py-2 text-[#455560]"
                                style={{ overflowWrap: 'break-word' }}
                            >
                                <Button className="text-[#049E0B] bg-[#A6D8A8] border-0 rounded-[6px]">
                                    {orderDetailData?.userId?.status}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="@sm:mt-4 flex @sm:gap-6 md:flex-col md:w-1/3">
                        <div className="@sm:w-1/2 @sm:py-3 border-1 h-1/2 flex flex-col items-center justify-center rounded-md mb-4">
                            <h1 className="table-name">
                                ₹ {orderDetailData?.grandTotal.toFixed(2)}
                            </h1>
                            <p className="mt-2">Total Collectable Amount</p>
                        </div>
                        <div className="@sm:w-1/2 @sm:py-3 border-1 h-1/2 flex flex-col items-center justify-center rounded-md">
                            <h1 className="table-name">
                                {orderDetailData?.paymentMode}
                            </h1>
                            <p className="mt-2 text-center">Payment Method</p>
                        </div>
                    </div>
                    <div className="md:flex border-1 md:w-2/3 rounded-md">
                        <div className="flex flex-col md:w-1/3 p-4">
                            <div className="flex justify-between mb-2">
                                <span className="font-semibold text-[#1A3353]">
                                    Shipping Address
                                </span>
                            </div>
                            <div className="text-[#455560]">
                                <p>
                                    {orderDetailData?.shippingAddress?.fullName}
                                </p>
                                <p>
                                    {orderDetailData?.shippingAddress?.houseNo}
                                    {orderDetailData?.shippingAddress?.city}
                                    <br />
                                    Ph:+
                                    {orderDetailData?.shippingAddress?.mobile}
                                </p>
                            </div>
                            <div className="mt-12">
                                <div>
                                    <div className="flex justify-between mb-2">
                                        <span className="font-semibold text-[#1A3353]">
                                            Transaction Id
                                        </span>
                                    </div>
                                    <div className="text-[#455560]">
                                        <p>
                                            {
                                                orderDetailData?.transactionId
                                                    ?.paymentGatewayDetails
                                                    ?.merchantTransactionId
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3 p-4 flex flex-col gap-4">
                            <div>
                                <div className="flex justify-between mb-2">
                                    <span className="font-semibold text-[#1A3353]">
                                        Billing Address
                                    </span>
                                </div>
                                <div className="text-[#455560]">
                                    <p>
                                        {
                                            orderDetailData?.billingAddress
                                                ?.fullName
                                        }
                                    </p>
                                    <p>
                                        {
                                            orderDetailData?.billingAddress
                                                ?.houseNo
                                        }
                                        {orderDetailData?.billingAddress?.city}
                                        <br />
                                        Ph:+
                                        {
                                            orderDetailData?.billingAddress
                                                ?.mobile
                                        }
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="flex justify-between mb-2">
                                    <span className="font-semibold text-[#1A3353]">
                                        Total Product(s)
                                    </span>
                                </div>
                                <div className="text-[#455560]">
                                    <p>
                                        {orderDetailData?.orderedItems?.length}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="flex justify-between mb-2">
                                    <span className="font-semibold text-[#1A3353]">
                                        Transaction Status
                                    </span>
                                </div>
                                <div className="text-[#455560]">
                                    <p>
                                        {orderDetailData?.transactionId?.status}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3 p-4">
                            <div className="flex justify-between mb-2">
                                <span className="font-semibold text-[#1A3353]">
                                    Tracking ID
                                </span>
                            </div>
                            <div className="text-[#455560]">
                                <p>Ecom-#5954847512</p>
                            </div>

                            <div className="mt-16">
                                <div className="flex justify-between mb-2">
                                    <span className="font-semibold text-[#1A3353]">
                                        Transaction Date
                                    </span>
                                </div>
                                <div className="text-[#455560]">
                                    <p>
                                        {formatDate(
                                            orderDetailData?.transactionId
                                                ?.createdAt
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SimpleTable
                    tableName="Products"
                    columns={columns}
                    dataSource={orderedItemList}
                />
                <div className="gap-8 mx-4">
                    <div className="table-name mb-3">Delivery Status</div>
                    <Select
                        disabled={orderDetailData?.status === 'delivered'}
                        value={value ?? orderDetailData?.status}
                        onChange={(selectedValue) =>
                            handleChange(selectedValue, orderDetailData?._id)
                        }
                        style={{ width: '200px' }}
                        options={options.map((option) => ({
                            label: option.key,
                            value: option.value,
                        }))}
                    />
                </div>
                <div className="md:flex gap-8">
                    {role === 'Delivery Person' ? null : (
                        <div className="md:w-2/3">
                            {/* Prescription */}
                            <OrderPrescription
                                prescriptionData={prescription}
                                isPrescriptionRequired={anyPrescriptionRequired}
                            />
                            {/* Prescription END */}
                        </div>
                    )}

                    <div className="md:w-1/3">
                        <div class="flex justify-between p-4 border-b-1 border-transparent items-center">
                            <div class="flex gap-4">
                                <h1 class="table-name">&nbsp;</h1>
                            </div>
                        </div>
                        <div className="flex justify-end p-4">
                            <div className="w-full h-[200px] border-1 rounded-md p-4">
                                <div className="py-2 flex justify-between items-center">
                                    <div className="font-semibold text-[#1A3353]">
                                        Item Total
                                    </div>
                                    <div className="font-semibold text-[#1A3353]">
                                        ₹{ordersDetail[0]?.subTotal?.toFixed(2)}
                                    </div>
                                </div>
                                <div className="py-2 flex justify-between items-center">
                                    <div className="font-semibold text-[#1A3353]">
                                        Total GST
                                    </div>
                                    <div className="font-semibold text-[#1A3353]">
                                        ₹{ordersDetail[0]?.totalGst?.toFixed(2)}
                                    </div>
                                </div>
                                <div className="py-2 flex justify-between items-center">
                                    <div className="font-semibold text-[#1A3353]">
                                        Shipping Charges
                                    </div>
                                    <div className="font-semibold text-[#1A3353]">
                                        ₹
                                        {ordersDetail[0]?.deliveryCharges?.toFixed(
                                            2
                                        )}
                                    </div>
                                </div>
                                {ordersDetail[0]?.discount ? (
                                    <div className="py-2 flex justify-between items-center">
                                        <div className="font-semibold text-[#1A3353]">
                                            Discount
                                        </div>
                                        <div className="font-semibold text-[#1A3353]">
                                            -₹
                                            {ordersDetail[0]?.discount?.toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                ) : null}

                                <div className="py-2 mt-2 flex justify-between items-center">
                                    <div className="font-semibold text-[#1A3353] text-[18px]">
                                        Total
                                    </div>
                                    <div className="font-semibold text-[#1A3353] text-[18px]">
                                        ₹
                                        {ordersDetail[0]?.grandTotal?.toFixed(
                                            2
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="@sm:px-8 @sm:pb-8 flex gap-4 mt-6 justify-center">
                            {/* hidden invoice button */}
                            {/* <Button
                                htmlType="submit"
                                className="w-1/2 w-max border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 h-auto px-8"
                            >
                                INVOICE
                            </Button>
                            <Button
                                htmlType="reset"
                                className="w-1/2 w-max border-[#232321] text-[#1A3353] font-semibold py-2 h-auto px-8"
                                onClick={goBack}
                            >
                                CANCEL
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Return Reason modal */}
            <Modal
                centered
                width={600}
                open={openModal}
                onCancel={closeModal}
                footer={false}
            >
                {orderDetailData?.orderedItems?.map((item, index) => (
                    <div key={index}>
                        <h2 className="table-name">Order Return</h2>
                        <div className="flex items-start border-2 rounded-md p-4 my-6">
                            <div className="w-1/3">
                                <img
                                    className="w-[80%]"
                                    src={item?.productId?.images[0]}
                                    alt="Product"
                                />
                            </div>
                            <div className="w-2/3 flex flex-col gap-2">
                                <p>{item?.productId?.name}</p>
                                <p>
                                    Price: ₹ {item?.discountedAmount.toFixed(2)}
                                </p>
                                {/* <p>
                                Size: <b>100gm</b>
                            </p> */}
                                <p>Qty: {item?.quantity}</p>
                            </div>
                        </div>

                        <h2 className="font-semibold text-[16px]">
                            RETURN REASON:
                        </h2>
                        <p className="border-2 rounded-md p-4 my-6">
                            {allReturnReasons}
                        </p>
                        {/* <p>{allReturnReasons?.length > 30 ? `${item.name?.slice(0, 27)}...` : allReturnReasons}</p> */}
                        {/* <Form className="flex flex-col gap-1 mt-3">
                            <Radio.Group
                                onChange={(e) =>
                                    setReturnReason(e.target.value)
                                }
                                defaultValue="Item_is_Damage"
                                disabled
                            >
                                {[
                                    {
                                        value: "Item_is_Damage",
                                        label: "Item_is_Damage",
                                    },
                                    {
                                        value: 'Other_Reason',
                                        label: 'Other Reason',
                                    },
                                ].map((item) => (
                                    <Radio
                                        key={item.value}
                                        value={item.value}
                                        style={{
                                            '&.ant-radio-checked': {
                                                color: '#FF8F2F',
                                            },
                                        }}
                                    >
                                        {item.label}
                                    </Radio>
                                ))}
                            </Radio.Group>
                            {returnReason === 'Other_Reason' && (
                                <>
                                    <p className="mt-6">Other Reason:</p>
                                    <TextArea rows={4} />
                                </>
                            )}

                            <Button
                                onClick={() =>
                                    handleSubmit(item?.productId?._id)
                                }
                                className="ml-auto mt-6 w-max border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 h-auto px-8"
                            >
                                Submit
                            </Button>
                        </Form> */}
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default OrderDetails;
