import { Button, Checkbox, Input, Select } from 'antd';
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import { uniqueId } from '../../utils/utils';
import useAppSelector from '../../hooks/useAppSelector';
import GetAttributesOptions from './get-attribute-options';
import ImageUpload from '../../components/common/image-upload';
import ImageRender from '../../components/common/image-render';

const VariantCardWrapper = forwardRef((_, ref) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [variants_state, setVariantsState] = useState([
        // {
        //     title: '',
        //     sku: '',
        //     hsnCode: '',
        //     status: 'active',
        //     attributes: {},
        //     images:[""]
        // },
    ]);

    console.log({ variants_state });
    const store = useAppSelector((state) => ({
        product_details: state.product_store.product_details,
        attributes_mapping: state.container_store.attributes_mapping,
    }));

    function addVariant() {
        const newVariant = {
            title: '',
            sku: '',
            hsnCode: '',
            status: 'active',
            attributes: {},
            id: uniqueId(),
            itemCode: '',
            images: [],
        };
        setVariantsState((prev) => [...prev, newVariant]);
    }

    function handleSelectChange(selected) {
        setSelectedItems(selected);
        if (selectedItems.length === 0 && variants_state.length === 0) {
            addVariant();
        }
    }

    function getVariantDetails() {
        return { variants_state, selectedItems };
    }

    const handleCheckbox = useCallback((key, value, index) => {
        setVariantsState((prev) => {
            let update_data = { ...prev[index] };
            if (key === 'status') {
                update_data = {
                    ...prev[index],
                    status: value ? 'active' : 'inactive',
                };
            } else {
                update_data = { ...prev[index], [key]: value };
            }
            return [
                ...prev.slice(0, index),
                update_data,
                ...prev.slice(index + 1),
            ];
        });
    }, []);

    ref.current = getVariantDetails;

    const handleVariantState = useCallback((key, index, vals) => {
        setVariantsState((prev) => {
            let update_data = { ...prev[index] };
            if (key === 'attributes') {
                const { attr_key, value } = vals;

                update_data['attributes'] = {
                    //! add this there is correction in the api attribute should be in the object format
                    ...update_data['attributes'],
                    [attr_key]: value,
                };
            } else if (key === 'sku') {
                update_data = { ...prev[index], itemCode: vals, sku: vals };
            } else {
                update_data = { ...prev[index], [key]: vals };
            }

            return [
                ...prev.slice(0, index),
                update_data,
                ...prev.slice(index + 1),
            ];
        });
    }, []);

    useEffect(() => {
        if (
            store.product_details &&
            Object.keys(store.product_details).length > 0
        ) {
            setVariantsState(store.product_details.productVariants);
            if (store.product_details.variantAttributes.length > 0) {
                const data = store.product_details.variantAttributes[0].map(
                    (item) => item.attribute
                );
                setSelectedItems(data);
            }
        }
    }, [store.product_details]);
    console.log('RENDERS VARIANT CARD');
    return (
        <>
            <div className="px-8 pt-8 mt-8">
                <>
                    <div className="flex mb-6 items-center">
                        <h1 className="md:text-[1.5vw] font-semibold">
                            Variants
                        </h1>
                        <Select
                            mode="multiple"
                            className="ms-3 min-w-md"
                            placeholder="Size in ml"
                            style={{
                                minWidth: '150px',
                            }}
                            onChange={handleSelectChange}
                            value={selectedItems}
                            options={store.attributes_mapping.variant}
                        />
                    </div>
                    {variants_state.map((variant, index) => (
                        <VariantCard
                            {...{
                                variant,
                                index,
                                selectedItems,
                                variant_options:
                                    store.attributes_mapping.variant,
                                handleVariantState,
                                handleCheckbox,
                            }}
                            key={index}
                        />
                    ))}
                    <Button
                        onClick={addVariant}
                        className="mt-6 mx-auto py-2 px-4 flex items-center fw-500 rounded-[6px] border-[2px] border-[#FF8F2F] bg-[#FF8F2F] text-white md:text-[.85vw]"
                    >
                        + Add Variant
                    </Button>
                </>
            </div>
        </>
    );
});

const VariantCard = memo(
    ({
        variant,
        index,
        selectedItems,
        variant_options,
        handleVariantState,
        handleCheckbox,
    }) => {
        console.log('renders key', index);
        console.log('variants', variant.images);
        const [isFeatured, setIsFeatured] = useState(variant.isFeatured);
        const [isIntroducingNew, setIsIntroducingNew] = useState(
            variant.isIntroducingNew
        );
        const [introducingNewBanner, setIsIntroducingNewBanner] = useState(
            variant.introducingNewBanner
        );
        const [featureBanner, setFeatureBanner] = useState(
            variant.featureBanner
        );

        return (
            <>
                <div
                    key={variant.id}
                    className="variant-section mt-6 border-1 rounded-lg p-6"
                >
                    <h1 className="md:text-[1vw] font-semibold mb-4">
                        Variant {index + 1}
                    </h1>

                    <div className="flex flex-wrap gap-4">
                        {selectedItems.map((selectedItem, i) => {
                            const selectedOption = variant_options.find(
                                (option) => option.value === selectedItem
                            );
                            return (
                                <div
                                    key={selectedOption.id}
                                    className="w-[32%]"
                                >
                                    <GetAttributesOptions
                                        data={selectedOption}
                                        selectAttribute={(attr_key, value) =>
                                            handleVariantState(
                                                'attributes',
                                                index,
                                                {
                                                    attr_key,
                                                    value,
                                                }
                                            )
                                        }
                                        value={
                                            variant['attributes'][
                                                selectedOption.value
                                            ]
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <div className="flex flex-wrap gap-4">
                        <div className="w-[32%] mb-4" name="sku">
                            <label className="text-[#1A3353]">SKU *</label>
                            <Input
                                placeholder="SKU"
                                className="w-full mt-2"
                                onChange={(e) =>
                                    handleVariantState(
                                        'sku',
                                        index,
                                        e.target.value
                                    )
                                }
                                value={variant.sku}
                            />
                        </div>
                        <div className="w-[32%] mb-4" name="variantTitle">
                            <label className="text-[#1A3353]">
                                Variant Title *
                            </label>
                            <Input
                                placeholder="Variant Title"
                                className="mt-2 w-full"
                                onChange={(e) =>
                                    handleVariantState(
                                        'title',
                                        index,
                                        e.target.value
                                    )
                                }
                                value={variant.title}
                            />
                        </div>
                        <div className="w-[32%] mb-4" name="hsnCode">
                            <label className="text-[#1A3353]">HSN Code *</label>
                            <Input
                                placeholder="HSN Code"
                                className="mt-2 w-full"
                                onChange={(e) =>
                                    handleVariantState(
                                        'hsnCode',
                                        index,
                                        e.target.value
                                    )
                                }
                                value={variant.hsnCode}
                            />
                        </div>
                    </div>

                    <div className="my-3 flex gap-4 items-center">
                        <Checkbox
                            onChange={(e) =>
                                handleCheckbox(
                                    'isBestSeller',
                                    e.target.checked,
                                    index
                                )
                            }
                            checked={variant.isBestSeller}
                        >
                            Best Selling
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => {
                                handleCheckbox(
                                    'isFeatured',
                                    e.target.checked,
                                    index
                                );
                                setIsFeatured(e.target.checked);
                            }}
                            checked={variant.isFeatured}
                        >
                            Featured Products
                        </Checkbox>
                        <Checkbox
                            onChange={(e) => {
                                handleCheckbox(
                                    'isIntroducingNew',
                                    e.target.checked,
                                    index
                                );
                                setIsIntroducingNew(e.target.checked);
                            }}
                            checked={variant.isIntroducingNew}
                        >
                            Introducing New
                        </Checkbox>
                        <Checkbox
                            onChange={(e) =>
                                handleCheckbox(
                                    'isConciousWall',
                                    e.target.checked,
                                    index
                                )
                            }
                            checked={variant.isConciousWall}
                        >
                            Conscious Wall
                        </Checkbox>
                    </div>

                    <div className="flex flex-wrap">
                        <div className="flex flex-col gap-2">
                            <ImageUpload
                                uploaded_images={variant.images}
                                title="Upload Product Images"
                                name={`variant_image_upload${index}`}
                                type={'multiple'}
                                setUploadedImages={(images) =>
                                    handleVariantState('images', index, images)
                                }
                            />
                            <div className="flex flex-row gap-2 flex-wrap">
                                <ImageRender
                                    data={variant.images}
                                    img_className={'w-[23.5%]'}
                                    container_classNamer={
                                        'flex flex-row gap-3 flex-wrap'
                                    }
                                />
                            </div>
                        </div>
                        {isFeatured ? (
                            <div className="flex flex-row gap-2 w-full my-4">
                                <ImageUpload
                                    uploaded_images={variant.images}
                                    title="Upload Feature Images"
                                    name={`featureBanner${index}`}
                                    type={'single'}
                                    setUploadedImages={(images) => {
                                        handleVariantState(
                                            'featureBanner',
                                            index,
                                            images
                                        );
                                        setFeatureBanner(images);
                                    }}
                                />
                                {featureBanner && (
                                    <ImageRender
                                        data={featureBanner}
                                        className="w-[30%] z-1 relative h-[400px]"
                                        alt="featureBanner"
                                    />
                                )}
                            </div>
                        ) : null}
                        {isIntroducingNew ? (
                            <div className="flex flex-row gap-2 w-full my-4">
                                <ImageUpload
                                    uploaded_images={variant.images}
                                    title="Upload Introducing New Images"
                                    name={`introducingnewBAnner${index}`}
                                    type={'single'}
                                    setUploadedImages={(images) => {
                                        handleVariantState(
                                            'introducingNewBanner',
                                            index,
                                            images
                                        );
                                        setIsIntroducingNewBanner(images);
                                    }}
                                />

                                {introducingNewBanner && (
                                    <ImageRender
                                        data={introducingNewBanner}
                                        className="w-[30%] z-1 relative h-[400px]"
                                        alt="featureBanner"
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </>
        );
    }
);

VariantCard.displayName = 'VariantCard';

VariantCardWrapper.displayName = 'VariantCardWrapper';

export default memo(VariantCardWrapper);
