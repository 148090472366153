// ModalComponent.js
import { Checkbox, ConfigProvider, DatePicker, Modal } from 'antd';
import React, { useState } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createFollowUp } from '../../redux/actions/followup-action';
import moment from 'moment';
import Alertify from '../../scripts/alertify';

const { TextArea } = Input;

const FollowUpModal = ({ visible, onClose }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    const [date, setDate] = useState('');
    const [isCalled, setIsCalled] = useState(false);
    const ordersDetail = useSelector((state) => state?.order?.orderDetail);

    const onChange = (date, dateString) => {
        const formattedDate = moment(dateString).format('lll');
        setDate(formattedDate);
    };

    console.log("orderDetailData", ordersDetail)
    const handleAddFollowup = () => {
        if (!date) {
            Alertify.error('Please select follow up date');
            return;
        }
        if (!value) {
            Alertify.error('Please add note for follow up');
            return;
        }
        let reqData = {
            note: value,
            followUpDate: date,
            isCalled: isCalled,
            orderId: ordersDetail[0]?._id
        };
       
        

        dispatch(createFollowUp(reqData)).then((e)=>{
            onClose()
            setDate(' ')
            setValue(' ')
        })
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        controlHeight: 48,

                        paddingContentHorizontal: 24,
                    },
                },
            }}
        >
            <Modal
                title="Add Followup"
                visible={visible}
                onCancel={onClose}
                footer={null}
            >
                <div className="flex flex-col gap-5">
                    <DatePicker
                        className="w-full"
                        onChange={onChange}
                        showTime={true}
                    />

                    <TextArea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder="Note"
                        autoSize={{
                            minRows: 5,
                            maxRows: 7,
                        }}
                    />

                    {/* <Checkbox
                        checked={isCalled}
                        onChange={(e) => setIsCalled(e.target.checked)}
                    >
                        Call
                    </Checkbox> */}
                    <div className="flex justify-end">
                        <button
                            onClick={handleAddFollowup}
                            type="button"
                            className=" text-white bg-[#FF8F2F]  font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    );
};

export default FollowUpModal;
