import React, { memo, useEffect, useState } from 'react';
import { DeleteImage } from '../../redux/actions/container-actions';
import { useDispatch } from 'react-redux';

const ImageRender = ({ data, img_className, container_classNamer }) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState(data);

    useEffect(() => {
        setFiles(data);
        return () => {};
    }, [data]);

    function deleteServerImage(select_image) {
        dispatch(DeleteImage({ url: select_image }))
            .unwrap()
            .then(() => {
                setFiles((images) => {
                    if (typeof images === 'string') {
                        return null;
                    } else {
                        return images.filter((item) => item !== select_image);
                    }
                });
            })
            .catch((err) => {
                if (err === 'Image not found in any product or product variant')
                    setFiles((images) => {
                        if (typeof images === 'string') {
                            return null;
                        } else {
                            return images.filter(
                                (item) => item !== select_image
                            );
                        }
                    });
            });
    }
    return (
        <>
            {typeof files === 'string' ? (
                <div className="w-1/2 relative flex items-center justify-center border border-gray-300 mx-auto">
                    <img
                        src={files}
                        className={`max-h-[35vh] ${img_className}`}
                        alt="variant"
                    />
                    <p
                        className="absolute -top-2 -right-2 cursor-pointer bg-white rounded-full p-1"
                        onClick={() => deleteServerImage(files)}
                    >
                        <img
                            src={'/images/delete-image.png'}
                            className="w-6 h-6"
                            alt="variant"
                        />
                    </p>
                </div>
            ) : (
                <>
                    <div className={`w-full border-2  ${container_classNamer}`}>
                        {files &&
                            files?.map((item) => (
                                <React.Fragment key={item}>
                                    <div
                                        className={`${img_className} relative border border-gray-300 rounded-sm flex items-center`}
                                    >
                                        <img
                                            src={item}
                                            className="h-[20vh] mx-auto w-auto object-contain"
                                            alt="images"
                                        />
                                        <p
                                            className="absolute -top-2 -right-2 cursor-pointer bg-white rounded-full p-1"
                                            onClick={() =>
                                                deleteServerImage(item)
                                            }
                                        >
                                            <img
                                                src={'/images/delete-image.png'}
                                                className="w-6 h-6"
                                                alt="variant"
                                            />
                                        </p>
                                    </div>
                                </React.Fragment>
                            ))}
                    </div>
                </>
            )}
        </>
    );
};

export default memo(ImageRender);
