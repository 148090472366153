import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CREATE_DELIVERY_PERSON,
    CREATE_DOCTOR,
    CUSTOMER_ADDRESSES,
    CUSTOMER_DETAILS,
    CUSTOMER_LIST,
    DOCTOR_LIST,
    GET_DELIVERY_PERSON,
    GET_DELIVERY_PERSON_DETAIL,
    GET_DOCTOR_DETAIL,
    GET_ROLE,
    UPDATE_DELIVERY_PERSON,
    UPDATE_ORDER_STATUS,
} from '../../constant/api-constant';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../scripts/api-services';
import { Link } from 'react-router-dom';
import Alertify from '../../scripts/alertify';

import {
    DELETE_DOCTOR,
    DELETE_PHARMA,
    PHARMA_USERS_LISTINGS,
    UPDATE_PHARMA,
} from '../../contant/api-constant';

/*--------------Customer List --------------------*/

export const CustomersListData = createAsyncThunk(
    'customers/customers-list',
    async ({ page = 1, pageSize = 10, search }) => {
        console.log('search value-------', search);
        try {
            const response = await postApi(CUSTOMER_LIST, {
                page,
                pageSize,
                search,
            });
            console.log('Response-----------', response);
            return response;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

/*--------------Customer Addresses --------------------*/

export const CustomersAddress = createAsyncThunk(
    'customers/customers-addresses',
    async (customer_id) => {
        try {
            const response = await getApi(
                `${CUSTOMER_ADDRESSES}${customer_id}`
            );
            console.log('Customer addresses ---------->', response);
            return response;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

/*--------------Customer Details --------------------*/

export const CustomersDetails = createAsyncThunk(
    'customers/customers-details',
    async (customer_id) => {
        try {
            const response = await getApi(`${CUSTOMER_DETAILS}/${customer_id}`);
            console.log('Customer details', response);
            return response;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

/*--------------Doctor List --------------------*/

export const DoctorListData = createAsyncThunk(
    'doctor/doctor-list',
    async ({ page = 1, pageSize = 10, search }) => {
        try {
            const response = await postApi(DOCTOR_LIST, {
                //    search:searchValue,
                page,
                pageSize,
                search,
            });

            return response;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

// create doctor

export const createDoctor = createAsyncThunk(
    'doctor/create-doctor',
    async ({ form }) => {
        try {
            const response = await postApi(`${CREATE_DOCTOR}`, form);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);
// doctor detail
export const getDoctorDetail = createAsyncThunk(
    'doctor/get-doctordetail',
    async ({ id }) => {
        try {
            const response = await getApi(`${GET_DOCTOR_DETAIL}/${id}`);

            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// update order status
export const updateOrderStatus = createAsyncThunk(
    'delivery/updateOrderStatus',
    async ({ status, otp, orderId }, { dispatch }) => {
        try {
            const response = await patchApi(
                `${UPDATE_ORDER_STATUS}/${orderId}`,
                {
                    deliveryStatus: status,
                    deliveryOTP: Number(otp),
                }
            );

            if (response?.data?.message === 'Order not found') {
                Alertify.error('Incorrect OTP');
            } else {
                Alertify.success(response?.data?.message);
            }
            return response?.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

/*-------------- Get delivery role --------------------*/

export const deliveryRole = createAsyncThunk(
    'delivery/delivery-role',
    async () => {
        try {
            const response = await postApi(GET_ROLE, {
                page: 1,
                pageSize: 10,
            });

            return response?.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

// create delivery partner

export const createDeliveryPartner = createAsyncThunk(
    'delivery/create-delivery-partner',
    async ({ form }) => {
        try {
            const response = await postApi(`${CREATE_DELIVERY_PERSON}`, form);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// get detail delivery partner

export const getDeliveryPartnerDetail = createAsyncThunk(
    'delivery/get-delivery-partner-detail',
    async ({ detailID }) => {
        try {
            const response = await getApi(
                `${GET_DELIVERY_PERSON_DETAIL}/${detailID}`
            );

            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// update delivery partner

export const updateDeliveryPartner = createAsyncThunk(
    'delivery/update-delivery-partner',
    async ({ id, status, form, role }, { dispatch }) => {
        try {
            const dataToBeSent = status ? { status } : form;
            const response = await patchApi(
                `${UPDATE_DELIVERY_PERSON}/${id}`,
                dataToBeSent
            );

            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

// show delivery list
export const DeliveryListData = createAsyncThunk(
    'delivery/delivery-list',
    async ({ page = 1, pageSize = 10, deliveryPersonId, search }) => {
        console.log('pageSize', pageSize);
        try {
            const response = await postApi(GET_DELIVERY_PERSON, {
                //    search: searchValue,
                page,
                pageSize,
                roles: [deliveryPersonId],
                search,
            });
            console.log('respoonse of api', response);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
);

export function ManipulateCustomerLists(data) {
    return data?.map((item) => {
        let name = `${item.firstName || ''} ${item.lastName || ''}`.trim();
        const hasEmail = item.email && item.email.trim() !== '';
        const hasPhone = item.mobile && item.mobile.trim() !== '';
        if (!name) {
            if (hasEmail) {
                name = item.email;
            } else if (hasPhone) {
                name = item.mobile;
            }
        }
        const email = hasEmail ? item.email : '';
        const phno = hasPhone ? item.mobile : '';
        return {
            key: item._id,
            name: name,
            email: email,
            phno: phno,
            action: (
                <span className="flex gap-2">
                    <Link to={`/users/user-details/${item._id}`}>
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px]"
                        />
                    </Link>
                    {/* <img
                        src="/icons/common/delete.svg"
                        alt="delete"
                        className="h-[20px]"
                    /> */}
                </span>
            ),
        };
    });
}

export function manipulateCustomerDetails(data) {
    const details = [
        { key: 1, label: 'ID', content: data._id },
        {
            key: 2,
            label: 'Customers Name',
            content: `${data.firstName} ${data.lastName}`,
        },
        { key: 3, label: 'Phone Number', content: data.mobile || 'NA' },
        { key: 4, label: 'Email Address', content: data.email || 'NA' },
        { key: 5, label: 'Gender', content: data.gender || 'NA' },
        { key: 6, label: 'City', content: data.city || 'NA' },
        { key: 7, label: 'Address', content: data.address || 'NA' },
        {
            key: 8,
            label: 'Created On',
            content: new Date(data.createdAt).toLocaleDateString(),
        },
        {
            key: 9,
            label: 'Birthday',
            content: new Date(data.dob).toLocaleDateString(),
        },
        {
            key: 10,
            label: 'Engagement',
            content: new Date(data.engagement).toLocaleDateString(),
        },
        {
            key: 11,
            label: 'Wedding',
            content: new Date(data.wedding).toLocaleDateString(),
        },
    ];

    return details;
}

export function ManipulateDoctorLists(data) {
    return data?.map((item) => {
        let name = `${item?.name || ''}`.trim();
        const hasEmail = item?.email;
        const hasPhone = item?.mobile;
        const hasStoreLoc = item?.storeLocation;
        const hasCreatedOn = item?.createdAt;
        const hasRoles = item?.roles[0]?.name;
        if (!name) {
            if (hasEmail) {
                name = item?.email;
            } else if (hasPhone) {
                name = item?.mobile;
            }
        }
        const email = hasEmail ? item?.email : '';
        const phno = hasPhone ? item?.mobile : '';
        const storeLocation = hasStoreLoc ? item?.storeLocation : '';
        const createdOn = hasCreatedOn ? item?.createdAt : '';
        // const s = hasRoles ? item?.roles[0]?.name : '';
        return {
            key: item._id,
            _id: item._id,
            name: name,
            email: email,
            mobile: item.mobile,
            storeLocation: storeLocation,
            createdAt: createdOn,
            roles: item?.roles ?? [],
        };
    });
}

export function ManipulateDeliveryLists(data) {
    return data?.map((item) => {
        // console.log("items in manipulated func", item)
        let name = `${item?.name || ''}`.trim();
        const hasEmail = item?.email;
        const hasPhone = item?.mobile;
        const hasStoreLoc = item?.storeLocation;
        const hasCreatedOn = item?.createdAt;
        const hasStatus = item?.status;
        const hasRoles = item?.roles[0]?.name;
        if (!name) {
            if (hasEmail) {
                name = item?.email;
            } else if (hasPhone) {
                name = item?.mobile;
            }
        }
        const email = hasEmail ? item?.email : '';
        const phno = hasPhone ? item?.mobile : '';
        const storeLocation = hasStoreLoc ? item?.storeLocation : '';
        const createdOn = hasCreatedOn ? item?.createdAt : '';
        const status = hasStatus ? item.status : '';
        const role = item?.roles;
        return {
            key: item._id,
            _id: item._id,
            name: name,
            email: email,
            mobile: phno,
            storeLocation: storeLocation,
            createdAt: createdOn,
            status: status,
            roles: role,
            action: (
                <span className="flex gap-2">
                    <Link to={`/users/user-details/${item._id}`}>
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px]"
                        />
                    </Link>
                    <img
                        src="/icons/common/delete.svg"
                        alt="delete"
                        className="h-[20px]"
                    />
                </span>
            ),
        };
    });
}

export const GetPharmaUsersList = createAsyncThunk(
    'GetPharmaUsersList',
    async (
        { role, page = 1, pageSize = 10, search = '' },
        { dispatch, getState }
    ) => {
        try {
            const res = await postApi(PHARMA_USERS_LISTINGS, {
                search,
                page,
                pageSize,
            });
            return { res, role };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const DeleteUserRole = createAsyncThunk(
    'DeleteUserRole',
    async ({ role, id, ...payload }, { dispatch, getState }) => {
        console.log('role on delete api', role);
        try {
            let res;
            if (role === 'Pharma') {
                res = await deleteApi(`${DELETE_PHARMA}/${id}`);
            }
            if (role === 'Doctor') {
                res = await deleteApi(`${DELETE_DOCTOR}/${id}`);
            }
            Alertify.success('User is successfully deleted');
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const UpdatePharmaUser = createAsyncThunk(
    'UpdatePharmaUser',
    async ({ id, payload }, { dispatch, getState }) => {
        try {
            const res = await patchApi(`${UPDATE_PHARMA}/${id}`, {
                ...payload,
            });
            Alertify.success('Detail is successfully updated');
            return { res };
        } catch (error) {
            console.error(error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);
