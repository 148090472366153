import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { SetCustomerSearch } from '../../redux/slices/user-slice';
import { CustomersListData } from '../../redux/actions/user-action';
import useAppSelector from '../../hooks/useAppSelector';

const getRandomInt = (max, min = 0) =>
    Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
    new Array(getRandomInt(5))
        .join('.')
        .split('.')
        .map((_, idx) => {
            const category = `${query}`;
            return {
                value: category,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>
                            <a
                                href={`https://s.taobao.com/search?q=${query}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {category}
                            </a>
                        </span>
                        <span className="text-grey">
                            {getRandomInt(200, 100)}
                        </span>
                    </div>
                ),
            };
        });

function Topbar(props) {
    const store = useAppSelector((state) => ({
        user_details: state.auth_store.user_details,
    }));
    const [options, setOptions] = useState([]);

    const handleSearch = (value) => {
        setOptions(value ? searchResult(value) : []);
    };
    const onSelect = (value) => {
        console.log('onSelect', value);
    };

    const dispatch = useDispatch();

    const setCustomerSearch = (key, value) => {
        console.log('Search', key, value);
        dispatch(SetCustomerSearch({ key, value }));
        dispatch(CustomersListData({ key, value }));
    };

    return (
        <>
            <div className="mb-4 flex items-center justify-between @sm:justify-end @sm:bg-[#084245] @sm:p-2">
                <img
                    src="/icons/topbar/logo.svg"
                    alt="logo"
                    className="@sm:hidden"
                />
                <div className="flex cursor-pointer flex-row items-center justify-center gap-7">
                    <div>
                        <p className="text-right">
                            {store?.user_details?.name}
                        </p>
                        <p className="text-right">
                            {store?.user_details?.email}
                        </p>
                    </div>

                    <img
                        src="/icons/topbar/user.png"
                        className="rounded-full w-10 h-10"
                        alt="Avatar"
                    />
                </div>
            </div>
            {/* <div className="flex items-end justify-between @sm:flex-col-reverse @sm:items-center mb-4">
                <div className="@sm:mt-6 @sm:w-full @lg:w-1/4 @xl:w-1/4 @2xl:w-1/4">
                    <p className="fw-500 mt-4 text-[1vw] text-[#1A3353] mb-2">
                        Search
                    </p>
                    <AutoComplete
                        popupMatchSelectWidth={252}
                        style={{
                            width: 300,
                        }}
                        options={options}
                        onSelect={onSelect}
                        // onSearch={handleSearch}
                        className="text-black"
                    >
                        <Input.Search
                            className="w-full text-black"
                            placeholder={props.placeholder}
                            enterButton
                            onChange={(e) =>
                                props.onSearch('search', e.target.value)
                            }
                        />
                    </AutoComplete>
                </div>
            </div> */}
        </>
    );
}

export default Topbar;
