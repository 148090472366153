import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    Button,
    ConfigProvider,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
} from 'antd';
import { Link } from 'react-router-dom';

const categoriesList = [
    {
        value: 'makeUp',
        label: 'Make Up',
    },
    {
        value: 'skinCare',
        label: 'Skin Care',
    },
];

const BlogDetails = () => {
    const [file, setFile] = useState(null);

    function goBack() {
        window.history.back();
    }
    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <div className="flex gap-4 items-center p-4 border-b-1">
                <Link to="#">
                    <ArrowLeftOutlined onClick={goBack} />
                </Link>
                <h1 className="table-name">Blog Details</h1>
            </div>
            <div className="p-4 mt-2 rounded-lg">
                <div className="border-1 rounded-lg p-8 mt-6">
                    <div className="flex gap-4 ms-auto justify-end items-center mb-6">
                        <strong>Status:</strong>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimaryBorder: '#6E3362',
                                    colorPrimary: '#6E3362',
                                },
                            }}
                        >
                            <Switch
                                defaultChecked
                                className="flex self-center"
                                // style={{ border: "1px solid #6E3362" }}
                            />
                        </ConfigProvider>
                    </div>
                    <ConfigProvider
                        theme={{
                            components: {
                                InputNumber: {
                                    controlWidth: 0,
                                    controls: false,
                                },
                            },
                        }}
                    >
                        <Form layout="vertical">
                            <div className="flex gap-4">
                                <Form.Item
                                    className="w-4/5"
                                    name="title"
                                    label={
                                        <label
                                            className="text-[#1A3353]"
                                            htmlFor="title"
                                        >
                                            Title *
                                        </label>
                                    }
                                >
                                    <Input
                                        className="h-[40px]"
                                        placeholder="Sumit"
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="w-4/5"
                                    name="authorName"
                                    label={
                                        <label
                                            className="text-[#1A3353]"
                                            htmlFor="authorName"
                                        >
                                            Author Name *
                                        </label>
                                    }
                                >
                                    <Input
                                        className="h-[40px]"
                                        placeholder="Purna Gummies Sugar Free Apple Cider Vinegar"
                                    />
                                </Form.Item>
                            </div>
                            <div className="flex gap-4">
                                <Form.Item
                                    className="w-4/5"
                                    name="category"
                                    label={
                                        <label
                                            className="text-[#1A3353]"
                                            htmlFor="category"
                                        >
                                            Category *
                                        </label>
                                    }
                                >
                                    <Select
                                        className="h-[40px]"
                                        options={categoriesList}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="w-4/5"
                                    name="views"
                                    label={
                                        <label
                                            className="text-[#1A3353]"
                                            htmlFor="views"
                                        >
                                            Date *
                                        </label>
                                    }
                                >
                                    <InputNumber
                                        className="w-full h-[40px] grid items-center"
                                        placeholder="10k"
                                        controls={false}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                label={
                                    <label className="text-[#1A3353]">
                                        Description *
                                    </label>
                                }
                            >
                                <CKEditor editor={ClassicEditor} />
                            </Form.Item>

                            {/* for featured image of the blog */}
                            <div className="flex flex-col gap-2 w-1/2">
                                <p className="table-name text-[1vw] font-normal">
                                    Upload Featured Image *
                                </p>
                                <div className="w-full FirstTab border-dashed border-2 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                                    <div>
                                        <img
                                            className="m-auto"
                                            src="/icons/common/upload-file.svg"
                                            alt="Upload File"
                                        />
                                        <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                                            Drag & drop files or{' '}
                                            <span className="">
                                                <label
                                                    htmlFor="getFile"
                                                    className="cursor-pointer text-[#FF8F2F] text-[22px] underline"
                                                    onClick={() =>
                                                        document
                                                            .getElementById(
                                                                'getFile'
                                                            )
                                                            .click()
                                                    }
                                                >
                                                    Browse
                                                    <input
                                                        type="file"
                                                        data-max-size="2048"
                                                        id="getFile"
                                                        className="fileIcon invisible"
                                                        onChange={(e) =>
                                                            setFile(
                                                                e.target
                                                                    .files[0]
                                                            )
                                                        }
                                                    />
                                                </label>
                                            </span>
                                        </div>
                                    </div>

                                    <p className="info">
                                        Supported files: JPEG, PNG, PDF
                                    </p>
                                </div>
                            </div>
                            <div className="flex gap-4 w-2/5 mt-6">
                                <Button
                                    htmlType="submit"
                                    className="w-1/2 border-[#FF8F2F] bg-[#FF8F2F] text-white font-semibold py-2 px-4 h-auto"
                                >
                                    SAVE CHANGES
                                </Button>
                                <Button
                                    htmlType="reset"
                                    className="w-1/2 border-[#232321] text-[#1A3353] font-semibold py-2 px-4 h-auto"
                                    onClick={goBack}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;
