import { createSlice } from '@reduxjs/toolkit';
import { StoreDetail, StoreList, UpdateStore } from '../actions/store-action';

const initialState = {
    storeList: [],
    storeListId: '',
    storeDetail: [],
    storeUpdate: [],
};
export function ManipulateProductLists(data) {
    return data?.map((item) => ({
        key: item._id,
        _id: item._id,
        name: item.name,
        address: item.address,
        mobile: item.mobile,
        status: item.status,
        action: item._id,
    }));
}

const storeSlice = createSlice({
    name: 'store-slice',
    initialState,
    reducers: {
        StoreListId: (state, action) => {
            state.storeListId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(StoreList.fulfilled, (state, { payload }) => {
            state.storeList = ManipulateProductLists(payload.data.list);
        });
        //store detail
        builder.addCase(StoreDetail.fulfilled, (state, { payload }) => {
            state.storeDetail = payload;
        });
        // updateStore
        builder.addCase(UpdateStore.fulfilled, (state, { payload }) => {
            state.storeUpdate = payload;
        });
    },
});

export const { StoreListId } = storeSlice.actions;
export default storeSlice.reducer;
