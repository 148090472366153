import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    ORDERS_DETAIL,
    ORDERS_LIST,
    RETURN_REASON,
    ORDERS_STATUS,
    UPDATE_ORDER_AGENT,
} from '../../constant/api-constant';
import { getApi, patchApi, postApi } from '../../scripts/api-services';
import Alertify from '../../scripts/alertify';

export const orderList = createAsyncThunk(
    'orders/orderList',
    async ({
        page,
        pageSize,
        search,
        role,
        userId,
        startDate,
        endDate,
        orderStatus,
    }) => {
        try {
            const response = await postApi(ORDERS_LIST, {
                page,
                pageSize,
                search,
                role,
                userId,
                startDate,
                endDate,
                orderStatus,
            });
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const orderDetail = createAsyncThunk(
    'order/orderDetail',
    async (_id) => {
        try {
            const response = await getApi(`${ORDERS_DETAIL}/${_id}`);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const orderStatus = createAsyncThunk(
    'orders/orderStatus',
    async ({ orderId, value }, { getState }) => {
        const role = getState().auth_store.role;
        const userId = getState().auth_store.userId;
        try {
            const response = await patchApi(ORDERS_STATUS, {
                orderId,
                status: value,
                updatedBy: role,
                updatedById: userId,
            });
            Alertify.success(response?.data?.message);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const updateOrderAgent = createAsyncThunk(
    'orders/updateOrderAgent',
    async ({ value, orderId }) => {
        try {
            const response = await patchApi(
                `${UPDATE_ORDER_AGENT}/${orderId}`,
                {
                    deliveryAgent: value,
                }
            );
            Alertify.success(response?.data?.message);
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

/*--------------------Return And Refund-----------------*/

export const returnReasonOrder = createAsyncThunk(
    'order/return-reason',
    async ({ productId, orderId, returnReason }) => {
        try {
            const response = await postApi(`${RETURN_REASON}/${orderId}`, {
                productId,
                returnReason,
            });
            return response;
        } catch (error) {
            console.log('Error fetch  API', error);
            return Promise.reject(error);
        }
    }
);

export function ManipulateTransactionId(data) {
    return data?.map((item) => {
        const { transactionId, ...rest } = item;
        if (transactionId && Array.isArray(transactionId)) {
            const manipulatedTransactionId = transactionId?.map(
                (transaction) => ({
                    ...transaction,
                    transactionStatus: transaction.status,
                    transactionId: transaction.transactionId,
                    transactionDate: transaction.createdAt,
                })
            );
            return {
                ...rest,
                transactionId: manipulatedTransactionId,
            };
        }
        return item;
    });
}
