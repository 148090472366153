import React, { useEffect, useState } from 'react';
import SimpleTable from '../../../components/tables/simpletable';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    CategoryListData,
    DeleteCategory,
} from '../../../redux/actions/category-action';
import SearchBar from '../../../components/topbar/searchbar';
import { useDebounce } from '../../../hooks/useDebounce';

const columns = [
    {
        title: 'NAME',
        dataIndex: 'name',
        width: '28%',
    },
    {
        title: 'SLUG',
        dataIndex: 'slug',
        width: '28%',
    },
    {
        title: 'PARENT CATEGORY',
        dataIndex: 'parentCategory',
        width: '28%',
    },
];

const CategoryListing = () => {
    const [searchP] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [searchValue, setSearchValue] = useState('');
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const categoryLevelUrl = searchP.get('category');
    const featured_BestSelling_Url = searchP.get('filter');

    const categoryData = useSelector(
        (state) => state.category_store.categoryList
    );
    const categoryCount = useSelector(
        (state) => state.category_store.category_count
    );

    const categoryFilterValue = useSelector(
        (state) => state.category_store.categoryFilterValue
    );

    const featuredCategoryValue = useSelector(
        (state) => state.category_store.featuredCategoryValue
    );

    const action_column = [
        {
            title: 'ACTIONS',
            dataIndex: 'action',
            width: '120px',
            render: (_, record) => {
                return (
                    <span className="flex gap-2">
                        <Link to={`/categories/edit-category/${record.id}`}>
                            <img
                                src="/icons/common/edit.svg"
                                alt="edit"
                                className="h-[20px]"
                            />
                        </Link>
                        <div
                            className="cursor-pointer"
                            onClick={() => deleteCategory(record.id)}
                        >
                            <img
                                src="/icons/common/delete.svg"
                                alt="delete"
                                className="h-[20px]"
                            />
                        </div>
                    </span>
                )
            },
        },
    ];

    const all_columns = [...columns, ...action_column];

    function searchProductInHeader(type, value) {
        setSearchValue(value)
    }

    const debouncedRequest = useDebounce((callback) => callback(), 300);


    function getCategoriesList(key, value) {
        const payload = {
            key: key,
            value: value,
            page: Number(currentPage),
            pageSize: Number(pageSize),
        };

        if (searchValue) {
            payload['search'] = searchValue;
        }

        if (categoryLevelUrl) {
            payload['level'] = categoryLevelUrl
        }

        if (featured_BestSelling_Url) {
            const filter = {};
            filter[featured_BestSelling_Url] = true;
            payload['filter'] = filter;
        }
        if (searchValue) {
            debouncedRequest(() => {
                dispatch(CategoryListData(payload)).then(() => setLoading(false));
            })
        } else {
            dispatch(CategoryListData(payload)).then(() => setLoading(false));
        }
    }

    function deleteCategory(id) {
        dispatch(DeleteCategory({ id }));
    }

    function paginate(pageNumber, pageSize) {
        setCurrentPage(pageNumber);
        setPageSize(pageSize);
        navigate(`?page=${pageNumber}&pageSize=${pageSize}`, { replace: true });
        if (categoryLevelUrl) {
            navigate(`?category=${categoryLevelUrl}&page=${pageNumber}&pageSize=${pageSize}`, { replace: true });
        }
        if (featured_BestSelling_Url) {
            navigate(`?filter=${featured_BestSelling_Url}&page=${pageNumber}&pageSize=${pageSize}`, { replace: true });
        }
    }

    useEffect(() => {
        getCategoriesList();
    }, [currentPage, pageSize, categoryLevelUrl, featured_BestSelling_Url, searchValue]);

    return (
        <>
            <SearchBar
                onSearch={searchProductInHeader}
                placeholder={'Search Category'}
            />
            <SimpleTable
                tableName="Categories"
                addNewLink="/categories/edit-category/0"
                categoryFilter={true}
                addNew={true}
                filterByCategory={false}
                categoryFilterFeatured={true}
                actionSelect={false}
                export={true}
                columns={all_columns}
                dataSource={categoryData}
                loading={loading}
                exportModal={'category'}
            />
            <div className="flex justify-center  py-10">
                {' '}
                <Pagination
                    pageSize={pageSize}
                    current={currentPage}
                    total={categoryCount}
                    onChange={paginate}
                    hideOnSinglePage
                    pageSizeOptions={[10, 20, 50]}
                />{' '}
            </div>
        </>
    );
};

export default CategoryListing;
