import { createSlice } from '@reduxjs/toolkit';
import { AttributeListData } from '../actions/attribute-action';
import {
    GetBrandsData,
    GetPrimaryCategories,
    _ManipulateMappingAttribute,
} from '../actions/container-actions';

const initialState = {
    attributes: {},
    attributes_mapping: {
        optional: [],
        mandatory: [],
        variant: [],
    },
    categories: [],
    sub_category: [],
    sub_child_category: [],
    brands_data: [],
};

const containerSlice = createSlice({
    name: 'container_store',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AttributeListData.fulfilled, (state, { payload }) => {
            state.attributes = payload;
            state.attributes_mapping = _ManipulateMappingAttribute(payload);
        });
        builder.addCase(
            GetPrimaryCategories.fulfilled,
            (state, { payload: { res } }) => {
                state.categories = res.data.data.list;
            }
        );
        builder.addCase(
            GetBrandsData.fulfilled,
            (state, { payload: { res } }) => {
                state.brands_data = res;
            }
        );
    },
});

export const { Test } = containerSlice.actions;
export default containerSlice.reducer;
