import { createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../scripts/api-services";
import { GET_PRESCRIPTION_ORDERS, GET_PRESCRIPTION_USERS, UPLOAD_PRESCRIPTION } from "../../constant/api-constant";
import Alertify from "../../scripts/alertify";

// for orders start here 
export const    uploadPrescription = createAsyncThunk(
    'orders/orderList',
    async ({ _id, userId , name , file },{dispatch}) => {
        try {
            const form_data = new FormData();
            form_data.append('orderId', _id);
            form_data.append('userId', userId);
            form_data.append('name', name);
            form_data.append('image', file);
            const response = await postApi(UPLOAD_PRESCRIPTION, form_data ,{
                'Content-Type': 'multipart/form-data',
            });
            dispatch(getPrescriptionByOrder(_id))
            Alertify.success("Prescription Uploaded !");
            return response.data;
        } catch (error) {
            console.error('Error:', error);
            Alertify.error(error.message[0]);
            return Promise.reject();
        }
    }
);

export const getPrescriptionByOrder = createAsyncThunk('prescription/getPrescription', async (_id) => {
    try {
        const response = await getApi(`${GET_PRESCRIPTION_ORDERS}${_id}`);
        return response;
    } catch (error) {
        console.error('Error:', error);
        Alertify.error(error.message[0]);
        return Promise.reject();
    }
});

// for orders end here 

// for user start from here 
export const getPrescriptionByUser = createAsyncThunk('prescription/getPrescriptionUser', async (id) => {
    try {
        const reqData={
            userId:id
        }
        const response = await postApi(`${GET_PRESCRIPTION_USERS}`,reqData);
        return response;
    } catch (error) {
        console.error('Error:', error);
        Alertify.error(error.message[0]);
        return Promise.reject();
    }
});
