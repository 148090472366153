import React, { useState } from 'react';
import { Select, Button, Form, Input, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { uploadPrescription } from '../../redux/actions/prescription-action';
import toast from 'react-hot-toast';

// import { FiUpload } from "react-icons/fi";

const UploadPrescription = ({uploadModal}) => {
    const [file, setFile] = useState({
        selectedFile: null,
    });

    const dispatch = useDispatch();
    const [dragActive, setDragActive] = useState(false);
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const ordersDetail = useSelector((state) => state?.order?.orderDetail);
    const orderDetailData = ordersDetail[0];

    const _id = orderDetailData?._id;
    const userId = orderDetailData?.userId?._id;

    const handleNameChange = (newValue) => {
        setName(newValue);
    };

    const checkFileType = (e, eventType) => {
        let extension;

        if (eventType === 'drop') {
            extension = e.dataTransfer.files[0].name.match(/\.([^.]+)$/)[1];
        } else {
            extension = e.target.value.match(/\.([^.]+)$/)[1];
        }

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'pdf':
            case 'webp':
                eventType !== 'drop'
                    ? setFile({ selectedFile: e.target.files[0] })
                    : setFile({ selectedFile: e.dataTransfer.files[0] });
                setMsg('');
                break;
            default:
                setFile({ selectedFile: null });
                setMsg(`.${extension} format is not supported.`);
        }
    };

    const checkSize = (e, eventType) => {
        let size;
        if (eventType === 'drop') {
            // console.log("size", e.dataTransfer.files[0]);
            size = e.dataTransfer.files[0].size / 8;
        } else {
            // console.log("size", e.target.files[0].size);
            size = e.target.files[0].size / 8;
        }
        // console.log("converted size", size);

        if (size <= 51200) {
            checkFileType(e, eventType);
        } else {
            setMsg('Size should be less than 50KB');
        }
    };

    const chooseFile = (e) => {
        if (e.target.files && e.target.files[0]) {
            setFile({ selectedFile: e.target.files[0] }); // Reset the state with the new file
            checkSize(e);
         
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            checkSize(e, 'drop');
            // checkFileType(e, "drop");
        }
    };

    function submitHandler() {
        dispatch(
            uploadPrescription({ _id, userId, name, file: file.selectedFile })
        );
        uploadModal()
        setFile({selectedFile: null})
        setName('')
    }
    return (
        <div className="p-8">
            <form
                className="uploadBox"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onSubmit={(e) => e.preventDefault()}
            >
                <div className="FirstTab border-dashed border-2 p-6 flex flex-col items-center justify-center text-center border-[#3B3B3B] rounded-[6px] mb-6">
                    <div>
                        <img
                            className="m-auto"
                            src="/icons/common/upload-file.svg"
                            alt="Upload File"
                        />
                        <div className="drag text-[#3B3B3B] font-semibold my-6 text-[22px]">
                            Drag & drop files or{' '}
                            <span className="">
                                <label
                                    htmlFor="img"
                                    className="cursor-pointer text-[#FF8F2F] underline"
                                    onClick={() =>
                                        document
                                            .getElementById('getFile')
                                            .click()
                                    }
                                >
                                    Browse
                                    <input
                                        type="file"
                                        data-max-size="2048"
                                        id="getFile"
                                        className="fileIcon"
                                        onChange={chooseFile}
                                    />
                                </label>
                            </span>
                        </div>
                    </div>

                    <p className="info">Supported files: JPEG, PNG</p>
                </div>
                <label className="w-full text-[#1A3353]">
                    Prescription Name
                </label>
                <Input
                    className="mt-3 mb-6 w-[100%]"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                />
            </form>

            {file.selectedFile !== null ? (
                <>
                    <div className="drag text-[#3B3B3B] font-semibold mt-2 mb-2 text-[22px]">
                        Uploading
                    </div>
                    <p className="mb-6 border-1 p-2 rounded-[6px]">
                        {file.selectedFile.name}
                    </p>
                </>
            ) : msg !== '' ? (
                <p className="text-[#e96c6c]">{msg}</p>
            ) : null}
            <Button onClick={submitHandler}>Submit </Button>
        </div>
    );
};

export default UploadPrescription;
