import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetAttributesValues } from '../../redux/actions/product-action';
import { Form, Input, Select } from 'antd';

const GetAttributesOptions = ({ data, selectAttribute, value = undefined, product_details }) => {
    const dispatch = useDispatch();
    const [text, setText] = useState(data.inputType === 'textBox' ? value : '');
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState('')

    function setAttributeData(select_value) {
        let value = '';
        const key = data.value;
        if (Array.isArray(select_value)) {
            value = select_value.map((item) => item.value);
        } else {
            value = select_value.value;
        }
        return selectAttribute(key, value);
    }

    useEffect(() => {
        if (data.inputType === 'dropdown') {
            dispatch(GetAttributesValues({ attribute: data.value, search: searchText })).then(
                ({ payload }) => {
                    if (payload.data.list) {
                        let newOptions = payload.data.list;

                        // Check and append values from parentAttributes
                        if (product_details && product_details.parentAttributes) {
                            product_details.parentAttributes.forEach((parentAttribute) => {
                                if (parentAttribute.key === data.value) {
                                    newOptions = [...newOptions, ...parentAttribute.value];
                                }
                            });
                        }
                        setOptions(newOptions);
                    }
                }
            );
        }

    }, [searchText]);

    return (
        <>
            <Form.Item
                className="mb-4 w-full"
                label={<label className="#1A3353">{data.label}</label>}
            >
                {data.inputType === 'textBox' ? (
                    <Input
                        value={text}
                        // className="h-[40px]"
                        onChange={(e) => {
                            selectAttribute(data.value, e.target.value);
                            setText(e.target.value);
                        }}
                    />
                ) : data.inputType === 'dropdown' ? (
                    <Select
                        className="min-w-[100px]"
                        placeholder="Select Value"
                        onChange={(_, option) => setAttributeData(option)}
                        mode={
                            data.inputType === 'dropdown' &&
                                data.multiple === true
                                ? 'multiple'
                                : false
                        }
                        value={value}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        onSearch={(value) => setSearchText(value)}
                    >
                        {options.map((item) => (
                            <Select.Option key={item._id} value={item._id}>
                                {item.value}
                            </Select.Option>
                        ))}
                    </Select>
                ) : null}
            </Form.Item>
            {/* </div> */}
        </>
    );
};

export default GetAttributesOptions;
