import React, { useEffect, useState } from 'react';
import SimpleTable from '../../components/tables/simpletable';
import { ConfigProvider, Image, Pagination, Switch } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    DeleteBanner,
    EditBanner,
    GetBannerList,
} from '../../redux/actions/banner-action';
import useAppSelector from '../../hooks/useAppSelector';

const columns = [
    {
        title: 'DESKTOP BANNER',
        dataIndex: 'desktopBanner',
        render: (desktopBanner) => (
            <>
                <div>
                    <img src={desktopBanner} className="" />
                </div>
            </>
        ),
    },
    {
        title: 'MOBILE BANNER',
        dataIndex: 'mobileBanner',
        render: (mobileBanner) => (
            <>
                <div>
                    <img src={mobileBanner} className="" />
                </div>
            </>
        ),
    },
    {
        title: 'BANNER NAME',
        dataIndex: 'bannerName',
    },
    {
        title: 'TYPE',
        dataIndex: 'type',
    },
    // {
    //   title: "STATUS",
    //   dataIndex: "status",
    // },
    // {
    //   title: "",
    //   dataIndex: "action",
    // },
];

const BannerListing = () => {
    const [loading, setLoading] = useState(true);
    const [searchP] = useSearchParams();

    const [currentPage, setCurrentPage] = useState(searchP.get('page') ?? 1);
    const [pageSize, setPageSize] = useState(searchP.get('pageSize') ?? 10);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const store = useAppSelector((state) => ({
        bannerList: state.banner_store.bannerList,
        banners_count: state.banner_store.bannerList_count,
    }));

    useEffect(() => {
        dispatch(GetBannerList({ page: Number(currentPage), pageSize: Number(pageSize)  })).then((res) => {
            setLoading(false);
        });
    }, [currentPage, pageSize]);

    // console.log("Banner List count------", store.bannerList);

    const action = [
        {
            title: 'STATUS',
            dataIndex: 'status',
            render: (_, record) => (
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimaryBorder: '#6E3362',
                            colorPrimary: '#6E3362',
                        },
                    }}
                >
                    <Switch
                        checked={record.status}
                        className="flex self-center"
                        onClick={() => toggleStatus(record.id, !record.status)}
                        // style={{ border: "1px solid #6E3362" }}
                    />
                </ConfigProvider>
            ),
        },
        {
            title: 'ACTIONS',
            dataIndex: 'action',
            render: (_, record) => (
                <span className="flex gap-4">
                    <Link to={`/banners/banner-detail/${record.id}`}>
                        <img
                            src="/icons/common/edit.svg"
                            alt="edit"
                            className="h-[20px]"
                        />
                    </Link>
                    <div onClick={() => deleteBanner(record.id)}>
                        <img
                            src="/icons/common/delete.svg"
                            alt="delete"
                            className="h-[20px] cursor-pointer"
                        />
                    </div>
                </span>
            ),
        },
    ];

    const all_columns = [...columns, ...action];

    function paginate(page, pageSize) {
        setCurrentPage(page);
        setPageSize(pageSize);
        navigate(`?page=${page}&pageSize=${pageSize}`, { replace: true });
    }
    
    function deleteBanner(id) {
        dispatch(DeleteBanner({ id, page:currentPage, pageSize:pageSize }));
    }

    function toggleStatus(id, status) {
        alert('are you sure want to change the status');
        dispatch(EditBanner({ id, payload: { status }, page:currentPage, pageSize:pageSize }));
    }

    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <SimpleTable
                tableName="Banner"
                addNew={true}
                addNewLink="/banners/banner-detail/0"
                actionSelect={false}
                export={false}
                columns={all_columns}
                dataSource={store.bannerList}
                loading={loading}
                checkBox={false}
            />
            <div className="flex justify-center  py-10">
                <Pagination
                   current={currentPage}
                   total={store.banners_count}
                   pageSize={pageSize}
                   onChange={paginate}
                   hideOnSinglePage
                   pageSizeOptions={[10, 20, 50]}
                />
            </div>
        </div>
    );
};

export default BannerListing;
