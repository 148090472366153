import React from 'react';
import SimpleTable from '../../components/tables/simpletable';
import { ConfigProvider, Switch } from 'antd';
import { Link } from 'react-router-dom';

const columns = [
    {
        title: 'TITLE',
        dataIndex: 'title',
        width: '20%',
    },
    {
        title: 'AUTHOR',
        dataIndex: 'author',
        width: '15%',
    },
    {
        title: 'CATEGORIES',
        dataIndex: 'categories',
    },
    {
        title: 'VIEWS',
        dataIndex: 'views',
        align: 'center',
    },
    {
        title: 'DATE',
        dataIndex: 'date',
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
    },
    {
        title: '',
        dataIndex: 'action',
        width: '90px',
    },
];

const dataSource = [
    {
        key: 1,
        title: 'The 10 Best Hydrating Primers For Dry Skin',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    id=""
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 2,
        title: '10 Best Eyeliner Pencils to Let Your Eyes Do All the Talking',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    id=""
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 3,
        title: 'How To: Face Serum The Right Way',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 1,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 4,
        title: 'The 10 Best Hydrating Primers For Dry Skin',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    id=""
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 5,
        title: '10 Best Eyeliner Pencils to Let Your Eyes Do All the Talking',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 6,
        title: 'The 10 Best Hydrating Primers For Dry Skin',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    id=""
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 7,
        title: '10 Best Eyeliner Pencils to Let Your Eyes Do All the Talking',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 8,
        title: 'How To: Face Serum The Right Way',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 1,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    id=""
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 9,
        title: 'The 10 Best Hydrating Primers For Dry Skin',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 4,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
    {
        key: 10,
        title: '10 Best Eyeliner Pencils to Let Your Eyes Do All the Talking',
        author: 'Diya J Mirja',
        categories: 'MakeUp',
        views: 5,
        date: '11-02-2019',
        status: (
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimaryBorder: '#6E3362',
                        colorPrimary: '#6E3362',
                    },
                }}
            >
                <Switch
                    id=""
                    defaultChecked
                    className="flex self-center"
                    // style={{ border: "1px solid #6E3362" }}
                />
            </ConfigProvider>
        ),
        action: (
            <span className="flex gap-4">
                <Link to="/blogs/blogs-detail">
                    <img
                        src="/icons/common/edit.svg"
                        alt="edit"
                        className="h-[20px]"
                    />
                </Link>
                <img
                    src="/icons/common/delete.svg"
                    alt="delete"
                    className="h-[20px]"
                />
            </span>
        ),
    },
];

const BlogsListing = () => {
    return (
        <div className="bg-white pb-2 rounded-[6px]">
            <SimpleTable
                tableName="Blogs"
                addNew={true}
                addNewLink="/blogs/blogs-detail"
                actionSelect={true}
                export={true}
                columns={columns}
                dataSource={dataSource}
            />
            <div className="flex justify-center  py-10">
                {/* {" "}
        <Pagination
          current={currentPage}
          total={product_count}
          pageSize={pageSize}
          onChange={paginate}
          hideOnSinglePage
        />{" "} */}
            </div>
        </div>
    );
};

export default BlogsListing;
